import { useIntl } from "react-intl";
import { useBulletinsThunks } from "../store/thunks/BulletinsThunks";
import { useBulletinsActions } from "../store/slices/BulletinsSlice";

export const useBrandWithoutRepresentativesProps = () => {
  const intl = useIntl();
  const brandWithoutRepresentativesMessages = {
    listRegistersWithoutRepresentatives: intl.formatMessage({
      id: "brandsWithoutRepresentative.label.listRegistersWithoutRepresentatives",
    }),
    noResults: intl.formatMessage({ id: "generics.label.noResults" }),
    number: intl.formatMessage({ id: "model.number" }),
    brandText: intl.formatMessage({ id: "model.brandText" }),
    image: intl.formatMessage({ id: "model.image" }),
    classes: intl.formatMessage({ id: "customers.label.classes" }),
    details: intl.formatMessage({ id: "customers.label.details" }),
    titular: intl.formatMessage({ id: "model.titular" }),
    address: intl.formatMessage({ id: "model.address" }),
    representative: intl.formatMessage({ id: "model.representative" }),
    representativeAddress: intl.formatMessage({ id: "model.address" }),
  };

  const { registerPage, currentPage, setCurrentPage, setRegisterPage } =
    useBulletinsActions();

  const {
    requestRegistersWithoutRepresentative,
    isLoading: bulletinIsLoading,
    setIsLoading: setBulletinIsLoading,
  } = useBulletinsThunks();

  return {
    brandWithoutRepresentativesProps: {
      setCurrentPage,
      currentPage,
      setRegisterPage,
      registerPage,
      isLoading: bulletinIsLoading,
      setIsLoading: setBulletinIsLoading,
      requestRegistersWithoutRepresentative,
      messages: brandWithoutRepresentativesMessages,
    },
  };
};
