import { Table } from "react-bootstrap";
import { useState } from "react";
import RegisterClassModal from "./RegisterClassModal";
import { BrandRegister } from "../models/BrandRegister";
import MyButton from "./MyButton";
import { Page } from "../models/Page";

interface Props {
    registerPage: Page<BrandRegister[]>,
    messages: {
        number: string,
        brandText: string,
        image: string,
        classes: string,
        details: string,
        titular: string,
        address: string,
        representative: string,
        representativeAddress: string,
    }
}

const RegisterTable = ({ registerPage,messages }: Props) => {

    const registers = registerPage.content

    const [showModal, setShowModal] = useState(false)
    const [modalRegister, setModalRegister] = useState<BrandRegister>({} as BrandRegister)

    const handleShowModal = (register: BrandRegister) => {
        setShowModal(true)
        setModalRegister(register)
    }

    return <>
        {registers?.length > 0 && <Table striped bordered hover>
            <thead>
                <tr>
                    {Object.values(messages).map((message,index) => <th key={index}>{message}</th>)}
                </tr>
            </thead>

            <tbody>
                {
                    registers.map(register => {
                        return <tr key={register.number}>
                            <td>{register.number}</td>
                            <td>{register.brandText}</td>
                            <td> {register.image &&
                                <img src={`data:image/png;base64,${register?.image}`} alt={""} />
                            } </td>
                            <td> {register.registerClasses.map((rc, index) => `${rc.key}${index < register.registerClasses.length - 1 ? '; ' : ''}`)} </td>
                            <td>
                                <MyButton label={messages.details} onClick={() => handleShowModal(register)} />
                            </td>
                            <td>{register.titularName}</td>
                            <td>{register.titularAddress}</td>
                            <td>{register.representative}</td>
                            <td>{register.representativeAddress}</td>
                        </tr>
                    })
                }

            </tbody>

        </Table>


        }
        <RegisterClassModal show={showModal} setShow={setShowModal} classes={modalRegister?.registerClasses} />

    </>
}


export default RegisterTable