import { Table } from "react-bootstrap";
import CustomerRow from "./CustomerRow";
import { Customer } from "../../models/Customer";


type CustomerTableProps = {
  customers: Customer[],
  messages: { name: string, details: string, addRegister: string, updateRegisters: string, remove: string, },
  removeCustomer: (id: number) => void;
  setSelectedCustomer: (customer: Customer) => void;
  changeUpdateModal: (show: boolean) => void;
  changeRegisterModal: (show: boolean) => void;
  changeDetailsModal: (show: boolean) => void;
}

const CustomerTable = ({ customers, messages, changeDetailsModal, changeRegisterModal, changeUpdateModal, removeCustomer, setSelectedCustomer }: CustomerTableProps) => {

  return <Table striped hover bordered style={{ margin: 5 }}>

    <thead className={"m-0 p-0 text-center"} style={{}}>
      <tr>
        <th>
          {messages.name}
        </th>
        <th className={"p-0"}></th>
      </tr>
    </thead>
    <tbody>
      {
        customers.map(customer => {

          const customerRowProps = {
            customer, changeDetailsModal, changeRegisterModal,
            changeUpdateModal, setSelectedCustomer, removeCustomer, messages
          }

          return <CustomerRow {...customerRowProps} key={customer.id} />
        })
      }
    </tbody>

  </Table>
}

export default CustomerTable;