import { Table } from "react-bootstrap";
import RenderTitularCount from "./RenderTitularCount";
import { TitularCount } from "../../models/TitularCount";
import { Page } from "../../models/Page";

type Props={
    titularCount:Page<TitularCount[]>;
    messages:{
        search:string,
        name: string,
        quantity: string,
        origin: string,
    }
}

const TitularStatisticsTable = ({titularCount,messages}:Props) => {

    const titular = titularCount.content

    if (!titular) return <></>

    return <>
        {
            titular.length > 0 &&
            <Table>
                <thead>
                    <tr>
                        <th>{messages.name}</th>
                        <th>{messages.quantity}</th>
                        <th>{messages.origin}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        <RenderTitularCount titular={titular} />
                    }
                </tbody>

            </Table>
        }
    </>

}

export default TitularStatisticsTable