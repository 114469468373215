import { useEffect, useState } from "react";
import { Renewal } from "../../models/Renewal";
import { toast } from "react-toastify";
import RenewalsTable from "../renewals/RenewalsTable";

type Props = {
    fetchRenewalsByMonth: (month: number, handleResult: (renewals: Renewal[]) => void) => void,
    messages:{
        brandText:string,
        endDate:string,
        customer:string,
        reference:string,
        number:string,
        source:string,
    }
}

const NextRenewals = ({fetchRenewalsByMonth,messages}:Props) => {

    const [renewals, setRenewals] = useState([] as Renewal[])
    const [isLoading, setIsLoading] = useState(false)
    const months = 6
    useEffect(() => {
        const handleResult = (result: Renewal[]) => {
            if (result) {
                if (result.length === 0) {
                    toast("Não há renevoações para este período")
                }
                setRenewals(result)
            }

        }
        setIsLoading(true)
        fetchRenewalsByMonth(months, handleResult)
        setIsLoading(false)
    }, [])

    return <>
        {!isLoading && <RenewalsTable renewalData={renewals} messages={messages} />}
    </>
}

export default NextRenewals