import {KeycloakUser} from "../../models/keycloak/KeycloakUser";
import {KeycloakGroup} from "../../models/keycloak/KeycloakGroup";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {useAppDispatch, useAppSelector} from "../hooks";
import { useState } from "react";

interface KeycloakState{
    users:KeycloakUser[],
    groups:KeycloakGroup[],
}


const keycloakState:KeycloakState={
    users:[],
    groups:[],
}


const keycloakSlice=createSlice({
    name:"keycloak",
    initialState:keycloakState,
    reducers:{
        setUsers:(state, action:PayloadAction<KeycloakUser[]>)=>{
            state.users=action.payload
        },
        setGroups:(state, action:PayloadAction<KeycloakGroup[]>)=>{
            state.groups=action.payload
        },
    }
})


export default keycloakSlice.reducer


export const useKeycloakAction=()=>{
    const [isLoading,setIsLoading]=useState(true)
    const dispatch=useAppDispatch()


    const {groups,users}=useAppSelector(state => state.keycloak)

    const {setUsers,setGroups}=keycloakSlice.actions

    return {
        isLoading,
        setIsLoading,
        users,groups,
        setUsers:(users:KeycloakUser[])=>{
            dispatch(setUsers(users))
        },
        setGroups:(groups:KeycloakGroup[])=>{
            dispatch(setGroups(groups))
        }
    }
}