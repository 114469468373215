import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import MyButton from "../../widgets/MyButton";
import { Representative } from "../../models/Representative";
import { AsyncPaginate } from "react-select-async-paginate";
import WrapperPageableTable from "../../widgets/WrapperPageableTable";
import RegisterTable from "../../widgets/RegisterTable";
import { Page } from "../../models/Page";
import { BrandRegister } from "../../models/BrandRegister";

type Pageable = {
    page: number
}

interface Props {
    registerPage: Page<BrandRegister[]>;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    setSelectedRepresentative: (representative: Representative) => void;
    selectedRepresentative: Representative;
    setRegisterPage: (page: Page<BrandRegister[]>) => void;
    requestRegistersByRepresentativeName: (name: string, page: number) => void,
    getAllRepresentatives: (page: number) => Promise<Page<Representative[]>>,
    isLoading: boolean,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    messages: {
        representatives: string,
        listRegisters: string,
        noResults: string,
        number: string,
        brandText: string,
        image: string,
        classes: string,
        details: string,
        titular: string,
        address: string,
        representative: string,
        representativeAddress: string,
    }
}

const Representatives = ({ registerPage, setCurrentPage, currentPage, setRegisterPage, setSelectedRepresentative, selectedRepresentative, getAllRepresentatives, isLoading, requestRegistersByRepresentativeName, setIsLoading, messages }: Props) => {


    useEffect(() => {
        setRegisterPage({} as Page<BrandRegister[]>)
    }, [])

    const handleListBrands = async () => {
        await handleRequestData()
    }

    async function loadOptions(search: string, _: unknown, { page }: Pageable) {
        const response = await getAllRepresentatives(page)
        return {
            options: response.content,
            hasMore: !response.last,
            additional: {
                page: page + 1
            }
        }
    }

    const handleRequestData = async () => {
        setCurrentPage(0)
        requestPage(0)
    }
    const requestPage = (page: number) => {
        requestRegistersByRepresentativeName(selectedRepresentative.name, page)
    }

    const getSearchButton = () => <MyButton disabled={isLoading} label={messages.listRegisters}
        onClick={handleListBrands}
        className={"ms-2"} />

    return <>
        <Container className={"mt-2"}>
            <Row className={"mb-2"}>
                <Col>
                    <AsyncPaginate
                        onChange={setSelectedRepresentative}
                        value={selectedRepresentative}
                        loadOptions={loadOptions}
                        getOptionLabel={(option: Representative) => option.name}
                        getOptionValue={(option: Representative) => option.name}
                        additional={{ page: 0 }}
                        placeholder={"Select"}
                    />
                </Col>
            </Row>
            <WrapperPageableTable {...{
                isLoading, setIsLoading,
                table: <RegisterTable registerPage={registerPage} messages={messages} />,
                searchButton: getSearchButton(), page: registerPage, currentPage,
                setCurrentPage, requestPage, messages
            }} />

        </Container>
    </>
}

export default Representatives