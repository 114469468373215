import dayjs from "dayjs";
import { Incident } from "../../models/Incident";
import { IncidentWatchTask } from "../../models/IncidentWatchTask";
import { friendlyDate } from "../../utils/DateUtils";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import MyDatePickerIncidents from "../../widgets/MyDatePickerIncidents";
import MyButton from "../../widgets/MyButton";
import IncidentDailyWatchDetails from "./IncidentDailyWatchDetails";
import RenderIncidents from "./RenderIncidents";

type Props = {
    isLoading: boolean,
    setIsLoading: (isLoading: boolean) => void,
    selectedInstitute: string,
    watchTaskList: IncidentWatchTask[],
    incidents: Incident[],
    updateWatchTaskList: (incidents: IncidentWatchTask[]) => void,
    startDate: string,
    setStartDate: (date: string) => void,
    isFirst: boolean,
    setIsFirst: (isFirst: boolean) => void,
    setIncidents: (incidents: Incident[]) => void,
    getIncidents: (startDate: dayjs.Dayjs, selectedInstitute: string, handleResult: (result: Incident[] ) => void) => void,
    getWatchTaskList: (startDate: dayjs.Dayjs, selectedInstitute: string, handleResult: (result: IncidentWatchTask[] ) => void) => void,
    getMonthSummary: (startDate: string, origin: string) => void,
    messages: {
        searchIncidents: string, noResults: string, bulletinOpenedBy: string,
        on: string,
    },
}

const IncidentForm = ({ messages, isLoading, setIsLoading, selectedInstitute, watchTaskList, incidents, updateWatchTaskList, isFirst,
    setIncidents, setIsFirst, setStartDate, startDate, getIncidents, getMonthSummary, getWatchTaskList
}: Props) => {

    const handleGetIncident = async () => {
        setIsFirst(false)
        setIncidents([])

        const handleWatchTaskResult = async (watchList: IncidentWatchTask[]) => {
            updateWatchTaskList(watchList)
        }
        const handleResult = async (result: Incident[]) => {
            setIncidents(result)
            getWatchTaskList(dayjs(startDate), selectedInstitute, handleWatchTaskResult)
            setIsLoading(false)
        }
        getIncidents(dayjs(startDate), selectedInstitute, handleResult)
        setIsLoading(true)
    }

    const filteredWatchTaskByOrigin = () => {
        return watchTaskList?.filter(task => task.origin === selectedInstitute) ?? []
    }

    const tasksDateAndInstitute = (date: dayjs.Dayjs, institute: string) => {
        return watchTaskList?.filter(task => task.dateWatch === friendlyDate(date) && task.origin === institute) ?? []
    }

    const onMonthChange = async (date: dayjs.Dayjs) => {
        const existsTask = watchTaskList?.find(wt => date.isSame(dayjs(wt.dateWatch), 'month'))
        if (!existsTask) {
            getMonthSummary(friendlyDate(date), selectedInstitute)
        }
    }

    return <>
        <Container>
            <Row className={"mt-3"}>
                <Col>
                    <MyDatePickerIncidents
                        incidentWatchList={filteredWatchTaskByOrigin()} date={dayjs(startDate)}
                        onChangeDate={(date) => setStartDate(friendlyDate(date))}
                        onMonthChange={onMonthChange} />
                    <IncidentDailyWatchDetails tasks={tasksDateAndInstitute(dayjs(startDate), selectedInstitute)} messages={messages} />
                </Col>
            </Row>
            <div className={"mt-3"}>
                <MyButton label={messages.searchIncidents} onClick={handleGetIncident} />
            </div>
            {isLoading && <Spinner animation={"border"} />}
            {
                (incidents.length === 0 && !isLoading && !isFirst) && <div>{messages.noResults}</div>
            }
            {
                <RenderIncidents incidents={incidents} />
            }
        </Container>
    </>
}

export default IncidentForm