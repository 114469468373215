import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Team } from "../../models/Team";
import { useAppSelector } from "../hooks";
import { useKeycloak } from "@react-keycloak/web";

interface UserState {
    name: string,
    username: string,
    email: string,
    isLoggedIn: boolean,
    showLoginModal: boolean,
    userTypes: string[],
    userIsAdmin: boolean,
    userIsManager: boolean,
    allTeams: Team[],
}

const userState: UserState = {
    name: "",
    email: "",
    username: "",
    userIsAdmin: false,
    userIsManager: false,
    showLoginModal: false,
    isLoggedIn: false,
    allTeams: [],
    userTypes: [],
}

const userSlice = createSlice({
    name: "user",
    initialState: userState,

    reducers: {
        clearState: () => userState,
        setUsername: (state, action: PayloadAction<string>) => {
            state.username = action.payload
        },
        setShowLoginModal: (state, action: PayloadAction<boolean>) => {
            state.showLoginModal = action.payload
        },
        setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload
        },
        setUserTypes: (state, action: PayloadAction<string[]>) => {
            state.userTypes = action.payload
            state.userIsAdmin = action.payload.includes("ROLE_ADMIN")
            state.userIsManager = action.payload.includes("ROLE_MANAGER")
        },
        setName: (state, action: PayloadAction<string>) => {
            state.name = action.payload
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload
        },
    }
})


export default userSlice.reducer
export const clearUserState = userSlice.actions.clearState


export const useUserActions = () => {

    const { keycloak } = useKeycloak()

    const {
        allTeams,
    } = useAppSelector(state => state.user)

    return {
        name: keycloak?.tokenParsed?.name,
        email: keycloak?.tokenParsed?.email,
        userIsAdmin: keycloak?.tokenParsed?.realm_access?.roles.includes("admin") ?? false,
        userIsManager: keycloak?.tokenParsed?.realm_access?.roles.includes("manager") ?? false,
        username: keycloak?.tokenParsed?.preferred_username ?? "",
        isLoggedIn: keycloak?.authenticated ?? false,
        allTeams,
    }
}