import { friendlyDate } from "../../utils/DateUtils";
import dayjs from "dayjs";
import IncidentRequests from "../../api/customer/IncidentRequests";
import { Incident } from "../../models/Incident";
import { useAppDispatch } from "../hooks";
import { IncidentWatchTask } from "../../models/IncidentWatchTask";
import { useIncidentsWatchTaskActions } from "../slices/IncidentsWatchTaskSlice";
import { useIsLoadingAction } from "../slices/IsLoadingSlice";


const fetchIncidentsByNumber = (registerNumber: string, startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {

    const { getIncidentsByNumberAndDay } = IncidentRequests

    return async () => {
        return await getIncidentsByNumberAndDay(registerNumber, friendlyDate(startDate), friendlyDate(endDate))
    }
}

const getIncidents = (startDate: dayjs.Dayjs, selectedInstitute: string) => {

    const { getIncidents } = IncidentRequests
    return async () => {
        return await getIncidents(friendlyDate(startDate), selectedInstitute)
    }
}

const getWatchTaskList = (startDate: dayjs.Dayjs, selectedInstitute: string) => {

    const { getWatchTaskList } = IncidentRequests

    return async () => {
        return await getWatchTaskList(friendlyDate(startDate), selectedInstitute)
    }
}


const getMonthSummary = (date: string, origin: string) => {
    const { getMonthSummary } = IncidentRequests

    return async () => {
        return await getMonthSummary(date, origin)
    }
}

export const useIncidentThunks = () => {

    const dispatch = useAppDispatch()


    const { updateWatchTaskList, setIsFirst, setIncidents } = useIncidentsWatchTaskActions()
    const { isLoading, setIsLoading } = useIsLoadingAction()

    return {
        isLoading: isLoading,
        setIsLoading,
        fetchIncidentsByNumber: (registerNumber: string, startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, handleResult: (result: Incident[] ) => void) => {
            dispatch(fetchIncidentsByNumber(registerNumber, startDate, endDate)).then(r => handleResult(r))
        },
        getIncidents: (startDate: dayjs.Dayjs, selectedInstitute: string, handleResult: (result: Incident[] ) => void) => {
            setIsFirst(false)
            setIsLoading(true)
            setIncidents([])
            dispatch(getIncidents(startDate, selectedInstitute))
                .then(result => {
                    setIncidents(result)
                    dispatch(getWatchTaskList(dayjs(startDate), selectedInstitute)).then(result => {
                        updateWatchTaskList(result)
                    })
                })
                .finally(() => {
                    setIsLoading(false)
                })
        },

        getWatchTaskList: (startDate: dayjs.Dayjs, selectedInstitute: string, handleResult: (result: IncidentWatchTask[] ) => void) => {
            dispatch(getWatchTaskList(startDate, selectedInstitute)).then(t => handleResult(t))
        },
        getMonthSummary: (startDate: string, origin: string) => {
            dispatch(getMonthSummary(startDate, origin)).then(result => {
                updateWatchTaskList(result)
            })
        }

    }
}