import UserRequests from "../../api/customer/UserRequests";
import { useAppDispatch } from "../hooks";
import CustomerRequests from "../../api/customer/CustomerRequests";
import { Customer } from "../../models/Customer";
import { CustomerClass } from "../../models/CustomerClass";

import { useCustomerActions } from "../slices/CustomerSlice";
import { useIsLoadingAction } from "../slices/IsLoadingSlice";


export const useCustomerThunks = () => {

    const { setCustomers, updateCustomer, addCustomer, changeDetailsModal, customers } = useCustomerActions()

    const updateObservingClass = (customerId: number, customerClasses: CustomerClass[]) => {

        const { updateObservingClassToCustomer } = CustomerRequests
        return async () => {
            return updateObservingClassToCustomer(customerId, customerClasses)
        }
    }

    const createCustomer = (customer: Customer) => {
        const { createCustomer: createCustomerRequest } = CustomerRequests
        return async () => {
            return createCustomerRequest(customer)
        }
    }

    const fetchCustomersData = () => {
        const { getCustomerDetails } = UserRequests
        return async () => {
            const customersFromServer = await getCustomerDetails()
            if (customersFromServer) {
                setCustomers(customersFromServer)
            }
        }
    }

    const removeCustomerRequest = (id: number) => {
        const { removeCustomer } = CustomerRequests
        return async () => {
            await removeCustomer(id)
            setCustomers(customers.filter(customer => customer.id !== id))
        }
    }

    const updateCustomerInfo = (customer: Customer) => {
        const { updateCustomer: updateCustomerRequest } = CustomerRequests
        return async () => {
            if (customer.id) {
                const result = await updateCustomerRequest(customer.id, customer)
                if (result) {
                    updateCustomer(result)
                    changeDetailsModal(false)
                }
            }
        }
    }

    const { isLoading, setIsLoading } = useIsLoadingAction()

    const dispatch = useAppDispatch()
    return {
        isLoading: isLoading,
        setIsLoading: setIsLoading,
        fetchCustomers: () => {
            setIsLoading(true)
            dispatch(fetchCustomersData()).finally(() => setIsLoading(false))
        },
        removeCustomer: (id: number) => {
            setIsLoading(true)
            dispatch(removeCustomerRequest(id)).finally(() => setIsLoading(false))
        },
        updateCustomer: (customer: Customer) => {
            setIsLoading(true)
            dispatch(updateCustomerInfo(customer)).finally(() => setIsLoading(false))
        },
        createCustomer: (customer: Customer, handleResult: (customer: Customer | undefined) => void) => {
            setIsLoading(true)
            dispatch(createCustomer(customer)).then(c => handleResult(c)).finally(() => setIsLoading(false))
        },
        updateObservingClass: (customerId: number, customerClasses: CustomerClass[], handleResult: (customer: Customer | undefined) => void) => {
            setIsLoading(true)
            dispatch(updateObservingClass(customerId, customerClasses)).then(c => handleResult(c)).finally(() => setIsLoading(false))
        }
    }
}