import { Customer } from "../../models/Customer";
import { AccordionItems } from "../../models/AccordionItems";
import { Form } from "react-bootstrap";
import MyAccordion from "../../widgets/MyAccordion";
import { SimpleRegister } from "../../models/SimpleRegister";
import CustomerDetails from "./CustomerDetails";

type CustomerCheckboxesProps = {
    customers: Customer[],
    selectedSimpleRegisters: SimpleRegister[],
    setSelectedSimpleRegisters: (simpleRegister: SimpleRegister[]) => void
    allSimpleRegisters: SimpleRegister[],
    messages:{
        selectAll:string,
    }
}


const CustomerCheckboxes = ({
    customers,
    selectedSimpleRegisters,
    setSelectedSimpleRegisters,
    allSimpleRegisters,messages
}: CustomerCheckboxesProps) => {

    const isAllCustomerRegisterSelected = (customer: Customer) => {
        const customerSelected = selectedSimpleRegisters.filter(reg => reg.customerName === customer.name)
        const allCustomer = allSimpleRegisters.filter(reg => reg.customerName === customer.name)

        return customerSelected.length === allCustomer.length
    }

    const toggleCustomerCheck = (customer: Customer) => {
        if (isAllCustomerRegisterSelected(customer)) {
            setSelectedSimpleRegisters(selectedSimpleRegisters.filter(reg => reg.customerName !== customer.name))
        } else {
            const registersWithoutCustomer = selectedSimpleRegisters.filter(reg => reg.customerName !== customer.name)
            const registersAddingCustomer = [...registersWithoutCustomer, ...allSimpleRegisters.filter(reg => reg.customerName === customer.name)]
            setSelectedSimpleRegisters(registersAddingCustomer)
        }

    }

    const items = (customer: Customer): AccordionItems[] => {
        const detailsProps = {
            customerName: customer.name,
            registers: customer.brandRegisters,
            selectedSimpleRegisters: selectedSimpleRegisters,
            setSelectedSimpleRegisters: setSelectedSimpleRegisters
        }
        return [
            {
                header: <>{customer.name}</>,
                body: <>
                    <div>
                        <Form.Check inline label={messages.selectAll} checked={isAllCustomerRegisterSelected(customer)}
                            onChange={() => toggleCustomerCheck(customer)} />
                    </div>
                    <CustomerDetails {...detailsProps} />
                </>
            },
        ]
    }

    return <>{

        customers.map((customer) => {
            const accordionItems = {
                items: items(customer),
                defaultAsOpen: false
            }
                return <MyAccordion key={customer.name} {...accordionItems} />
        })
    }
    </>
}

export default CustomerCheckboxes