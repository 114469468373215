import { useIntl } from "react-intl";
import { useBulletinsActions } from "../store/slices/BulletinsSlice";
import { useBulletinsThunks } from "../store/thunks/BulletinsThunks";

export const useRepresentativesProps = () => {
  const intl = useIntl();

  const representativesMessages = {
    representatives: intl.formatMessage({ id: "model.representatives" }),
    listRegisters: intl.formatMessage({
      id: "representatives.label.listRegisters",
    }),
    noResults: intl.formatMessage({ id: "generics.label.noResults" }),
    number: intl.formatMessage({ id: "model.number" }),
    brandText: intl.formatMessage({ id: "model.brandText" }),
    image: intl.formatMessage({ id: "model.image" }),
    classes: intl.formatMessage({ id: "customers.label.classes" }),
    details: intl.formatMessage({ id: "customers.label.details" }),
    titular: intl.formatMessage({ id: "model.titular" }),
    address: intl.formatMessage({ id: "model.address" }),
    representative: intl.formatMessage({ id: "model.representative" }),
    representativeAddress: intl.formatMessage({ id: "model.address" }),
  };

  const {
    registerPage,
    currentPage,
    setCurrentPage,
    setSelectedRepresentative,
    selectedRepresentative,
    setRegisterPage,
  } = useBulletinsActions();

  const {
    isLoading: bulletinIsLoading,
    setIsLoading: setBulletinIsLoading,
    requestRegistersByRepresentativeName,
    getAllRepresentatives,
  } = useBulletinsThunks();

  return {
    representativesProps: {
      currentPage,
      registerPage,
      setCurrentPage,
      setSelectedRepresentative,
      setRegisterPage,
      selectedRepresentative,
      requestRegistersByRepresentativeName,
      getAllRepresentatives,
      isLoading: bulletinIsLoading,
      setIsLoading: setBulletinIsLoading,
      messages: representativesMessages,
    },
  };
};
