import { useIntl } from "react-intl";
import { useSimilarityActions } from "../store/slices/SimilaritySlice";
import { useIncidentThunks } from "../store/thunks/IncidentThunks";

export const useSearchIncidentsProps = () => {
  const intl = useIntl();

  const searchIncidentsMessages = {
    number: intl.formatMessage({ id: "model.number" }),
    searchIncidents: intl.formatMessage({ id: "generics.label.search" }),
    startDate: intl.formatMessage({ id: "generics.label.startDate" }),
    endDate: intl.formatMessage({ id: "generics.label.endDate" }),
  };
  const { origin } = useSimilarityActions();

  const { fetchIncidentsByNumber } = useIncidentThunks();

  return {
    searchIncidentsProps: {
      origin,
      fetchIncidentsByNumber,
      messages: searchIncidentsMessages,
    },
  };
};
