import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "../hooks";

type IsLoadingState = {
  isLoading: boolean
}

const isLoadingState: IsLoadingState = {
  isLoading: false,
}


const isLoadingSlice = createSlice({
  name: "isLoading",
  initialState: isLoadingState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    }
  }
})

export default isLoadingSlice.reducer

export const useIsLoadingAction = () => {
  const { isLoading } = useAppSelector(state => state.isLoading)
  const { setIsLoading } = isLoadingSlice.actions

  const dispatch = useAppDispatch()

  return {
    isLoading,
    setIsLoading: (isLoading: boolean) => {
      dispatch(setIsLoading(isLoading))
    }
  }
}