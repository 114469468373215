import { Container, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { LocalizationProvider, PickersDay, PickersDayProps, StaticDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { friendlyDate } from "../../utils/DateUtils";
import { mapInstitute } from "../../models/Institute";
import MySelect from "../../widgets/MySelect";
import MyButton from "../../widgets/MyButton";
import DailyWatchDetails from "../similarities/DailyWatchDetails";
import { WatchTask } from "../../models/WatchTask";
import { PublicationInfo } from "../../models/PublicationInfo";

interface Props {
    downloadAndSaveIncidents: (date: string, institute: string, successMessage: string, errorMessage: string) => void;
    downloadAndSaveRegisters: (date: string, institute: string, successMessage: string, errorMessage: string) => void;
    shutdownDownloader: (successMessage: string, errorMessage: string) => void;
    deleteRegisters: (date: string, institute: string, successMessage: string, errorMessage: string) => void;
    forceINPIUpdate: (successMessage: string, errorMessage: string) => void;
    isLoading: boolean;
    institutes: string[];
    watchTaskList: WatchTask[],
    onMonthChange: (date: Dayjs, origin: string) => void,
    getRegistersSummary: (date: Dayjs) => PublicationInfo,
    messages: {
        institute: string, forceDownload: string, forceIncidents: string, deleteRegisters: string,
        on: string,
        publications: string,
        watchStartedBy: string,
        watchEndedBy: string,
    }
}

const ForceCheck = ({ downloadAndSaveIncidents, downloadAndSaveRegisters, shutdownDownloader, deleteRegisters, forceINPIUpdate, isLoading, institutes, messages, watchTaskList, onMonthChange, getRegistersSummary }: Props) => {
    const [date, setDate] = useState(dayjs(new Date()))
    const [origin, setOrigin] = useState('PT')

    useEffect(() => {
        setDate(dayjs())
        onMonthChange(date, origin)
    }, [origin])

    const handleForceIncidentsDownload = async () => {
        downloadAndSaveIncidents(friendlyDate(date), origin, "Scheduler successfully executed", "")
    }
    const handleForceDownload = async () => {
        downloadAndSaveRegisters(friendlyDate(date), origin,
            "Scheduler successfully executed",
            "Scheduler could not be executed"
        )
    }
    const handleDeleteRegisters = () => {
        deleteRegisters(friendlyDate(date), origin,
            "Registers successfully removed",
            "Registers could not be removed")
    }
    const handleShutdownDownloader = async () => {
        shutdownDownloader("Downloader shutdown", "Error on shutdown Downloader")
    }
    const handleINPIUpdater = async () => {
        forceINPIUpdate("INPI Registers Successfully Updated", "Error on Updating INPI Registers")
    }

    const getDailyWatchDetails = (date: dayjs.Dayjs, setDate: (date: dayjs.Dayjs) => void, label: string) => {
        return {
            origin: origin,
            watchTaskList: watchTaskList,
            label: label,
            onMonthChange: onMonthChange,
            getRegistersSummary: getRegistersSummary,
            date: date,
            setDate: setDate,
            messages
        }
    }

    return (
        <>
            <Container className={"mt-2"}>
                <MySelect onChange={(evt) => setOrigin(evt.target.value)} labelId={"institute-label-id"}
                    label={messages.institute} value={origin} className={"mb-2"}
                    selectId={"institute-dropdown-id"} data={
                        institutes.map((inst: string) => {
                            const institute = mapInstitute(inst)
                            if (institute) {
                                return <option key={institute.origin} value={institute.origin}>{institute.name}</option>
                            }
                        })
                    } />

                <DailyWatchDetails {...getDailyWatchDetails(date, setDate, "")} />

                <div className={"mt-3"}>
                    <MyButton {...{ disabled: isLoading, label: messages.forceDownload, onClick: handleForceDownload }} />
                </div>
                <div className={"mt-3"}>
                    <MyButton {...{ disabled: isLoading, label: messages.forceIncidents, onClick: handleForceIncidentsDownload }} />
                </div>
                <div className={"mt-3"}>
                    <MyButton {...{ disabled: isLoading, label: messages.deleteRegisters, onClick: handleDeleteRegisters }} />
                </div>
                <div className={"mt-3"}>
                    <MyButton {...{ disabled: isLoading, label: "Shutdown Downloader", onClick: handleShutdownDownloader }} />
                </div>
                <div className={"mt-3"}>
                    <MyButton {...{ disabled: isLoading, label: "INPI Updater", onClick: handleINPIUpdater }} />
                </div>
                {isLoading && <Spinner animation={"border"} />}
            </Container>
        </>
    )
}

export default ForceCheck