import { requests } from "./API";
import { PublicationInfo } from "../../models/PublicationInfo";

const baseUrl = "/summary";
const PublicationInfoRequests = {
  getMonthPublicationInfo: (date: string, source: string) =>
    requests.get<PublicationInfo[]>(`${baseUrl}/month/${date}/${source}`),
};

export default PublicationInfoRequests;
