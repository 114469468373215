import { useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import dayjs from "dayjs";
import MyDatePicker from "../../widgets/MyDatePicker";
import { Incident } from "../../models/Incident";
import MyTextInput from "../../widgets/MyTextInput";
import MyButton from "../../widgets/MyButton";
import RenderIncidents from "./RenderIncidents";
import { WatchTask } from "../../models/WatchTask";

interface Props {
    origin: string,
    fetchIncidentsByNumber: (processNumber: string, startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, handleResult: (result: Incident[] | undefined) => void) => void;
    messages: {
        number: string,
        searchIncidents: string,
        startDate: string,
        endDate: string,
    }
}

const SearchIncidents = ({ origin, fetchIncidentsByNumber, messages }: Props) => {

    const [processNumber, setProcessNumber] = useState('');
    const [startDate, setStartDate] = useState(dayjs(new Date()))
    const [endDate, setEndDate] = useState(dayjs(new Date()))
    const [incidents, setIncidents] = useState<Incident[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFirst, setIsFirst] = useState(true);

    const handleGetIncident = async () => {
        setIsFirst(false)
        setIncidents([])
        setIsLoading(true)
        const handleResult = (result: Incident[] | undefined) => {
            if (result) {
                setIncidents(result)
            }
            setIsLoading(false)
        }
        fetchIncidentsByNumber(processNumber, startDate, endDate, handleResult)
    }


    return <>
        <Container>
            <div className={"mt-3"}>
                <MyTextInput label={messages.number} value={processNumber} onChange={(event) => setProcessNumber(event.target.value)} />
                <Row className={"mt-3"}>
                    <Col>
                        <MyDatePicker origin={origin} label={messages.startDate} date={startDate} onChangeDate={(date) => setStartDate(date)}  watchTaskList={[] as WatchTask[]}  />
                    </Col>
                    <Col>
                        <MyDatePicker origin={origin} label={messages.endDate} date={endDate} onChangeDate={(date) => setEndDate(date)}  watchTaskList={[] as WatchTask[]} />
                    </Col>

                </Row>
            </div>
            <div className={"mt-3"}>
                <MyButton label={messages.searchIncidents} onClick={handleGetIncident} />
            </div>
            {isLoading && <Spinner animation={"border"} />}

            {
                (incidents.length === 0 && !isLoading && !isFirst) && <div>Sem resultados</div>
            }
            {
                <RenderIncidents incidents={incidents} />

            }
        </Container>
    </>
}
export default SearchIncidents