import { ChangeEvent } from "react";
import { Form, Row } from "react-bootstrap";
import { Similarity } from "../../models/Similarity";


type SimilarityFilterProps = {
  similarities: Similarity[],
  filter: number,
  setFilter: (filter: number) => void,
  setSelectedSimilaritiesId: (ids: number[]) => void;
  filterLabel: string
}

const SimilarityFilter = ({ similarities, filter, filterLabel, setFilter, setSelectedSimilaritiesId }: SimilarityFilterProps) => {


  const handleRangeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedSimilaritiesId([])
    const filter = parseFloat(event.currentTarget.value)
    if (!Number.isNaN(filter)) {
      setFilter(filter)
    }
  }

  return <>
    {similarities.length !== 0  && (
      <Row>
        <Form.Label>{filterLabel}: {(filter * 100).toFixed(2)}%</Form.Label>
        <Form.Range value={filter} onChange={handleRangeChange} min={0} max={1} step={0.01} />
      </Row>
    )}
  </>
}

export default SimilarityFilter