import {IncidentWatchTask} from "../../models/IncidentWatchTask";
import {Col, Row} from "react-bootstrap";
import React from "react";

interface Props {
    tasks: IncidentWatchTask[],
    messages:{
        bulletinOpenedBy:string,
        on:string,
    }
}

const IncidentDailyWatchDetails = ({tasks,messages,}: Props) => {
    if (tasks.length === 0) return <></>
    return <>
        {

            tasks.map(({openUser, openDate,}, index) => {
                const openTaskRow = <Col><> {messages.bulletinOpenedBy} <i>{openUser}</i> {messages.on} {openDate}</>
                </Col>
                return <React.Fragment key={index}>
                    <Row className={"mt-2"}>
                        {openTaskRow}
                    </Row>
                </React.Fragment>
            })
        }
    </>
}

export default IncidentDailyWatchDetails