import {  Spinner } from "react-bootstrap";
import { Customer } from "../../models/Customer";
import MyButton from "../../widgets/MyButton";
import { BrandRegister } from "../../models/BrandRegister";
import { CustomerClass } from "../../models/CustomerClass";
import CustomerTable from "./CustomerTable";
import CustomerModals from "./CustomerModals";

interface Props {
    showNewCustomerModal: boolean;
    setShowNewCustomerModal: (show: boolean) => void;
    showUpdateModal: boolean;
    showDetailsModal: boolean;
    showRegisterModal: boolean;
    showObservingClassModal: boolean;
    customers: Customer[];
    setCustomers: (customers: Customer[]) => void;
    isLoading: boolean;
    changeDetailsModal: (show: boolean) => void;
    selectedCustomer: Customer;
    updateCustomer: (customer: Customer) => void;
    updateRegister: (customerId: number, register: BrandRegister) => void
    deleteRegister: (registerId: number) => void
    setSelectedCustomer: (customer: Customer) => void
    changeUpdateModal: (show: boolean) => void
    setSelectedRegister: (register: BrandRegister) => void
    selectedRegister: BrandRegister
    registers: BrandRegister[]
    setRegisters: (registers: BrandRegister[]) => void
    defaultCustomer: () => Customer
    defaultRegister: () => BrandRegister
    createRegister: (customerId: number, register: BrandRegister) => void
    changeRegisterModal: (show: boolean) => void
    removeCustomer: (id: number) => void
    changeObservingClassModal: (show: boolean) => void
    updateObservingClass: (customerId: number, classes: CustomerClass[], handleResult: (result: Customer | undefined) => void) => void
    createCustomer: (customer: Customer, handlerResult: (customer: Customer | undefined) => void) => void
    getRegisterDataFromAPI: (number: string, source: string, selectedCustomerId: number, handleResult: (registerFetchedFromAPI: BrandRegister) => void) => void,
    isLoadingModal: boolean,
    setIsLoadingModal: (isLoading: boolean) => void,
    institutes: string[],
    messages: {
        name: string, addNewCustomer: string, details: string, addRegister: string, updateRegisters: string, remove: string, newCustomer: string, email: string, address: string, displayName: string, language: string, close: string, saveChanges: string, createNewRegister: string, createNewRegisterFor: string, updateDetailsOf: string, removeRegister: string, isRequired: string,
        registers: string, number: string, institute: string, fetchData: string,
        brandText: string, reference: string, alias: string, image: string,
        requestDate: string, endDate: string, ignoreWords: string, classes: string,
        moreClasses: string, key: string, value: string, noRegisters: string,
    }
}

const Customers = ({ setShowNewCustomerModal, customers, isLoading, changeDetailsModal, setSelectedCustomer, changeUpdateModal, changeRegisterModal, removeCustomer, messages, showUpdateModal, showDetailsModal, showRegisterModal, showObservingClassModal, setCustomers, selectedCustomer, updateCustomer, updateRegister, deleteRegister, setSelectedRegister, selectedRegister, registers, setRegisters, defaultCustomer, defaultRegister, createRegister, changeObservingClassModal, updateObservingClass, createCustomer, getRegisterDataFromAPI, showNewCustomerModal, institutes, isLoadingModal, setIsLoadingModal, }: Props) => {

    const handleShow = () => setShowNewCustomerModal(true);


    const customerTableProps = {
        customers, messages, changeDetailsModal, changeRegisterModal, changeUpdateModal, removeCustomer, setSelectedCustomer
    }

    const customerModalsProps = {
        setShowNewCustomerModal, customers, changeDetailsModal, setSelectedCustomer, changeUpdateModal, changeRegisterModal,
        showUpdateModal, showDetailsModal, showRegisterModal, setCustomers, selectedCustomer, updateCustomer, updateRegister, deleteRegister, setSelectedRegister, selectedRegister, registers, setRegisters, defaultCustomer, defaultRegister, createRegister, changeObservingClassModal, updateObservingClass, createCustomer, getRegisterDataFromAPI, showNewCustomerModal, institutes, isLoadingModal, setIsLoadingModal, showObservingClassModal, messages
    }

    if (isLoading) {
        return <Spinner animation={"border"} />
    }

    return <>
        <div className={"p-5"}>
            <MyButton label={messages.addNewCustomer} onClick={handleShow} />
            <CustomerTable {...customerTableProps} />
            <CustomerModals {...customerModalsProps} />
        </div>
    </>
}


export default Customers;