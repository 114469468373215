import dayjs, { Dayjs } from "dayjs"
import { Row, Col } from "react-bootstrap"
import { PublicationInfo } from "../../models/PublicationInfo"
import { WatchTask } from "../../models/WatchTask"
import DailyWatchDetails from "./DailyWatchDetails"

type Props = {
  startDate: dayjs.Dayjs,
  setStartDate: (date: dayjs.Dayjs) => void,
  endDate: dayjs.Dayjs,
  setEndDate: (date: dayjs.Dayjs) => void,
  origin:string,
  watchTaskList:WatchTask[],
  startDateLabel:string,
  endDateLabel:string,
  onMonthChange: (date: Dayjs, origin: string) => void,
  getRegistersSummary: (date: Dayjs) => PublicationInfo,
  messages:{
    publications: string,
    on: string,
    watchStartedBy: string,
    watchEndedBy: string,
  }

}
const SimilarityCalendars = ({ startDate, setStartDate, endDate, setEndDate,getRegistersSummary,startDateLabel,endDateLabel,onMonthChange,origin,watchTaskList,messages }: Props) => {

  const getDailyWatchDetails = (date: dayjs.Dayjs, setDate: (date: dayjs.Dayjs) => void, label:string) => {
    return {
      origin: origin,
      watchTaskList: watchTaskList,
      label: label,
      onMonthChange: onMonthChange,
      getRegistersSummary: getRegistersSummary,
      date: date,
      setDate: setDate,
      messages
    }
  }

  return <>
    <Row>
      <Col>
        <DailyWatchDetails {...getDailyWatchDetails(startDate, setStartDate,startDateLabel)} />
      </Col>
      <Col>
        <DailyWatchDetails {...getDailyWatchDetails(endDate, setEndDate,endDateLabel)} />
      </Col>
    </Row>
  </>
}

export default SimilarityCalendars