import { useIntl } from "react-intl";
import { useIncidentsWatchTaskActions } from "../store/slices/IncidentsWatchTaskSlice";
import { useIncidentThunks } from "../store/thunks/IncidentThunks";
import { useKeycloakThunks } from "../store/thunks/KeycloakThunks";
import { useRenewalThunks } from "../store/thunks/RenewalThunks";

export const useIncidentsProps = () => {
  const intl = useIntl();

  const incidentsMessages = {
    next6MonthRenewals: intl.formatMessage({
      id: "incidents.label.next6MonthRenewals",
    }),
    institute: intl.formatMessage({ id: "model.institute" }),
    searchIncidents: intl.formatMessage({
      id: "incidents.label.searchIncidents",
    }),
    noResults: intl.formatMessage({ id: "generics.label.noResults" }),
    bulletinOpenedBy: intl.formatMessage({
      id: "incidents.label.bulletinOpenedBy",
    }),
    on: intl.formatMessage({ id: "generics.label.on" }),
    brandText: intl.formatMessage({ id: "model.brandText" }),
    endDate: intl.formatMessage({ id: "model.concessionEndDate" }),
    customer: intl.formatMessage({ id: "model.customer" }),
    reference: intl.formatMessage({ id: "model.reference" }),
    number: intl.formatMessage({ id: "model.number" }),
    source: intl.formatMessage({ id: "model.institute" }),
    monthsInAdvance: intl.formatMessage({
      id: "renewals.label.monthsInAdvance",
    }),
    search: intl.formatMessage({ id: "generics.label.search" }),
    year: intl.formatMessage({ id: "model.year" }),
    byMonths: intl.formatMessage({ id: "renewals.label.byMonths" }),
    byYear: intl.formatMessage({ id: "renewals.label.byYear" }),
    startDate: intl.formatMessage({ id: "generics.label.startDate" }),
  };

  const {
    selectedInstitute: incidentSelectedInstitute,
    setSelectedInstitute: instituteSetSelectedInstitute,
    watchTaskList: incidentWatchTaskList,
    updateWatchTaskList,
    startDate,
    setStartDate,
    incidents,
    setIncidents,
    isFirst: incidentIsFirst,
    setIsFirst: setIncidentIsFirst,
  } = useIncidentsWatchTaskActions();

  const {
    getIncidents,
    getWatchTaskList,
    getMonthSummary,
    isLoading: incidentIsLoading,
    setIsLoading: setIncidentIsLoading,
  } = useIncidentThunks();

  const { institutes } = useKeycloakThunks();

  const { fetchRenewalsByMonth } = useRenewalThunks();
  return {
    incidentsProps: {
      selectedInstitute: incidentSelectedInstitute,
      setSelectedInstitute: instituteSetSelectedInstitute,
      updateWatchTaskList,
      watchTaskList: incidentWatchTaskList,
      startDate,
      setStartDate,
      incidents,
      setIncidents,
      isFirst: incidentIsFirst,
      setIsFirst: setIncidentIsFirst,
      getIncidents,
      getWatchTaskList,
      getMonthSummary,
      isLoading: incidentIsLoading,
      setIsLoading: setIncidentIsLoading,
      institutes,
      messages: incidentsMessages,
      fetchRenewalsByMonth,
    },
  };
};
