import { useAppDispatch } from "../hooks";
import dayjs, { Dayjs } from "dayjs";
import { friendlyDate } from "../../utils/DateUtils";
import SimilarityRequests from "../../api/similarities/SimilarityRequests";
import { Similarity } from "../../models/Similarity";
import { WatchTask } from "../../models/WatchTask";
import WatchTaskRequests from "../../api/customer/WatchTaskRequests";
import { CustomerBrand, Details, OffendingBrand, } from "../../models/SimilarityDto";
import { useWatchTaskActions } from "../slices/WatchTaskSlice";
import PublicationInfoRequests from "../../api/bulletins/PublicationInfoRequests";
import { toast } from "react-toastify";
import { useSimilarityActions } from "../slices/SimilaritySlice";
import { useUserActions } from "../slices/UserSlice";
import { PublicationInfo } from "../../models/PublicationInfo";
import { SimpleRegister } from "../../models/SimpleRegister";
import { useState } from "react";


const fetchSimilaritiesWithSource = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, selectedInstitute: string, simpleRegisters: SimpleRegister[]) => {

    const { getSimilaritiesWithSourceFromSelectedRegisters } = SimilarityRequests

    return async () => {
        return await getSimilaritiesWithSourceFromSelectedRegisters(friendlyDate(startDate), friendlyDate(endDate), selectedInstitute, simpleRegisters)
    }
}

const getMonthWatchTasks = (date: string, source: string) => {

    const { getMonthWatchTasks } = WatchTaskRequests

    return async () => {
        return await getMonthWatchTasks(date, source)
    }
}

const getMonthPublicationInfo = (date: string, source: string) => {
    const { getMonthPublicationInfo } = PublicationInfoRequests


    return async () => {
        return await getMonthPublicationInfo(date, source)
    }

}

const fetchWatchTaskList = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, selectedInstitute: string) => {

    const { getWatchTaskList } = WatchTaskRequests

    return async () => {
        return await getWatchTaskList(friendlyDate(startDate), friendlyDate(endDate), selectedInstitute)
    }
}

const fetchFreeSearch = (brandText: string, observingClass: number, startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
    const { freeSearch } = SimilarityRequests

    return async () => {
        return await freeSearch(brandText, observingClass, friendlyDate(startDate), friendlyDate(endDate))
    }
}

const closeWatch = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, origin: string) => {

    const { closeWatch } = WatchTaskRequests

    return async () => {
        return await closeWatch(friendlyDate(startDate), friendlyDate(endDate), origin)
    }
}

const generateReport = (similarities: Similarity[], startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, origin: string) => {

    const { generateReport } = SimilarityRequests

    return async () => {
        return await generateReport(similarities, friendlyDate(startDate), friendlyDate(endDate), origin)
    }

}


const createSimilarity = (s: Details, customerBrand: CustomerBrand, offendingBrand: OffendingBrand) => {

    const { probability, candidate, watched, offendingNumber } = s
    const {
        reference,
        brandText,
        watchedNumber,
        watchedSource,
        watchedClasses,
        watchedImage,
        watchedTitular
    } = customerBrand
    const {
        offendingClasses,
        offendingImage,
        offendingSource,
        offendingTitular,
        originalOffendingBrand,
        datePublication
    } = offendingBrand

    return {
        probability, candidate, watched, offendingNumber,
        reference, brandText, watchedNumber, watchedSource, watchedClasses, watchedImage, watchedTitular,
        offendingClasses, offendingImage, offendingSource, offendingTitular, originalOffendingBrand, datePublication
    } as Similarity

}


export const useSimilarityThunks = () => {

    const { publicationsInfo, setPublicationsInfo, watchTaskList, setWatchTaskList, } = useWatchTaskActions()
    const { setSimilarities, setIsFirst, selectedSimilaritiesId, origin, similarities } = useSimilarityActions()
    const [isLoading, setIsLoading] = useState(false);


    const { username } = useUserActions()

    const createOpenWatchTasks = (days: dayjs.Dayjs[], selectedInstitute: string): WatchTask[] => {

        return days.filter(day => {
            return !hasOpenWatchTask(day)
        }).map(day => {
            return {
                dateWatch: friendlyDate(day),
                origin: selectedInstitute,
                openUser: username,
                openDate: friendlyDate(dayjs()),
                closeDate: "",
                closeUser: ""
            } as WatchTask
        })

    }

    const hasOpenWatchTask = (day: dayjs.Dayjs): boolean => {
        return watchTaskList.find(wt => wt.dateWatch === friendlyDate(day) && wt.closeDate === "") !== undefined
    }

    const tasksOutsideSimilarityPeriod = (startDate: Dayjs, endDate: Dayjs): WatchTask[] => {
        return watchTaskList.filter(wt => {
            const currentDate = dayjs(wt.dateWatch)
            return currentDate.isBefore(startDate, 'day')
                || currentDate.isAfter(endDate, 'day')
        })
    }

    const filterTasksToBeClosed = (startDate: Dayjs, endDate: Dayjs): WatchTask[] => {

        return watchTaskList.filter(wt => {
            const currentDate = dayjs(wt.dateWatch)
            return startDate.isSameOrBefore(currentDate, 'day')
                && endDate.isSameOrAfter(currentDate, 'day')
                && wt.closeDate !== ""
        })
    }

    const getPublicationsInfo = (date: Dayjs) => {
        return publicationsInfo.find(info => info.date === friendlyDate(date) && info.origin === origin) ?? {} as PublicationInfo
    }

    const dispatch = useAppDispatch()
    return {
        isLoading,
        setIsLoading,
        fetchSimilaritiesWithSource: (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, selectedInstitute: string, simpleRegisters: SimpleRegister[]) => {
            console.log(`fetchSimilaritiesWithSource: start`)
            setSimilarities([])

            if (startDate <= endDate) {
                setIsLoading(true)

                dispatch(fetchSimilaritiesWithSource(startDate, endDate, selectedInstitute, simpleRegisters)).then(similarityDto => {

                    if (similarityDto) {
                        const similarities = similarityDto.details.map(s => {

                            const customerBrand = similarityDto.customers.find(c => c.watchedNumber === s.customerNumber)
                            const offendingBrand = similarityDto.offendings.find(ob => ob.offendingNumber === s.offendingNumber)
                            if (customerBrand && offendingBrand) {
                                return createSimilarity(s, customerBrand, offendingBrand)
                            }
                        }).filter(s => s) as Similarity[]
                        setSimilarities(similarities)
                        const days = similarityDto.days.map(day => dayjs(day))
                        const openTasks = createOpenWatchTasks(days, origin)
                        setWatchTaskList([...watchTaskList, ...openTasks])

                    } else {
                        toast("Error fetching similarities")
                    }
                }
                ).finally(() => {
                    setIsLoading(false)
                    console.log(`fetchSimilaritiesWithSource: setIsLoading to false`)
                })
            } else {
                toast("Data fim tem de ser posterior à data de início")
            }
            console.log(`fetchSimilaritiesWithSource: end`)
        },
        fetchWatchTaskList: (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, selectedInstitute: string, handleResult: (tasks: WatchTask[]) => void) => {
            dispatch(fetchWatchTaskList(startDate, endDate, selectedInstitute)).then(handleResult).finally(() => {
                setIsLoading(false)
                console.log(`fetchWatchTaskList: setIsLoading to false`)
            })
        },
        fetchFreeSearch: (brandText: string, observingClass: number, startDate: dayjs.Dayjs, endDate: dayjs.Dayjs,) => {
            dispatch(fetchFreeSearch(brandText, observingClass, startDate, endDate)).then(result => {
                if (result) {
                    setSimilarities(result)
                }
            }).finally(() => setIsLoading(false))
        },
        generateReport: (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, origin: string,) => {
            const selectedSimilarities = selectedSimilaritiesId.map(id => {
                return similarities[id]
            })
            dispatch(generateReport(selectedSimilarities, startDate, endDate, origin)).then(result => {
                if (!result) {
                    toast("Error on fetching report")
                }
            }).finally(() => setIsLoading(false))
        },

        onMonthChange: (date: dayjs.Dayjs, origin: string) => {

            const existsSummary = publicationsInfo.find(reg =>
                date.isSame(dayjs(reg.date), 'month')
                && reg.origin === origin)
            if (!existsSummary) {
                setIsLoading(true)
                dispatch(getMonthPublicationInfo(friendlyDate(date), origin)).then(result => {
                    setPublicationsInfo([...publicationsInfo, ...result])
                }).finally(() => setIsLoading(false))
            }

            const existsTask = watchTaskList.find(wt => date.isSame(dayjs(wt.dateWatch), 'month') && wt.origin === origin)

            if (!existsTask) {
                setIsLoading(true)
                dispatch(getMonthWatchTasks(friendlyDate(date), origin)).then((result) => {
                    if (result && watchTaskList) {
                        setWatchTaskList([...watchTaskList, ...result])
                    }
                }).finally(() => setIsLoading(false))
            }
        },

        closeWatch: (startDate: Dayjs, endDate: Dayjs, origin: string) => {
            dispatch(closeWatch(startDate, endDate, origin)).then(newWatchTasks => {
                if (newWatchTasks) {
                    toast("Vigilância fechada com sucesso")
                    const filteredWatchTasks = [...tasksOutsideSimilarityPeriod(startDate, endDate), ...filterTasksToBeClosed(startDate, endDate)]
                    setWatchTaskList([...filteredWatchTasks, ...newWatchTasks])
                    setSimilarities([])

                } else {
                    toast("Data fim tem de ser posterior à data de início")
                }
            }).finally(() => setIsFirst(true))
        },
        getRegistersSummary: (date: Dayjs) => {
            return getPublicationsInfo(date)
        },
    }
}