import { useEffect, useState } from "react";
import { mapInstitute } from "../../models/Institute";
import dayjs, { Dayjs } from "dayjs";
import SimilarityTable from "./SimilarityTable";
import { SimpleRegister } from "../../models/SimpleRegister";
import { Customer } from "../../models/Customer";
import { Similarity } from "../../models/Similarity";
import { WatchTask } from "../../models/WatchTask";
import { PublicationInfo } from "../../models/PublicationInfo";
import './Similarities.css';
import SimilarityForm from "./SimilarityForm";
import Keycloak from "keycloak-js";

interface Props {
  similarities: Similarity[];
  selectedSimilaritiesId: number[];
  setSelectedSimilaritiesId: (ids: number[]) => void;
  setSimilarities: (similarities: Similarity[]) => void;
  isFirst: boolean;
  filter: number;
  origin: string;
  setOrigin: (origin: string) => void;
  setFilter: (filter: number) => void;
  fetchSimilaritiesWithSource: (startDate: Dayjs, endDate: Dayjs, origin: string, selectedRegisters: SimpleRegister[]) => void;
  onMonthChange: (date: Dayjs, origin: string) => void;
  getRegistersSummary: (date: Dayjs) => PublicationInfo;
  customers: Customer[];
  setCustomers: (customers: Customer[]) => void;
  watchTaskList: WatchTask[];
  isLoading: boolean;
  generateReport: (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, origin: string) => void,
  closeWatch: (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs, origin: string) => void,
  keycloak: Keycloak,
  messages: {
    institute: string, startDate: string, endDate: string, publications: string, searchSimilarities: string, minimumSimilarity: string, customerSelection: string, selectAll: string,
    closeWatch: string,
    generateReport: string,
    noResults: string,
    similarity: string,
    reference: string,
    number: string,
    brandText: string,
    image: string,
    classes: string,
    publication: string,
    titular: string,
    analysis: string,
    on: string,
    watchStartedBy: string,
    watchEndedBy: string,
  }
}

const Similarities = ({ similarities, selectedSimilaritiesId, setSelectedSimilaritiesId, setSimilarities, isFirst, filter, origin, setFilter, setOrigin, fetchSimilaritiesWithSource, onMonthChange, getRegistersSummary, customers, watchTaskList, isLoading, generateReport, closeWatch, keycloak, messages }: Props) => {
  const [startDate, setStartDate] = useState<Dayjs>(dayjs())
  const [endDate, setEndDate] = useState<Dayjs>(dayjs())

  useEffect(() => {
    return () => {
      setSimilarities([])
    }
  }, [customers])

  useEffect(() => {
    setStartDate(dayjs())
    setEndDate(dayjs())
    onMonthChange(startDate, origin)
    setSimilarities([])
  }, [origin])


  const similarityTableProps = {
    startDate,
    endDate,
    closeWatch,
    filter,
    generateReport,
    isFirst,
    origin,
    selectedSimilaritiesId,
    setSelectedSimilaritiesId,
    similarities,
    messages,
  }

  const institutes = keycloak?.tokenParsed?.institutes.map(inst => mapInstitute(inst))

  const similarityFormProps = {
    onChange: (evt) => setOrigin(evt.target.value),
    labelId: "institute-dropdown-label",
    label: messages.institute,
    value: origin,
    buttonLabel: messages.searchSimilarities,
    institutes,
    similarities,
    isLoading,
    isFirst,
    filter,
    filterLabel: messages.minimumSimilarity,
    setFilter,
    setSelectedSimilaritiesId,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    getRegistersSummary,
    startDateLabel: messages.startDate,
    endDateLabel: messages.endDate,
    onMonthChange,
    origin,
    setOrigin,
    watchTaskList,
    fetchSimilaritiesWithSource,
    customers,
    checkboxLabel: messages.selectAll,
    customerCheckboxLabel: messages.customerSelection, messages
  }

  return <>
    {
      <div className="similarities-container">
        <>
          <SimilarityForm {...similarityFormProps} />
          {similarities.length > 0 && <SimilarityTable {...similarityTableProps} />}
        </>
      </div>
    }
  </>

}

export default Similarities
