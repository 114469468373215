import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, PickersDay, PickersDayProps, StaticDatePicker } from "@mui/x-date-pickers";
import { TextField, TextFieldProps } from "@mui/material";
import { friendlyDate } from "../utils/DateUtils";
import dayjs, { Dayjs } from "dayjs";
import { WatchTask } from "../models/WatchTask";
import React from "react";
import { Form } from "react-bootstrap";

interface Props {
  date: dayjs.Dayjs,
  onChangeDate: (date: Dayjs) => void,
  onMonthChange?: (month: Dayjs, origin: string) => void,
  isIncident?: boolean,
  label?: string,
  watchTaskList: WatchTask[],
  origin: string
}

const isWeekend = (date: dayjs.Dayjs) => {
  return date.day() === 0 || date.day() === 6
}

const MyDatePicker = ({
  date, onChangeDate, isIncident = false, label, onMonthChange, watchTaskList, origin
}: Props) => {

  const renderWeekPickerDay = (date: dayjs.Dayjs, _: Dayjs[], pickersDayProps: PickersDayProps<Dayjs>): JSX.Element => {
    const task = watchTaskList.filter(task => task.dateWatch === friendlyDate(date))
    return renderDay(date, pickersDayProps, task[task.length-1])
  };

  const renderDay = (date: dayjs.Dayjs, pickersDayProps: PickersDayProps<Dayjs>, task?: WatchTask): JSX.Element => {

    if (task) {
      const isTaskClosed = task.closeUser
      const style = (isTaskClosed || isIncident) ? { bgcolor: 'green' } : { bgcolor: 'yellow' }
      return <PickersDay sx={style} {...pickersDayProps} key={date.toISOString()} />
    }

    if (isWeekend(date)) {
      return <React.Fragment key={date.toISOString()} />
    }
    if (date.isBefore(dayjs())) {
      return <PickersDay
        sx={{ bgcolor: 'red' }}
        {...pickersDayProps}
        key={date.toISOString()} />
    }

    return <PickersDay {...pickersDayProps} key={date.toISOString()} />
  }

  if (onMonthChange === undefined) return <></>

  return <>
    <Form.Label>{label}</Form.Label>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker displayStaticWrapperAs="desktop"
        onMonthChange={(date: Dayjs) => onMonthChange(date, origin)}
        value={date} onChange={(date) => onChangeDate(date ?? dayjs())}
        renderDay={renderWeekPickerDay}
        renderInput={(params: TextFieldProps) => {
          return <TextField{...params} />
        }}
      />
    </LocalizationProvider>

  </>
}

export default MyDatePicker