import {Table} from "react-bootstrap";
import {Renewal} from "../../models/Renewal";
import dayjs from "dayjs";

interface Props {
    renewalData: Renewal[],
    messages:{
        brandText:string,
        endDate:string,
        customer:string,
        reference:string,
        number:string,
        source:string,
    }
}

const RenewalsTable = ({renewalData,messages}: Props) => {

    if (renewalData.length === 0) return <></>
    const headers = () => {
        return [messages.brandText,messages.endDate,messages.customer,messages.reference,messages.number,messages.source]
    }

    return <>
        <Table>
            <thead>
            <tr>
                {
                headers().map((header, index) => {
                    return <th key={index}>{header}</th>
                })
            }
            </tr>

            </thead>
            <tbody>
            {
                renewalData.map(entry => {
                    const {number, brandText, origin, reference, concessionEndDate, customer} = entry
                    return <tr key={number}>
                        <td>{brandText}</td>
                        <td>{dayjs(concessionEndDate).format("DD/MM/YYYY")}</td>
                        <td>{customer}</td>
                        <td>{reference}</td>
                        <td>{number}</td>
                        <td>{origin}</td>
                    </tr>
                })
            }
            </tbody>
        </Table>
    </>
}
export default RenewalsTable