import {ChangeEvent} from "react";
import MyTextInput from "../../widgets/MyTextInput";
import RenewalsTable from "./RenewalsTable";
import {toast} from "react-toastify";
import dayjs from "dayjs";
import {Renewal} from "../../models/Renewal";
import MyButton from "../../widgets/MyButton";

interface Props {
    fetchRenewalsByYear: (year: number, handleResult: (result: Renewal[]) => void) => void;
    setRenewalsByYear: (renewals: Renewal[]) => void;
    yearRenewal: number;
    setYear: (year: number) => void;
    renewalsByYear: Renewal[];
    messages:{
        brandText:string,
        endDate:string,
        customer:string,
        reference:string,
        number:string,
        source:string,
        year:string,
        search:string,
    }
}

const RenewalsByYear = ({fetchRenewalsByYear,setRenewalsByYear,yearRenewal,setYear,renewalsByYear,messages}:Props) => {


    const handleYearSearch = async () => {

        const handleResult = (result: Renewal[]) => {
            if (result) {
                if (result.length === 0) {
                    toast("Não há renevoações para este ano")
                }
                setRenewalsByYear(result)
            }
        }

        if (yearRenewal >= dayjs().year()) {
            fetchRenewalsByYear(yearRenewal, handleResult)
        } else {
            toast("Valor inválido para ano")
        }
    }


    const handleYearChange = (event: ChangeEvent<HTMLInputElement>) => {
        const year = parseInt(event.target.value)
        if (!isNaN(year)) {
            setYear(year)
        }
    }

    return <>
        <MyTextInput type={"number"} inputClassName={"w-25"} value={yearRenewal} onChange={handleYearChange} label={messages.year}/>
        <MyButton className={"mt-2 mb-2 ms-2"} label={messages.search} onClick={handleYearSearch}/>
        <RenewalsTable renewalData={renewalsByYear} messages={messages}/>
    </>
}

export default RenewalsByYear