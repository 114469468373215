import { useAuthProps } from "./useAuthProps";
import { useBrandWithoutRepresentativesProps } from "./useBrandWithoutRepresentativesProps";
import { useBulletinsProps } from "./useBulletinsProps";
import { useCustomersProps } from "./useCustomersProps";
import { useForceCheckProps } from "./useForceCheckProps";
import { useFreeSearchProps } from "./useFreeSearchProps";
import { useIncidentsProps } from "./useIncidentsProps";
import { useKeycloakProps } from "./useKeycloakProps";
import { useRenewalsProps } from "./useRenewalsProps";
import { useRepresentativesProps } from "./useRepresentativesProps";
import { useSearchIncidentsProps } from "./useSearchIncidentsProps";
import { useSimilaritiesProps } from "./useSimilaritiesProps";
import { useStatisticsProps } from "./useStatisticsProps";
import { useUserProps } from "./useUserProps";
import { useAirProps } from "./useAirProps";

export const useProps = () => {
  const { similaritiesProps } = useSimilaritiesProps();
  const { customersProps } = useCustomersProps();
  const { forceCheckProps } = useForceCheckProps();
  const { keycloakProps } = useKeycloakProps();
  const { incidentsProps } = useIncidentsProps();
  const { renewalsProps } = useRenewalsProps();
  const { searchIncidentsProps } = useSearchIncidentsProps();
  const { freeSearchProps } = useFreeSearchProps();
  const { bulletinsProps } = useBulletinsProps();
  const { brandWithoutRepresentativesProps } =
    useBrandWithoutRepresentativesProps();
  const { representativesProps } = useRepresentativesProps();
  const { userProps } = useUserProps();
  const { statisticsProps } = useStatisticsProps();
  const { authProps } = useAuthProps();
  const { airProps } = useAirProps();

  return {
    similaritiesProps,
    customersProps,
    forceCheckProps,
    keycloakProps,
    incidentsProps,
    renewalsProps,
    searchIncidentsProps,
    freeSearchProps,
    bulletinsProps,
    brandWithoutRepresentativesProps,
    representativesProps,
    statisticsProps,
    authProps,
    userProps,
    airProps,
  };
};
