import { useState } from "react"
import { useAppDispatch } from "../hooks"
import AIRRequests from "../../api/downloader/AIRRequests"
import { toast } from "react-toastify"


const getAIRRequest = (brandText: string) => {
  const { getAIR } = AIRRequests
  return async () => {
    return await getAIR(brandText)
  }
}

export const useAIRThunks = () => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)


  return {
    isLoading,
    setIsLoading,
    getAIR: (brandText: string) => {
      setIsLoading(true)
      dispatch(getAIRRequest(brandText)).then(result=>{
        toast("AIR generated successfully")
      }).catch(error=>{
        toast.error(error)
      }).finally(()=>{
        setIsLoading(false)
      })
    }
  }
}