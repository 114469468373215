import { Form } from "react-bootstrap";
import MyButton from "../../widgets/MyButton";
import { Colors } from "../../widgets/Colors";
import { Customer } from "../../models/Customer";


interface CustomerRowProps {
  customer: Customer;
  removeCustomer: (id: number) => void;
  setSelectedCustomer: (customer: Customer) => void;
  changeUpdateModal: (show: boolean) => void;
  changeRegisterModal: (show: boolean) => void;
  changeDetailsModal: (show: boolean) => void;
  messages:{details:string,addRegister:string,updateRegisters:string,remove:string}
}

const CustomerRow = ({ customer, changeDetailsModal, changeRegisterModal, changeUpdateModal, removeCustomer, setSelectedCustomer,messages }: CustomerRowProps) => {

  if (!customer) return <></>
  const { name } = customer

  const setShowCustomerRegistersUpdateModal = (show: boolean) => {
      changeUpdateModal(show)
  }
  const setShowCustomerRegistersModal = (show: boolean) => {
      changeRegisterModal(show)
  }
  const setShowCustomerDetailsModal = (show: boolean) => {
      changeDetailsModal(show)
  }


  const handleRegistersAdd = (customer: Customer) => {
      setSelectedCustomer(customer)
      setShowCustomerRegistersModal(true)
  }


  const handleRegistersUpdate = (customer: Customer) => {
      setSelectedCustomer(customer)
      setShowCustomerRegistersUpdateModal(true)
  }

  const handleRemoveCustomer = async (id: number) => {
      const confirmation = window.confirm("Are you sure")
      if (confirmation) {
          removeCustomer(id)
      }
  }

  const handleDetailsChange = (customer: Customer) => {
      setSelectedCustomer(customer)
      setShowCustomerDetailsModal(true)
  }


  return <tr>
      <td><Form.Control name={"name"} value={name ?? ''} disabled /></td>
      <td>
          <MyButton className={"mx-2"} label={messages.details} onClick={() => handleDetailsChange(customer)}
              color={Colors.GREEN} />
          <MyButton label={messages.addRegister} onClick={() => handleRegistersAdd(customer)} />
          <MyButton className={"mx-2"} label={messages.updateRegisters}
              onClick={() => handleRegistersUpdate(customer)} color={Colors.GREEN} />
          <MyButton label={messages.remove} onClick={() => handleRemoveCustomer(customer.id ?? -1)}
              color={Colors.RED} />
      </td>
  </tr>

}


export default CustomerRow