import React from "react"
import { Incident } from "../../models/Incident"

type Props = {
  incidents: Incident[]
}

const RenderIncidents = ({ incidents }: Props) => {
  return <>
    {incidents.map(({ register: { number }, image }) => {
      return <React.Fragment key={number}>
        <div> Processo: {number}</div>
        <figure>
          <img style={{ borderStyle: "solid", borderColor: "black", borderWidth: "1px" }}
            alt={`Incident for ${number}`} width={1200}
            src={`data:image/png;base64,${image}`} />
        </figure>
      </React.Fragment>
    }
    )}
  </>
}

export default RenderIncidents