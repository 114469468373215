import { Col, Container, Row, } from "react-bootstrap";
import MyDatePicker from "../../widgets/MyDatePicker";
import dayjs from "dayjs";
import MySelect from "../../widgets/MySelect";
import { mapInstitute } from "../../models/Institute";
import MyButton from "../../widgets/MyButton";
import RegisterTable from "../../widgets/RegisterTable";
import WrapperPageableTable from "../../widgets/WrapperPageableTable";
import { Page } from "../../models/Page";
import { BrandRegister } from "../../models/BrandRegister";
import { WatchTask } from "../../models/WatchTask";

interface Props {
    setRegisterPage: (page: Page<BrandRegister[]>) => void;
    registerPage: Page<BrandRegister[]>;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    setPublicationDate: (date: dayjs.Dayjs) => void;
    publicationDate: string;
    selectedInstitute: string;
    setSelectedInstitute: (institute: string) => void;
    getAllRegistersByDateAndSource: (date: string, source: string, page: number) => Promise<Page<BrandRegister[]>>;
    institutes: string[];
    origin: string,
    messages: {
        institute: string, listPublications: string, noResults: string,
        number: string,
        brandText: string,
        image: string,
        classes: string,
        details: string,
        titular: string,
        address: string,
        representative: string,
        representativeAddress: string,
    }
}

const Bulletins = ({ setCurrentPage, currentPage, setRegisterPage, registerPage, isLoading, setIsLoading, setPublicationDate, publicationDate, setSelectedInstitute, selectedInstitute, getAllRegistersByDateAndSource, institutes, origin, messages }: Props) => {

    const handleRequestData = async () => {
        setRegisterPage({} as Page<BrandRegister[]>)
        setIsLoading(true)
        setCurrentPage(0)
        await requestPage(0)
        setIsLoading(false)
    }
    const requestPage = async (page: number) => {
        const result = await getAllRegistersByDateAndSource(publicationDate, selectedInstitute, page)
        if (result) {
            setRegisterPage(result)
        }
    }

    return <>
        <Container className={"mb-2 mt-5"}>
            <Row className={"mt-2"}>
                <Col>

                    <MyDatePicker {...{
                        onMonthChange: () => { }, isIncident: false, date: dayjs(publicationDate), onChangeDate: setPublicationDate, origin, watchTaskList:[] as WatchTask[]
                    }} />
                </Col>
                <Col>
                    <MySelect {...{
                        onChange: (evt) => setSelectedInstitute(evt.target.value),
                        labelId: "institute-dropdown-label2", label: messages.institute, value: selectedInstitute,
                        className: "mb-2", selectId: "institute-dropdown", data: institutes.map((inst: string) => {
                            const institute = mapInstitute(inst)
                            if (institute) {
                                return <option key={institute.origin}
                                    value={institute.origin}>{institute.name}</option>
                            }
                        })
                    }}
                    />

                </Col>
            </Row>

            <Row>
                <WrapperPageableTable
                    {...{
                        isLoading, setIsLoading,
                        table: <RegisterTable registerPage={registerPage} messages={messages} />,
                        searchButton: <MyButton {...{ disabled: isLoading, label: messages.listPublications, onClick: handleRequestData }} />,
                        page: registerPage, currentPage,
                        setCurrentPage, requestPage,
                        messages
                    }}
                />
            </Row>
        </Container>
    </>
}

export default Bulletins;