import { useUserActions } from "../store/slices/UserSlice";
import { useAuthentication } from "../store/thunks/AuthenticationThunks";

export const useAuthProps = () => {
  const { isLoggedIn, userIsAdmin, userIsManager, name } = useUserActions();

  const { doPostLogin, doLogout, isLoading } = useAuthentication();
  return {
    authProps: {
      doPostLogin,
      doLogout,
      isLoading,
      isLoggedIn,
      userIsAdmin,
      userIsManager,
      name,
    },
  };
};
