import { useState } from "react"
import MyTextInput from "../../widgets/MyTextInput"
import MyButton from "../../widgets/MyButton"
import { useAIRThunks } from "../../store/thunks/AIRThunks"
import { Spinner } from "react-bootstrap"

type Props={
 messages:{airButtonLabel:string},
 getAIR:(brandText:string)=>void
}

const AIR=({messages}:Props)=>{

  const {getAIR,isLoading}=useAIRThunks()

  const [brandText,setBrandText]=useState('')
  const handleGenerateAIR=()=>{
    getAIR(brandText)
  }

  return <>
      {isLoading && <Spinner animation="border" />}
      <MyTextInput onChange={(evt)=>setBrandText(evt.target.value)}  />
      <MyButton disabled={isLoading} label={messages.airButtonLabel} onClick={handleGenerateAIR} />
  </>
}

export default AIR