import { ChangeEvent, useEffect } from "react";
import RegisterForm from "./RegisterForm";
import { Customer } from "../../models/Customer";
import { BrandRegister } from "../../models/BrandRegister";
import { SelectChangeEvent } from "@mui/material";
import { Colors } from "../../widgets/Colors";

interface Props {
    updateRegister: (customerId: number, register: BrandRegister) => void
    deleteRegister: (registerId: number) => void
    showUpdateModal: boolean
    selectedCustomer: Customer
    setSelectedCustomer: (customer: Customer) => void
    changeUpdateModal: (show: boolean) => void
    updateCustomer: (customer: Customer) => void
    setSelectedRegister: (register: BrandRegister) => void
    selectedRegister: BrandRegister,
    registers: BrandRegister[]
    setRegisters: (registers: BrandRegister[]) => void
    defaultCustomer: () => Customer
    defaultRegister: () => BrandRegister,
    getRegisterDataFromAPI: (number: string, source: string, selectedCustomerId: number, handleResult: (registerFetchedFromAPI: BrandRegister) => void) => void,
    isLoadingModal: boolean,
    setIsLoadingModal: (isLoading: boolean) => void,
    institutes: string[],
    messages: {
        updateDetailsOf: string, removeRegister: string, close: string, saveChanges: string,
        registers: string, number: string, institute: string, fetchData: string,
        brandText: string, reference: string, alias: string, image: string,
        requestDate: string, endDate: string, ignoreWords: string, classes: string,
        moreClasses: string, key: string, value: string, noRegisters: string,
    }
}

const CustomerRegisterUpdateFormModal = ({ updateRegister, deleteRegister, showUpdateModal, selectedCustomer, setSelectedCustomer, changeUpdateModal, updateCustomer, setSelectedRegister, selectedRegister, registers, setRegisters, defaultCustomer, defaultRegister, getRegisterDataFromAPI, isLoadingModal, setIsLoadingModal, institutes, messages }: Props) => {

    useEffect(() => {
        const registers = selectedCustomer.brandRegisters
        setRegisters(registers ?? [])
        if (registers && registers?.length > 0) {
            setSelectedRegister(registers[0])
        }
    }, [selectedCustomer])

    const setShowCustomerRegistersUpdateModal = (show: boolean) => {
        changeUpdateModal(show)
    }
    const actualCustomer = (): Customer => {
        return selectedCustomer ?? defaultCustomer()
    }
    const handleClose = () => {
        setSelectedRegister({} as BrandRegister)
        setShowCustomerRegistersUpdateModal(false)
    };
    const handleRemoveRegister = async () => {
        if (selectedRegister && selectedRegister.id) {
            deleteRegister(selectedRegister.id)
            const updatedRegisters = registers.filter(brandRegister => brandRegister.id !== selectedRegister.id)
            setRegisters(updatedRegisters)
            const updatedCustomer = { ...selectedCustomer, brandRegisters: updatedRegisters }
            updateCustomer(updatedCustomer)
            setSelectedRegister(defaultRegister())
            setSelectedCustomer(updatedCustomer)
        }
    }
    const handleRegisterSelection = (event: ChangeEvent<HTMLInputElement & HTMLSelectElement> | SelectChangeEvent<any>) => {
        const selectedRegister = registers.find(register => register.id === parseInt(event.target.value))
        if (selectedRegister) {
            setSelectedRegister(selectedRegister)
        }
    }

    const handleUpdateRegister = async (register: BrandRegister) => {
        if (register && selectedCustomer.id) {
            updateRegister(selectedCustomer.id, register)
            handleClose()
        }
    }

    const registerFormProps = {
        getRegisterDataFromAPI, institutes, isLoadingModal, selectedCustomer, selectedRegister, setIsLoadingModal, onSelectChange: handleRegisterSelection,
        isUpdate: true, show: showUpdateModal, title: `${messages.updateDetailsOf} ${actualCustomer()?.name}`,
        setSelectedRegister,
        actions:
            [
                { label: messages.removeRegister, action: handleRemoveRegister, color: Colors.RED, isSubmit: false },
                { label: messages.close, action: handleClose, color: Colors.GRAY, isSubmit: false },
                { label: messages.saveChanges, action: handleUpdateRegister, color: Colors.BLUE, isSubmit: true }
            ],
        messages, handleClose
    }

    return (
        <RegisterForm {...registerFormProps} size={"lg"} />
    )
}

export default CustomerRegisterUpdateFormModal;