import {LOCALES} from "./locales";

export const messages  = {
    [LOCALES.ENGLISH]: {
        "generics.label.on": "On",
        "generics.label.close": "Close",
        "generics.label.saveChanges": "Save Changes",
        "generics.label.noResults": "No Results",
        "generics.label.remove": "Remove",
        "generics.label.search": "Search",
        "generics.label.startDate": "Start Date",
        "generics.label.endDate": "End Date",
        "generics.label.classes": "Classes",

        "generics.errors.isRequired":"Is Required",

        "model.name":"Name",
        "model.year":"Year",
        "model.class":"Class",
        "model.displayName":"Display Name",
        "model.email":"Email",
        "model.address":"Address",
        "model.language":"Language",
        "model.number":"Number",
        "model.institute":"Institute",
        "model.brandText":"Brand Text",
        "model.alias":"Alias",
        "model.image":"Image",
        "model.requestDate":"Request Date",
        "model.concessionEndDate":"Concession End Date",
        "model.key":"Key",
        "model.value":"Value",
        "model.reference": "Reference",
        "model.customer": "Customer",
        "model.source":"Source",
        "model.titular": "Titular",
        "model.representative": "Representative",
        "model.representatives": "Representatives",



        // NAV
        "nav.label.home": "Home",
        "nav.label.logout": "Logout",
        "nav.label.customers": "Customers",
        "nav.label.incidents": "Incidents",
        "nav.label.similarities": "Similarities",
        "nav.label.profile": "Profile",
        "nav.label.team": "Team",
        "nav.label.keycloak": "Keycloak",
        "nav.label.forceScheduler": "Force Scheduler",
        "nav.label.freeSearch": "Free Search",
        "nav.label.searchIncidents": "Search Incidents",
        "nav.label.renewals": "Renewals",
        "nav.label.bulletins": "Bulletins",
        "nav.label.registersWithoutRepresentatives": "Registers Without Representative",
        "nav.label.listRepresentatives": "List Representatives",
        "nav.label.statistics": "Statistics",
        "nav.label.air": "AIR",
        //Clients
        "customers.label.name": "Name",
        "customers.label.addNewCustomer": "Add New Customer",
        "customers.label.details": "Details",
        "customers.label.addRegister": "Add Register",
        "customers.label.updateRegisters": "Update Registers",
        "customers.label.newCustomer": "New Customer",
        "customers.label.registers": "Registers",
        "customers.label.fetchData": "Fetch Data",
        "customers.label.ignoreWords": "Ignore Words?",
        "customers.label.classes": "Classes",
        "customers.label.moreClasses": "More Classes",
        "customers.label.noRegisters": "No Registers Yet",
        "customers.label.createNewRegister": "Create New Register",
        "customers.label.createNewRegisterFor": "Create New Register For",
        "customers.label.updateDetailsOf": "Update Details Of",
        "customers.label.removeRegister": "Remove Register",
        //Incidents
        "incidents.label.next6MonthRenewals": "Renewals Within 6 Months",
        "incidents.label.customer": "Customer",
        "incidents.label.source": "Source",
        "incidents.label.noResults": "No Results",
        "incidents.label.bulletinOpenedBy": "Bulletin Opened By",
        // "incidents.label.on": "On",
        //Index
        "similarities.label.startDate": "Start Date",
        "similarities.label.publications": "Publications",
        "similarities.label.searchSimilarities": "Search Similarities",
        "similarities.label.watchStartedBy": "Watch Started By",
        "similarities.label.watchEndedBy": "Watch Ended By",
        "similarities.label.closeWatch": "Close Watch",
        "similarities.label.generateReport": "Generate Report",
        "similarities.label.minimumSimilarity": "Minimum Similarity",
        "similarities.label.similarity": "Similarity",
        "similarities.label.publication": "Publication",
        "similarities.label.titular": "Titular",
        "similarities.label.analysis": "Analysis",
        "similarities.label.customerSelection":"Customers selection",
        "similarities.label.selectAllRegisters":"Select All Registers",
        //Profile
        "profile.label.managePassword": "Manage Password",
        "profile.label.sendPasswordResetEmail": "Send password reset email",
        "profile.label.password": "Password",
        "profile.label.confirmPassword": "Confirm Password",
        "profile.label.changePassword": "Change Password",
        "profile.label.manageIgnoredWords": "Manage Ignored Words",
        "profile.label.wordsToBeIgnored": "Words To Be Ignored",
        "profile.label.word": "Word",
        "profile.label.newWord": "New Word",
        "profile.label.newClass": "New Class",
        "profile.label.manageRelatedClass": "Manage Related Classes",
        "profile.label.relatedClasses": "Related Classes",
        "profile.label.class": "Class",
        //Keycloak
        "keycloak.label.teamManagement": "Team Management",
        "keycloak.label.newTeam": "New Team",
        "keycloak.label.team": "Team",
        "keycloak.label.createTeam": "Create Team",
        "keycloak.label.updateInstitutes": "Update Institutes",
        "keycloak.label.username": "Username",
        "keycloak.label.firstName": "First Name",
        "keycloak.label.lastName": "Last Name",
        "keycloak.label.createUser": "Create User",
        "keycloak.label.newUser": "New User",
        "keycloak.label.associateTeam": "Associate Team",
        "keycloak.label.userManagement": "User Management",
        //ForceScheduler
        "forceScheduler.label.forceDownload": "Force Download",
        "forceScheduler.label.forceIncidents": "Force Incidents",
        "forceScheduler.label.deleteRegisters": "Delete Registers",
        //FreeSearch
        "freeSearch.label.class": "Class",
        "freeSearch.label.search": "Search",
        // Renewals
        "renewals.label.byMonths": "By Months",
        "renewals.label.monthsInAdvance": "Months In Advance",
        "renewals.label.byYear": "By Year",
        "renewals.label.year": "Year",

        "brandsWithoutRepresentative.label.listRegistersWithoutRepresentatives": "List Registers Without Representative",

        //Bulletins
        "bulletins.label.listPublications": "List Publications",
        "bulletins.label.representative": "Representative",

        "representatives.label.representatives": "Representatives",
        "representatives.label.listRegisters": "List Registers",


        "bulletins.label.listTitularWithoutRepresentatives": "List Titular Without Representatives",
        "bulletins.label.listRepresentativeWithMostRegisters": "List Representative With Most Registers",
        "bulletins.label.quantity":"Quantity",

        "air.label.generate":"Generate AIR",
        //ERRORS



    } as Record<string, string>,

    [LOCALES.PORTUGUESE]: {
        "model.name":"Nome",
        "model.year":"Ano",
        "model.displayName":"Nome De Exibição",
        "model.email":"Email",
        "model.class":"Classe",
        "model.address":"Morada",
        "model.language":"Idioma",
        "model.number":"Número",
        "model.institute":"Instituto",
        "model.brandText":"Sinal",
        "model.alias":"Alias",
        "model.image":"Imagem",
        "model.requestDate":"Data De Pedido",
        "model.concessionEndDate":"Data Do Fim De Pedido",
        "model.key":"Chave",
        "model.value":"Valor",
        "model.reference": "Referência",
        "model.customer": "Cliente",
        "model.source":"Origem",
        "model.titular": "Titular",
        "model.representative": "Mandatário",
        "model.representatives": "Mandatários",

        "generics.label.on": "Em",
        "generics.label.close": "Fechar",
        "generics.label.saveChanges": "Guardar Mudanças",
        "generics.label.noResults": "Sem Resultados",
        "generics.label.isRequired":"É Obrigatório",
        "generics.label.remove":"Remove",
        "generics.label.search": "Pesquisar",
        "generics.label.startDate": "Data De Início",
        "generics.label.endDate": "Data De Fim",
        "generics.label.classes": "Classes",
        "generics.errors.isRequired":"É Obrigatório",

        "nav.label.home": "Início",
        "nav.label.logout": "Sair",
        "nav.label.customers": "Clientes",
        "nav.label.incidents": "Incidentes",
        "nav.label.similarities": "Similaridades",
        "nav.label.profile": "Perfil",
        "nav.label.team": "Equipa",
        "nav.label.keycloak": "Keycloak",
        "nav.label.forceScheduler": "Forçar Calendarizador",
        "nav.label.freeSearch": "Pesquisa Livre",
        "nav.label.searchIncidents": "Pesquisa Incidentes",
        "nav.label.renewals": "Renovações",
        "nav.label.bulletins": "Boletins",
        "nav.label.registersWithoutRepresentatives": "Marcas Sem Mandatários",
        "nav.label.listRepresentatives": "Lista Mandatários",
        "nav.label.statistics": "Estatísticas",
        "nav.label.air": "AIR",

        "customers.label.name": "Nome",
        "customers.label.addNewCustomer": "Adicionar Novo Cliente",
        "customers.label.details": "Detalhes",
        "customers.label.addRegister": "Adiciona Registo",
        "customers.label.updateRegisters": "Atualiza Registos",
        "customers.label.newCustomer": "Novo Cliente",
        "customers.label.registers": "Registos",
        "customers.label.fetchData": "Buscar Dados",
        "customers.label.ignoreWords": "Ignorar Palavras?",
        "customers.label.classes": "Classes",
        "customers.label.moreClasses": "Mais Classes",
        "customers.label.noRegisters": "Sem Registos",
        "customers.label.createNewRegister": "Criar Novo Registo",
        "customers.label.createNewRegisterFor": "Criar Novo Registo Para",
        "customers.label.updateDetailsOf": "Atualiza Detalhes De",
        "customers.label.removeRegister": "Remove Registo",

        "incidents.label.next6MonthRenewals": "Renovações Em 6 Meses",
        "incidents.label.customer": "Cliente",
        "incidents.label.source": "Origem",
        "incidents.label.noResults": "Sem Resultados",
        "incidents.label.bulletinOpenedBy": "Boletim Aberto Por",
        "incidents.label.startDate": "Data De Início",
        "incidents.label.endDate": "Data De Fim",
        "incidents.label.searchIncidents": "Pesquisa Incidentes",

        "similarities.label.startDate": "Data De Início",
        "similarities.label.publications": "Publicações",
        "similarities.label.searchSimilarities": "Pesquisar Similaridades",
        "similarities.label.watchStartedBy": "Vigilância Iniciada Por",
        "similarities.label.watchEndedBy": "Vigilância Terminada Por",
        "similarities.label.closeWatch": "Fechar Vigilância",
        "similarities.label.generateReport": "Gerar Relatório",
        "similarities.label.minimumSimilarity": "Semelhança Mínima",
        "similarities.label.similarity": "Semelhança",
        "similarities.label.publication": "Publicação",
        // "similarities.label.titular": "Titular",
        "similarities.label.analysis": "Análise",
        "similarities.label.customerSelection":"Seleção de clientes",
        "similarities.label.selectAllRegisters":"Selecione Todos os Registos",

        "profile.label.managePassword": "Gerir Palavra-Passe",
        "profile.label.sendPasswordResetEmail": "Enviar email para modificar palavra-passe",
        "profile.label.password": "Palavra-Passe",
        "profile.label.confirmPassword": "Confirme Palavra-Passe",
        "profile.label.changePassword": "Altera Palavra-Passe",
        "profile.label.manageIgnoredWords": "Gerir Palavras Ignoradas",
        "profile.label.wordsToBeIgnored": "Palavras A Serem Ignoradas",
        "profile.label.word": "Palavra",
        "profile.label.newWord": "Nova Palavra",
        "profile.label.newClass": "Nova Classe",
        "profile.label.manageRelatedClass": "Gerir Classes Relacionadas",
        "profile.label.relatedClasses": "Classes Relacionadas",
        "profile.label.class": "Classe",

        "keycloak.label.teamManagement": "Gerir Equipa",
        "keycloak.label.newTeam": "Nova Equipa",
        "keycloak.label.team": "Equipa",
        "keycloak.label.createTeam": "Criar Equipa",
        "keycloak.label.updateInstitutes": "Atualizar Institutos",
        "keycloak.label.newUser": "Novo Utilizador",
        "keycloak.label.username": "Nome de Utilizador",
        "keycloak.label.firstName": "Primeiro Nome",
        "keycloak.label.lastName": "Último Nome",
        "keycloak.label.createUser": "Criar Utilizador",
        "keycloak.label.associateTeam": "Associar Equipa",
        "keycloak.label.userManagement": "Gerir Utilizador",
        "forceScheduler.label.forceDownload": "Força Download",
        "forceScheduler.label.forceIncidents": "Força Incidentes",
        "forceScheduler.label.deleteRegisters": "Remove Registos",
        "freeSearch.label.class": "Classe",
        "freeSearch.label.search": "Pesquisar",
        "renewals.label.byMonths": "Por Meses",
        "renewals.label.monthsInAdvance": "Meses De Antecedência",
        "renewals.label.byYear": "Por Ano",
        "renewals.label.year": "Ano",

        "brandsWithoutRepresentative.label.listRegistersWithoutRepresentatives": "Listar Marcas Sem Mandatários",

        "representatives.label.representatives": "Mandatários",
        "representatives.label.listRegisters": "Listar Registos",

        "bulletins.label.listPublications": "Listar Publicações",
        "bulletins.label.representative": "Mandatário",

        "bulletins.label.listTitularWithoutRepresentatives": "Lista Titulares Sem Mandatários",
        "bulletins.label.listRepresentativeWithMostRegisters": "Lista Mandatários Com Maior Quantidade De Marcas",
        "bulletins.label.quantity":"Quantidade",

        "air.label.generate":"Gerar AIR",
    } as Record<string, string>,

}