import RegisterForm from "./RegisterForm";
import { Customer } from "../../models/Customer";
import { BrandRegister } from "../../models/BrandRegister";
import { Colors } from "../../widgets/Colors";

interface Props {
    createRegister: (customerId: number, register: BrandRegister) => void
    showRegisterModal: boolean
    selectedCustomer: Customer,
    changeRegisterModal: (show: boolean) => void
    setSelectedRegister: (register: BrandRegister) => void
    defaultCustomer: () => Customer
    defaultRegister: () => BrandRegister,
    getRegisterDataFromAPI: (number: string, source: string, selectedCustomerId: number, handleResult: (registerFetchedFromAPI: BrandRegister) => void) => void,
    isLoadingModal: boolean,
    setIsLoadingModal: (isLoading: boolean) => void,
    institutes: string[],
    messages: {
        createNewRegister: string, createNewRegisterFor: string, close: string,

        registers: string, number: string, institute: string, fetchData: string,
        brandText: string, reference: string, alias: string, image: string,
        requestDate: string, endDate: string, ignoreWords: string, classes: string,
        moreClasses: string, key: string, value: string, noRegisters: string,
    }
}

const CustomerRegisterFormModal = ({ createRegister, changeRegisterModal, selectedCustomer, setSelectedRegister, showRegisterModal, defaultCustomer, defaultRegister, getRegisterDataFromAPI, isLoadingModal, setIsLoadingModal, institutes, messages }: Props) => {


    const setShowCustomerRegistersModal = (show: boolean) => {
        changeRegisterModal(show)
    }
    const actualCustomer = (): Customer => {
        return selectedCustomer ?? defaultCustomer()
    }
    const handleClose = () => {
        setSelectedRegister(defaultRegister())
        setShowCustomerRegistersModal(false)
    };

    const handleCreateRegister = async (register: BrandRegister) => {
        if (!selectedCustomer.id || !selectedCustomer.brandRegisters) {
            return
        }
        createRegister(selectedCustomer.id, register)
        setSelectedRegister(defaultRegister())
        setShowCustomerRegistersModal(false)
    }

    const registerFormProps = {
        getRegisterDataFromAPI, institutes, isLoadingModal,
        setIsLoadingModal, selectedCustomer,
        onSelectChange: () => { },
        isUpdate: false, show: showRegisterModal,
        title: `${messages.createNewRegisterFor} ${actualCustomer()?.name}`,
        setSelectedRegister,
        actions: [
            { label: messages.close, action: handleClose, color: Colors.GRAY, isSubmit: false },
            { label: messages.createNewRegister, action: handleCreateRegister, color: Colors.BLUE, isSubmit: true }
        ],
        messages,
        handleClose,
    }

    return <RegisterForm {...registerFormProps} size="lg" />

}

export default CustomerRegisterFormModal;