import { useIntl } from "react-intl";
import { useAIRThunks } from "../store/thunks/AIRThunks";

export const useAirProps = () => {
  const {getAIR,isLoading}=useAIRThunks()
  const intl = useIntl();
  const messages={
    airButtonLabel:intl.formatMessage({ id: "air.label.generate" }),
  }

  return {
    airProps:{
      getAIR,
      isLoading,
      messages,
    },
  };
};