import { Col, Form, Row } from "react-bootstrap";
import React from "react";
import dayjs, { Dayjs } from "dayjs";
import MyDatePicker from "../../widgets/MyDatePicker";
import { friendlyDate } from "../../utils/DateUtils";
import { WatchTask } from "../../models/WatchTask";
import { PublicationInfo } from "../../models/PublicationInfo";


interface Props {
  date: Dayjs,
  setDate: (date: Dayjs) => void,
  label: string,
  origin: string,
  watchTaskList: WatchTask[],
  onMonthChange: (month: Dayjs, origin: string) => void,
  getRegistersSummary: (date: Dayjs) => PublicationInfo,
  messages: {
    publications: string,
    on: string,
    watchStartedBy: string,
    watchEndedBy: string,
  }
}

const DailyWatchDetails = ({ date, setDate, label, origin, watchTaskList, onMonthChange, getRegistersSummary,messages }: Props) => {

  const brandRegisterSummary = getRegistersSummary(date)

  const tasksByDateAndOrigin = (date: dayjs.Dayjs) => {
    return watchTaskList?.filter(task => task.dateWatch === friendlyDate(date) && task.origin === origin) ?? []
  }

  const tasks = tasksByDateAndOrigin(date)


  if (!tasks && !brandRegisterSummary) return <></>
  return <>
    <Form.Label>{label}</Form.Label>
    <MyDatePicker watchTaskList={watchTaskList} onMonthChange={onMonthChange}
      date={dayjs(date)} onChangeDate={(day: Dayjs) => setDate(day)} origin={origin}
    />
    {tasks?.map(({ openUser, openDate, closeUser, closeDate }, index) => {

      const openTaskRow = <Col> {messages.watchStartedBy} <i>{openUser}</i> {messages.on} {openDate}</Col>
      const closeTaskRow = <Col>{messages.watchEndedBy} <i>{closeUser}</i> {messages.on} {closeDate}</Col>
      return <React.Fragment key={index}>
        <Row className={"mt-2"}>
          {!(closeDate && closeUser) ? <b>{openTaskRow}</b> : <>{openTaskRow}</>}
        </Row>
        <Row>
          {closeDate && closeUser &&
            <>{closeTaskRow}</>
          }
        </Row>

      </React.Fragment>
    })}
    <Row>
      <Col>{messages.publications}: {brandRegisterSummary.count ? brandRegisterSummary.count : 0}</Col>
    </Row>
  </>
}

export default DailyWatchDetails