import { useEffect } from "react";
import KeycloakTeamManagement from "./KeycloakTeamManagement";
import MyAccordion from "../../widgets/MyAccordion";
import { AccordionItems } from "../../models/AccordionItems";
import KeycloakUserManagement from "./KeycloakUserManagement";
import { Spinner } from "react-bootstrap";
import { KeycloakGroup } from "../../models/keycloak/KeycloakGroup";
import { KeycloakUser } from "../../models/keycloak/KeycloakUser";

interface Props {
    fetchKeycloakData: () => void;
    isLoading: boolean;
    setGroups: (groups: KeycloakGroup[]) => void
    groups: KeycloakGroup[]
    createGroup: (group: KeycloakGroup) => void
    updateGroupInstitute: (group: KeycloakGroup) => void
    deleteGroup: (group: KeycloakGroup) => void
    users: KeycloakUser[];
    setUsers: (users: KeycloakUser[]) => void
    createUser: (user: KeycloakUser, callback: (newUserId: string) => void) => void
    sendUserPostCreationMail: (userId: string, callback: () => void) => void
    deleteUser: (user: KeycloakUser) => void
    associateUserToTeam: (user: KeycloakUser) => void,
    messages:{
        teamManagement:string,
        userManagement:string,
        newTeam: string,
        name: string,
        createTeam: string,
        institute: string,
        updateInstitutes: string,
        remove: string,
        newUser:string,
        username: string,
        firstName: string,
        lastName: string,
        email: string,
        createUser: string,
        team: string,
        associateTeam: string,
    }
}

const KeycloakView = ({fetchKeycloakData, isLoading, setGroups, groups, createGroup, updateGroupInstitute, deleteGroup, users, setUsers, createUser, sendUserPostCreationMail, deleteUser, associateUserToTeam,messages}:Props) => {

    useEffect(() => {
       fetchKeycloakData()
    }, [])

    const items = (): AccordionItems[] => {
        return [
            {
                header: messages.teamManagement, body:
                    <KeycloakTeamManagement setGroups={setGroups} groups={groups} createGroup={createGroup} updateGroupInstitute={updateGroupInstitute} deleteGroup={deleteGroup} messages={messages} />
            },
            {
                header: messages.userManagement,
                body: <KeycloakUserManagement associateUserToTeam={associateUserToTeam} createUser={createUser} deleteUser={deleteUser} groups={groups} isLoading={isLoading} sendUserPostCreationMail={sendUserPostCreationMail} setUsers={setUsers} users={users} messages={messages} />
            }
        ]
    }

    return <>
        <h1>Keycloak Management</h1>
        {isLoading && <Spinner animation={"border"} />}
        {!isLoading && <MyAccordion items={items()} />}
    </>
}


export default KeycloakView;