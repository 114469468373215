import MyButton from "../../widgets/MyButton";
import { Spinner } from "react-bootstrap";

interface Props {
    resetPassword: (userId: string) => void;
    isLoading: boolean;
    userId: string;
    messages:{
        sendPasswordResetEmail:string
    }
}

const PasswordChangeForm = ({ resetPassword, isLoading, userId,messages }: Props) => {


    const handleSendResetPasswordLink = async () => {
        if (userId) {
            resetPassword(userId)
        }
    }

    return (
        <>
            <MyButton disabled={isLoading} label={messages.sendPasswordResetEmail} onClick={handleSendResetPasswordLink} />
            {isLoading && <Spinner animation={"border"} />}
        </>
    )

}
export default PasswordChangeForm