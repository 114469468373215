import { Form } from "react-bootstrap";
import { SimpleRegister } from "../../models/SimpleRegister";
import { BrandRegister } from "../../models/BrandRegister";

export type CustomerDetailsProps = {
  customerName: string,
  registers:BrandRegister[],
  selectedSimpleRegisters: SimpleRegister[],
  setSelectedSimpleRegisters: (simpleRegister: SimpleRegister[]) => void
}

const CustomerDetails = ({ customerName,registers,selectedSimpleRegisters,setSelectedSimpleRegisters}: CustomerDetailsProps) => {

      const handleCheckboxChange = (simpleRegister: SimpleRegister) => {
          if (selectedSimpleRegisters.some(item => item.number === simpleRegister.number && item.institute === simpleRegister.institute)) {
              setSelectedSimpleRegisters(selectedSimpleRegisters.filter((item) => item.number !== simpleRegister.number || item.institute !== simpleRegister.institute));
          } else {
              setSelectedSimpleRegisters([...selectedSimpleRegisters, simpleRegister]);
          }
      };

  return <>
      {
          registers.map(register => {
              const simpleRegister = {
                  number: register?.number,
                  institute: register?.source,
                  customerName: customerName
              } as SimpleRegister
              return simpleRegister ? <Form.Check
                  inline
                  key={register.number}
                  label={register.brandText}
                  type={"checkbox"}
                  name={"registers"}
                  checked={selectedSimpleRegisters.some(item => item.number === simpleRegister.number && item.institute === simpleRegister.institute)}
                  onChange={() => handleCheckboxChange(simpleRegister)}
              /> : undefined
          })
      }
  </>
}

export default CustomerDetails