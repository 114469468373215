import { Container } from "react-bootstrap";
import { RepresentativeCount } from "../../models/RepresentativeCount";
import MyButton from "../../widgets/MyButton";
import { Page } from "../../models/Page";
import RepresentativeStatisticsTable from "./RepresentativeStatisticsTable";
import WrapperPageableTable from "../../widgets/WrapperPageableTable";

interface Props {
    currentPage: number;
    setCurrentPage: (page: number) => void;
    representativeCount: Page<RepresentativeCount[]>;
    setRepresentatives: (page: Page<RepresentativeCount[]>) => void;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    getRepresentativesBrandsCounts: (page: number) => Promise<Page<RepresentativeCount[]>>,
    messages: {
        search: string,
        name: string,
        quantity: string,
        origin: string,
        noResults: string,
    }
}


const RepresentativeStatistics = ({ setCurrentPage, currentPage, getRepresentativesBrandsCounts, representativeCount, setRepresentatives, isLoading, setIsLoading, messages }: Props) => {

    const handleRequestInitialData = async () => {
        setRepresentatives({} as Page<RepresentativeCount[]>)
        setCurrentPage(0)
        await requestPage(0)
    }

    const requestPage = async (page: number) => {
        const result = await getRepresentativesBrandsCounts(page)
        if (result) {
            setRepresentatives(result)
        }
    }

    return <>
        <Container className={"mt-3"} fluid>
            <WrapperPageableTable {...{
                isLoading, setIsLoading,
                searchButton: <MyButton {...{
                    disabled: isLoading, label: messages.search, onClick: handleRequestInitialData,
                    className: "mt-2"
                }} />,
                table: <RepresentativeStatisticsTable {...{ representativeCount, messages }} />,
                page: representativeCount, currentPage,
                setCurrentPage, requestPage,messages
            }}
            />
        </Container>
    </>
}
export default RepresentativeStatistics