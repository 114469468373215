import {ChangeEvent} from "react";
import MyTextInput from "../../widgets/MyTextInput";
import RenewalsTable from "./RenewalsTable";
import {toast} from "react-toastify";
import {Renewal} from "../../models/Renewal";
import MyButton from "../../widgets/MyButton";

interface Props {
    fetchRenewalsByMonth: (month: number, handleResult: (result: Renewal[]) => void) => void;
    setRenewalsByMonth: (renewals: Renewal[]) => void;
    monthRenewal: number;
    setMonth: (month: number) => void;
    renewalsByMonth: Renewal[];
    messages:{
        brandText:string,
        endDate:string,
        customer:string,
        reference:string,
        number:string,
        source:string,
        monthsInAdvance:string,
        search:string
    };
}

const RenewalsByMonth = ({fetchRenewalsByMonth,monthRenewal,renewalsByMonth,setMonth,setRenewalsByMonth,messages}:Props) => {

    const handleMonthSearch = async () => {

        const handleResult = (result: Renewal[]) => {
            if (result) {
                if (result.length === 0) {
                    toast("Não há renevoações para este período")
                }
                setRenewalsByMonth(result)
            }
        }

        if (monthRenewal > 0) {
            fetchRenewalsByMonth(monthRenewal, handleResult)
        } else {
            toast("Valor inválido para meses")
        }
    }

    const handleMonthChange = (event: ChangeEvent<HTMLInputElement>) => {
        const month = parseInt(event.target.value)
        if (!isNaN(month)) {
            setMonth(month)
        }
    }


    return <>
        <MyTextInput type={"number"} inputClassName={"w-25"} value={monthRenewal}
                     onChange={handleMonthChange} label={messages.monthsInAdvance}/>
        <MyButton className={"mt-2 mb-2 ms-2"} label={messages.search} onClick={handleMonthSearch}/>
        <RenewalsTable renewalData={renewalsByMonth} messages={messages}/>

    </>
}
export default RenewalsByMonth