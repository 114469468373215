import dayjs, { Dayjs } from "dayjs";
import { Form, Spinner} from "react-bootstrap";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Institute } from "../../models/Institute";
import { PublicationInfo } from "../../models/PublicationInfo";
import { Similarity } from "../../models/Similarity";
import { SimpleRegister } from "../../models/SimpleRegister";
import { WatchTask } from "../../models/WatchTask";
import MyAccordion from "../../widgets/MyAccordion";
import MyButton from "../../widgets/MyButton";
import MySelect from "../../widgets/MySelect";
import SimilarityCalendars from "./SimilarityCalendars";
import SimilarityFilter from "./SimilarityFilter";
import { Customer } from "../../models/Customer";
import CustomerCheckboxes from "./CustomerCheckboxes";


type Props = {
  customers: Customer[],
  setOrigin: (value: string) => void,
  label: string,
  institutes: Institute[],
  startDate: dayjs.Dayjs,
  setStartDate: (date: dayjs.Dayjs) => void,
  endDate: dayjs.Dayjs,
  setEndDate: (date: dayjs.Dayjs) => void,
  getRegistersSummary: (date: Dayjs) => PublicationInfo;
  startDateLabel: string,
  endDateLabel: string,
  onMonthChange: (date: dayjs.Dayjs, origin: string) => void,
  origin: string,
  watchTaskList: WatchTask[],
  similarities: Similarity[],
  isFirst: boolean,
  filter: number,
  setFilter: (filter: number) => void,
  filterLabel: string,
  setSelectedSimilaritiesId: (ids: number[]) => void,
  isLoading: boolean,
  buttonLabel: string,
  fetchSimilaritiesWithSource: (startDate: Dayjs, endDate: Dayjs, origin: string, selectedRegisters: SimpleRegister[]) => void;
  checkboxLabel:string,
  customerCheckboxLabel:string,
  messages:{
    selectAll:string,
    publications: string,
    on: string,
    watchStartedBy: string,
    watchEndedBy: string,
  }
}

const SimilarityForm = ({ customers, setOrigin, label, institutes, endDate, endDateLabel, filter, filterLabel, getRegistersSummary, isFirst, onMonthChange, origin, setEndDate, setFilter, setSelectedSimilaritiesId, setStartDate, similarities, startDate, startDateLabel, watchTaskList, buttonLabel, fetchSimilaritiesWithSource, isLoading,checkboxLabel,customerCheckboxLabel,messages }: Props) => {

  const [allRegisters, setAllRegisters] = useState<SimpleRegister[]>([])
  const [selectedRegisters, setSelectedRegisters] = useState<SimpleRegister[]>([]);
  useEffect(() => {
    const registers = simpleRegisters(customers)
    setAllRegisters(registers)
    setSelectedRegisters(registers)
  }, [])

  const isAllRegistersSelected = () => {
    if (selectedRegisters.length !== allRegisters.length) return false
    return selectedRegisters.every(selReg => allRegisters.some(reg => reg.number === selReg.number && reg.institute === selReg.institute))
  }

  const handleCheckboxChange = () => {
    if (isAllRegistersSelected()) {
      setSelectedRegisters([])
    } else {
      setSelectedRegisters([...allRegisters])
    }
  }

  const simpleRegisters = useCallback((customers: Customer[]) => customers.flatMap(cust => {
    return cust.brandRegisters.map(br => {
      return {
        institute: br.source,
        number: br.number,
        brandText: br.brandText,
        customerName: cust.name
      } as SimpleRegister
    })
  }), [])

  const items = () => {
    const customerCheckboxesProps = {
      customers: customers,
      selectedSimpleRegisters: selectedRegisters,
      setSelectedSimpleRegisters: setSelectedRegisters,
      allSimpleRegisters: allRegisters,
      messages
    }
    return {
      header: <>{customerCheckboxLabel}</>,
      body: <>
        <div>
          <Form.Check
            inline key={"1"}
            label={checkboxLabel} type={"checkbox"}
            name={"registers"} checked={isAllRegistersSelected()}
            onChange={() => handleCheckboxChange()}
          />
        </div>
        <CustomerCheckboxes {...customerCheckboxesProps} />
      </>
    }
  }

 const memoizedItems = useMemo(() => items, [items]);

  const institutesOptions = useMemo(() => {
    return institutes?.map((institute: Institute) => {
      if (institute) {
        return <option key={institute.origin}
          value={institute.origin}>{institute.name}</option>
      }
    })
  }, [institutes])

  const handleSearchSimilarities = async () => {
    fetchSimilaritiesWithSource(startDate, endDate, origin, selectedRegisters)
  }

  const mySelectProps = {
    onChange: (evt) => setOrigin(evt.target.value),
    labelId: "institute-dropdown-label",
    label: label,
    value: origin,
    className: "mb-2",
    selectId: "institute-dropdown",
    data: institutesOptions
  }

  const calendarProps = {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    getRegistersSummary,
    startDateLabel: startDateLabel,
    endDateLabel: endDateLabel,
    onMonthChange,
    origin,
    watchTaskList,
    messages
  }

  const similarityFilterProps = {
    similarities,
    isFirst,
    filter,
    filterLabel: filterLabel,
    setFilter,
    setSelectedSimilaritiesId
  }


  return <>
    <MyAccordion items={[memoizedItems()]} defaultAsOpen={false} />
    <MySelect {...mySelectProps} />
    <SimilarityCalendars {...calendarProps} />
    <MyButton className={"mb-2"} disabled={isLoading} label={buttonLabel}
      onClick={handleSearchSimilarities} />
    <SimilarityFilter {...similarityFilterProps} />
    {isLoading && <Spinner animation="border" />}
  </>
}

export default SimilarityForm