import { BrandRegister } from "../../models/BrandRegister"
import { Customer } from "../../models/Customer"
import { CustomerClass } from "../../models/CustomerClass"
import CustomerDetailsModal from "./CustomerDetailsModal"
import CustomerFormModal from "./CustomerFormModal"
import CustomerObservingClassFormModal from "./CustomerObservingClassFormModal"
import CustomerRegisterFormModal from "./CustomerRegisterFormModal"
import CustomerRegisterUpdateFormModal from "./CustomerRegisterUpdateFormModal"

type Props = {
  showNewCustomerModal: boolean;
  setShowNewCustomerModal: (show: boolean) => void;
  showUpdateModal: boolean;
  showDetailsModal: boolean;
  showRegisterModal: boolean;
  showObservingClassModal: boolean;
  customers: Customer[];
  setCustomers: (customers: Customer[]) => void;
  changeDetailsModal: (show: boolean) => void;
  selectedCustomer: Customer;
  updateCustomer: (customer: Customer) => void;
  updateRegister: (customerId: number, register: BrandRegister) => void
  deleteRegister: (registerId: number) => void
  setSelectedCustomer: (customer: Customer) => void
  changeUpdateModal: (show: boolean) => void
  setSelectedRegister: (register: BrandRegister) => void
  selectedRegister: BrandRegister
  registers: BrandRegister[]
  setRegisters: (registers: BrandRegister[]) => void
  defaultCustomer: () => Customer
  defaultRegister: () => BrandRegister
  createRegister: (customerId: number, register: BrandRegister) => void
  changeRegisterModal: (show: boolean) => void
  changeObservingClassModal: (show: boolean) => void
  updateObservingClass: (customerId: number, classes: CustomerClass[], handleResult: (result: Customer | undefined) => void) => void
  createCustomer: (customer: Customer, handlerResult: (customer: Customer | undefined) => void) => void
  getRegisterDataFromAPI: (number: string, source: string, selectedCustomerId: number, handleResult: (registerFetchedFromAPI: BrandRegister) => void) => void,
  isLoadingModal: boolean,
  setIsLoadingModal: (isLoading: boolean) => void,
  institutes: string[],
  messages: {
    newCustomer: string, name: string, email: string, address: string, displayName: string, language: string, close: string, saveChanges: string, createNewRegister: string, createNewRegisterFor: string, updateDetailsOf: string, removeRegister: string, isRequired: string,
    registers: string, number: string, institute: string, fetchData: string,
    brandText: string, reference: string, alias: string, image: string,
    requestDate: string, endDate: string, ignoreWords: string, classes: string,
    moreClasses: string, key: string, value: string, noRegisters: string,
  }
}

const CustomerModals = ({ showNewCustomerModal, setShowNewCustomerModal, showUpdateModal, showDetailsModal, showObservingClassModal, showRegisterModal, customers, setCustomers, changeDetailsModal, selectedCustomer, updateCustomer, updateRegister, deleteRegister, setSelectedCustomer, changeUpdateModal, setSelectedRegister, selectedRegister, registers, setRegisters, defaultCustomer, defaultRegister, createRegister, changeRegisterModal, changeObservingClassModal, updateObservingClass, createCustomer, getRegisterDataFromAPI, institutes, isLoadingModal, setIsLoadingModal, messages }: Props) => {

  const newCustomerModalProps = {
    createCustomer, customers, setCustomers, setShowNewCustomerModal, showNewCustomerModal, messages
  }

  const customerObservingClassModal = {
    changeObservingClassModal, customers, defaultCustomer, selectedCustomer, setSelectedCustomer, showObservingClassModal, updateCustomer, updateObservingClass
  }

  const customerRegisterFormModalProps = {
    setIsLoadingModal, getRegisterDataFromAPI, institutes, isLoadingModal, changeRegisterModal, createRegister, defaultCustomer, defaultRegister, selectedCustomer, selectedRegister, setSelectedRegister, showRegisterModal, messages
  }

  const customerRegisterUpdateFormModalProps = {
    setIsLoadingModal, getRegisterDataFromAPI, institutes, isLoadingModal, changeUpdateModal, defaultCustomer, defaultRegister, deleteRegister, registers, selectedCustomer, selectedRegister, setRegisters, setSelectedCustomer, setSelectedRegister, showUpdateModal, updateCustomer, updateRegister, messages
  }

  const customerDetailsModalProps = {
    changeDetailsModal, selectedCustomer, showDetailsModal, updateCustomer, messages,
  }

  return <>
    {showNewCustomerModal && <CustomerFormModal {...newCustomerModalProps} />}
    {showObservingClassModal && <CustomerObservingClassFormModal {...customerObservingClassModal} />}
    {showRegisterModal && <CustomerRegisterFormModal {...customerRegisterFormModalProps} />}
    {showUpdateModal && <CustomerRegisterUpdateFormModal {...customerRegisterUpdateFormModalProps} /> }
    {showDetailsModal &&<CustomerDetailsModal {...customerDetailsModalProps} /> }
  </>

}

export default CustomerModals