import { useIntl } from "react-intl";
import { useCustomerThunks } from "../store/thunks/CustomerThunks";
import { useCustomerActions } from "../store/slices/CustomerSlice";
import { useBrandRegisterThunks } from "../store/thunks/BrandRegisterThunks";
import { useBrandRegistersAction } from "../store/slices/BrandRegisterSlice";
import { useKeycloakThunks } from "../store/thunks/KeycloakThunks";

export const useCustomersProps = () => {
  const intl = useIntl();

  const customerMessages = {
    name: intl.formatMessage({ id: "model.name" }),
    addNewCustomer: intl.formatMessage({
      id: "customers.label.addNewCustomer",
    }),
    details: intl.formatMessage({ id: "customers.label.details" }),
    addRegister: intl.formatMessage({ id: "customers.label.addRegister" }),
    updateRegisters: intl.formatMessage({
      id: "customers.label.updateRegisters",
    }),
    remove: intl.formatMessage({ id: "generics.label.remove" }),
    newCustomer: intl.formatMessage({ id: "customers.label.newCustomer" }),
    email: intl.formatMessage({ id: "model.email" }),
    address: intl.formatMessage({ id: "model.address" }),
    displayName: intl.formatMessage({ id: "model.displayName" }),
    language: intl.formatMessage({ id: "model.language" }),
    close: intl.formatMessage({ id: "generics.label.close" }),
    saveChanges: intl.formatMessage({ id: "generics.label.saveChanges" }),
    createNewRegister: intl.formatMessage({
      id: "customers.label.createNewRegister",
    }),
    createNewRegisterFor: intl.formatMessage({
      id: "customers.label.createNewRegisterFor",
    }),
    updateDetailsOf: intl.formatMessage({
      id: "customers.label.updateDetailsOf",
    }),
    removeRegister: intl.formatMessage({
      id: "customers.label.removeRegister",
    }),
    isRequired: intl.formatMessage({ id: "generics.errors.isRequired" }),
    registers: intl.formatMessage({ id: "customers.label.registers" }),
    number: intl.formatMessage({ id: "model.number" }),
    institute: intl.formatMessage({ id: "model.institute" }),
    fetchData: intl.formatMessage({ id: "customers.label.fetchData" }),
    brandText: intl.formatMessage({ id: "model.brandText" }),
    reference: intl.formatMessage({ id: "model.reference" }),
    alias: intl.formatMessage({ id: "model.alias" }),
    image: intl.formatMessage({ id: "model.image" }),
    requestDate: intl.formatMessage({ id: "model.requestDate" }),
    endDate: intl.formatMessage({ id: "model.concessionEndDate" }),
    ignoreWords: intl.formatMessage({ id: "customers.label.ignoreWords" }),
    classes: intl.formatMessage({ id: "customers.label.classes" }),
    moreClasses: intl.formatMessage({ id: "customers.label.moreClasses" }),
    key: intl.formatMessage({ id: "model.key" }),
    value: intl.formatMessage({ id: "model.value" }),
    noRegisters: intl.formatMessage({ id: "customers.label.noRegisters" }),
  };

  const { institutes } = useKeycloakThunks();

  const {
    customers,
    setCustomers,
    showNewCustomerModal,
    setShowNewCustomerModal,
    showUpdateModal,
    showDetailsModal,
    showRegisterModal,
    showObservingClassModal,
    changeDetailsModal,
    selectedCustomer,
    setSelectedCustomer,
    changeUpdateModal,
    setSelectedRegister,
    selectedRegister,
    defaultCustomer,
    defaultRegister,
    changeRegisterModal,
    changeObservingClassModal,
  } = useCustomerActions();
  const {
    fetchCustomers,
    isLoading: customerIsLoading,
    updateCustomer,
    removeCustomer,
    updateObservingClass,
    createCustomer,
  } = useCustomerThunks();

  const {
    updateRegister,
    deleteRegister,
    createRegister,
    getRegisterDataFromAPI,
    isLoadingModal,
    setIsLoadingModal,
  } = useBrandRegisterThunks();

  const { registers, setRegisters } = useBrandRegistersAction();

  return {
    customersProps: {
      setIsLoadingModal,
      getRegisterDataFromAPI,
      institutes,
      showNewCustomerModal,
      isLoadingModal,
      showDetailsModal,
      customers,
      fetchCustomers,
      isLoading: customerIsLoading,
      showObservingClassModal,
      showRegisterModal,
      showUpdateModal,
      setCustomers,
      setShowNewCustomerModal,
      changeDetailsModal,
      selectedCustomer,
      updateCustomer,
      changeUpdateModal,
      defaultCustomer,
      defaultRegister,
      deleteRegister,
      registers,
      selectedRegister,
      setRegisters,
      setSelectedCustomer,
      setSelectedRegister,
      updateRegister,
      changeRegisterModal,
      createRegister,
      removeCustomer,
      changeObservingClassModal,
      updateObservingClass,
      createCustomer,
      messages: customerMessages,
    },
  };
};
