import { Col, Form, Image, Row, Spinner } from "react-bootstrap";
import { ChangeEvent, useEffect, } from "react";
import { BrandRegister } from "../../models/BrandRegister";
import MyTextInput from "../../widgets/MyTextInput";
import { mapInstitute } from "../../models/Institute";
import MySelect from "../../widgets/MySelect";
import MyButton from "../../widgets/MyButton";
import { Colors } from "../../widgets/Colors";
import { array, boolean, number, object, string } from 'yup';
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import MyModal from "../../widgets/MyModal";
import { Customer } from "../../models/Customer";

interface Action {
    action: (arg: any) => void
    label: string,
    color: Colors
    isSubmit: boolean
}

const RegisterSchema = object<BrandRegister>().shape({
    id: number().nullable().optional(),
    number: string().required(),
    source: string().required(),
    brandText: string().required(),
    reference: string().optional(),
    alias: string().optional(),
    image: string().optional(),
    dateRequest: string().required(),
    datePublication: string().optional(),
    concessionEndDate: string().optional(),
    bypass: boolean().required().default(false),
    registerClasses: array().min(1).of(
        object().shape({
            key: string().required(),
            value: string().optional(),
        })
    ).required()
}).required()

type RegisterFormInputs = "id" | "number" | "source" | "brandText" | "reference" | "alias" | "image" | "dateRequest" | "datePublication" | "concessionEndDate" | "bypass" | "registerClasses";

interface Props {
    onSelectChange: (event: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => void,
    isUpdate: boolean,

    show: boolean,
    size: "sm" | "lg" | "xl",
    title: string,
    actions: Action[],
    selectedCustomer: Customer,
    selectedRegister?: BrandRegister,
    setSelectedRegister: (register: BrandRegister) => void,
    getRegisterDataFromAPI: (number: string, source: string, selectedCustomerId: number, handleResult: (registerFetchedFromAPI: BrandRegister) => void) => void,
    isLoadingModal: boolean,
    setIsLoadingModal: (isLoading: boolean) => void,
    institutes: string[],
    messages: {
        registers: string, number: string, institute: string, fetchData: string,
        brandText: string, reference: string, alias: string, image: string,
        requestDate: string, endDate: string, ignoreWords: string, classes: string,
        moreClasses: string, key: string, value: string, noRegisters: string,
    },
    handleClose: () => void,
}

const RegisterForm = ({
        show, size, title, actions,
        isUpdate = false, onSelectChange = () => { },
        selectedCustomer, getRegisterDataFromAPI, isLoadingModal, setIsLoadingModal,
        institutes, messages, handleClose, selectedRegister,setSelectedRegister
    }: Props) => {

    const updateYup = (register: BrandRegister) => {
        setValue("id", register.id )
        setValue("number", register.number)
        setValue("source", register.source ?? "PT")
        setValue("brandText", register.brandText)
        setValue("reference", register.reference ?? "")
        setValue("alias", register.alias)
        setValue("image", register.image ?? "")
        setValue("dateRequest", register.dateRequest)
        setValue("datePublication", register.dateRequest)
        setValue("concessionEndDate", register.concessionEndDate)
        setValue("bypass", register.bypass)
        setValue("registerClasses", register.registerClasses)
    }

    const {
        register: formHookRegister,
        handleSubmit,
        formState: { errors },
        watch,
        control,
        getValues,
        reset,
        setValue
    } = useForm({
        resolver: yupResolver(RegisterSchema)
    });

    const {
        append,
        remove,
    } = useFieldArray({
        control,
        name: "registerClasses"
    });

    const registers = selectedCustomer?.brandRegisters
    const currentSource = watch("source")
    const image = getValues("image")

    useEffect(() => {
        if (selectedRegister) {
            updateYup(selectedRegister)
        } else {
            setValue("source", "PT")
        }
        return () => {
            updateYup({} as BrandRegister)
            reset()
        }
    }, [selectedRegister])


    const handleAddNewClass = () => {
        if (getValues("registerClasses")?.filter(rc => rc.key === "").length === 0) {
            append({ key: "", value: "" })
        }
    }

    const handleRemoveClass = (index: number) => {
        remove(index)
    }

    const handleFetchDataFromApi = async () => {
        if (selectedCustomer?.id) {
            setIsLoadingModal(true)
            const number = getValues("number")
            const source = getValues("source")
            reset()
            const handleResult = (registerFetchedFromAPI: BrandRegister) => {
                setIsLoadingModal(false)
                if (registerFetchedFromAPI) {
                    if(!registerFetchedFromAPI.concessionEndDate){
                        registerFetchedFromAPI.concessionEndDate = registerFetchedFromAPI.dateRequest
                    }
                    updateYup(registerFetchedFromAPI)
                }
            }
            getRegisterDataFromAPI(number, source, selectedCustomer.id, handleResult)
        }
    }

    const getRegisters = () => {
        if (registers?.length === 0) {
            return [<option key={0}>{messages.noRegisters}</option>]
        }

        return [registers?.map(register =>
            <option
                value={register.id ?? ''} key={register.number}>{register.brandText}</option>)]
    }
    const isValidButton = (): boolean => {
        return !isUpdate || (isUpdate && selectedRegister !== undefined && selectedRegister.number !== undefined && selectedRegister.number.length > 0)
    }

    const formFooter = () => {
        return <>
            {
                actions.map(({ action, label, color, isSubmit }, index) => {
                    if (isSubmit) {
                        return <MyButton disabled={!isValidButton()} key={index} label={label}
                            onClick={handleSubmit(action)} color={color} />
                    } else {
                        return <MyButton key={index} label={label} onClick={action} color={color} />
                    }

                })
            }
        </>
    }

    const showFetchButton = () => currentSource && (currentSource === "PT" || currentSource === "EU")

    const renderClass = (index: number) => {
        return <Row key={index}>
            <Col>
                <MyTextInput type={"number"} inputClassName={"mb-2"}
                    label={messages.key}{...formHookRegister(`registerClasses.${index}.key`)}
                    errorText={errors.registerClasses?.message ?? ""}
                />

            </Col>
            <Col>
                <MyTextInput inputClassName={"mb-2"}
                    label={messages.value}{...formHookRegister(`registerClasses.${index}.value`)}
                    errorText={errors.registerClasses?.message ?? ""}
                />
            </Col>
            <Col>
                <Form.Label className={"mb-2 invisible"}> 1</Form.Label>
                <div>
                    <MyButton label={"remove"} onClick={() => handleRemoveClass(index)} />

                </div>

            </Col>
        </Row>
    }

    const getCol = (label: string, registerInputId: RegisterFormInputs, errorText: string | undefined, className = "mb-2") => {
        return <Col> <MyTextInput inputClassName={className} label={label} {...formHookRegister(registerInputId)} errorText={errorText} />
        </Col>
    }
    const getColDate = (label: string, registerInputId: RegisterFormInputs, errorText: string | undefined, className = "mb-2") => {
        return <Col> <MyTextInput inputClassName={className} label={label} {...formHookRegister(registerInputId)} errorText={errorText} type={"date"} />
        </Col>
    }

    const formBody = () => {
        return <Form>
            <Form.Group>

                {
                    isUpdate && <>
                        <MySelect labelId={""} label={messages.registers}
                            onChange={onSelectChange} value={selectedRegister?.id}
                            className={"mb-2"} selectId={""} data={getRegisters()}
                        />
                    </>
                }
                <Row className={"mb-1"}>
                    {getCol(messages.number, "number", errors.number?.message)}
                    <Col>
                        <MySelect labelId={""} label={messages.institute} className={"mb-2"} selectId={""}
                            {...formHookRegister("source")} errorText={errors.source?.message} data={
                                institutes.map((inst: string) => {
                                    const institute = mapInstitute(inst)
                                    if (institute) {
                                        return <option key={institute.origin}
                                            value={institute.origin}>{institute.name}</option>
                                    }
                                })
                            } />
                    </Col>
                </Row>
                {
                    showFetchButton() && <Row className={"mb-1"}>
                        <Col>
                            <MyButton label={messages.fetchData} onClick={handleFetchDataFromApi} />
                        </Col>
                    </Row>
                }
                <Row>
                    {getCol(messages.brandText, "brandText", errors.brandText?.message)}
                    {getCol(messages.reference, "reference", errors.reference?.message)}
                </Row>
                <Row>
                    {getCol(messages.alias, "alias", errors.alias?.message, "mb-1")}
                    <Col></Col>
                </Row>

                {
                    image && <Row>
                        <Form.Label>{messages.image}</Form.Label>
                        <Col>
                            <Image src={image ? `data:image/png;base64,${image}` : ''}
                                height={200} width={200} />
                        </Col>
                    </Row>
                }

                <Row>
                    {getColDate(messages.requestDate, "dateRequest", errors.dateRequest?.message)}
                    {getColDate(messages.endDate, "concessionEndDate", errors.concessionEndDate?.message)}
                </Row>

                <Row className={"mt-2"}>
                    <Col>
                        <Form.Check label={messages.ignoreWords}
                            {...formHookRegister("bypass")} />
                        {errors.bypass?.message && <p className={"text-danger"}>{errors.bypass?.message}</p>}
                    </Col>

                </Row>
                <Row className={"mt-2"}>
                    <Form.Label>{messages.classes}</Form.Label>

                </Row>
                {
                    getValues("registerClasses")?.map((_, index) => renderClass(index))
                }
                {errors.registerClasses?.message && <p className={"text-danger"}>{errors.registerClasses?.message}</p>}
                <MyButton label={messages.moreClasses} onClick={handleAddNewClass} color={Colors.GREEN} />
            </Form.Group>
            {isLoadingModal && <Spinner animation={"border"} />}
        </Form>
    }

    const myModalProps = {
        show, title, body: formBody(), footer: formFooter(), handleClose
    }

    return <>
        <MyModal {...myModalProps} size={size ?? "lg"} />

    </>
}

export default RegisterForm;