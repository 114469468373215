import { TitularCount } from "../../models/TitularCount"

type Props = {
  titular: TitularCount[]
}

const RenderTitularCount = ({ titular }: Props) => {
  return <>
    {titular.map(({ titularName, count, origin }) => {
      return <tr key={titularName}>
        <td>{titularName}</td>
        <td>{count}</td>
        <td>{origin}</td>
      </tr>
    })}
  </>
}

export default RenderTitularCount