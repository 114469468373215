import { useIntl } from "react-intl";
import { useDownloaderThunks } from "../store/thunks/DownloaderThunks";
import { useKeycloakThunks } from "../store/thunks/KeycloakThunks";
import { useWatchTaskActions } from "../store/slices/WatchTaskSlice";
import { useSimilarityThunks } from "../store/thunks/SimilarityThunks";

export const useForceCheckProps = () => {
  const intl = useIntl();
  const { institutes } = useKeycloakThunks();

  const forceCheckMessages = {
    institute: intl.formatMessage({ id: "model.institute" }),
    forceDownload: intl.formatMessage({
      id: "forceScheduler.label.forceDownload",
    }),
    forceIncidents: intl.formatMessage({
      id: "forceScheduler.label.forceIncidents",
    }),
    deleteRegisters: intl.formatMessage({
      id: "forceScheduler.label.deleteRegisters",
    }),
    on: intl.formatMessage({ id: "generics.label.on" }),
    watchStartedBy: intl.formatMessage({
      id: "similarities.label.watchStartedBy",
    }),
    watchEndedBy: intl.formatMessage({ id: "similarities.label.watchEndedBy" }),
    publications: intl.formatMessage({ id: "similarities.label.publications" }),
  };

  const { watchTaskList } = useWatchTaskActions();

  const {
    downloadAndSaveIncidents,
    downloadAndSaveRegisters,
    shutdownDownloader,
    deleteRegisters,
    forceINPIUpdate,
    isLoading: forceCheckIsLoading,
  } = useDownloaderThunks();

  const {
      onMonthChange,
      getRegistersSummary,
    } = useSimilarityThunks();

  return {
    forceCheckProps: {
      downloadAndSaveIncidents,
      downloadAndSaveRegisters,
      shutdownDownloader,
      forceINPIUpdate,
      isLoading: forceCheckIsLoading,
      deleteRegisters,
      institutes,
      messages: forceCheckMessages,
      watchTaskList,
      onMonthChange,
      getRegistersSummary,
    },
  };
};
