import { useIntl } from "react-intl";
import { useBulletinsActions } from "../store/slices/BulletinsSlice";
import { useBulletinsThunks } from "../store/thunks/BulletinsThunks";

export const useStatisticsProps = () => {
  const intl = useIntl();

  const statisticsMessages = {
    listTitularWithoutRepresentatives: intl.formatMessage({
      id: "bulletins.label.listTitularWithoutRepresentatives",
    }),
    listRepresentativeWithMostRegisters: intl.formatMessage({
      id: "bulletins.label.listRepresentativeWithMostRegisters",
    }),
    search: intl.formatMessage({ id: "generics.label.search" }),
    name: intl.formatMessage({ id: "model.name" }),
    quantity: intl.formatMessage({ id: "bulletins.label.quantity" }),
    noResults: intl.formatMessage({ id: "generics.label.noResults" }),
    origin: "Origin",
  };

  const {
    registerPage,
    currentPage,
    setCurrentPage,
    representativeCount,
    setRepresentatives,
    titularCount,
    setTitularCount,
  } = useBulletinsActions();

  const {
    isLoading: bulletinIsLoading,
    setIsLoading: setBulletinIsLoading,
    getTitularWithoutRepresentatives,
    getRepresentativesBrandsCounts,
  } = useBulletinsThunks();

  return {
    statisticsProps: {
      currentPage,
      getRepresentativesBrandsCounts,
      isLoading: bulletinIsLoading,
      representativeCount,
      setCurrentPage,
      registerPage,
      setIsLoading: setBulletinIsLoading,
      setRepresentatives,
      titularCount,
      setTitularCount,
      getTitularWithoutRepresentatives,
      messages: statisticsMessages,
    },
  };
};
