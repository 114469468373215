import { useIntl } from "react-intl";
import { useSimilarityActions } from "../store/slices/SimilaritySlice";
import { useSimilarityThunks } from "../store/thunks/SimilarityThunks";
import { useWatchTaskActions } from "../store/slices/WatchTaskSlice";
import { useCustomerActions } from "../store/slices/CustomerSlice";

export const useSimilaritiesProps = () => {
  const intl = useIntl();

  const similaritiesMessages = {
    institute: intl.formatMessage({ id: "model.institute" }),
    startDate: intl.formatMessage({ id: "generics.label.startDate" }),
    endDate: intl.formatMessage({ id: "generics.label.endDate" }),
    publications: intl.formatMessage({ id: "similarities.label.publications" }),
    searchSimilarities: intl.formatMessage({
      id: "similarities.label.searchSimilarities",
    }),
    minimumSimilarity: intl.formatMessage({
      id: "similarities.label.minimumSimilarity",
    }),
    customerSelection: intl.formatMessage({
      id: "similarities.label.customerSelection",
    }),
    selectAll: intl.formatMessage({
      id: "similarities.label.selectAllRegisters",
    }),
    closeWatch: intl.formatMessage({ id: "similarities.label.closeWatch" }),
    generateReport: intl.formatMessage({
      id: "similarities.label.generateReport",
    }),
    noResults: intl.formatMessage({ id: "generics.label.noResults" }),
    similarity: intl.formatMessage({ id: "similarities.label.similarity" }),
    reference: intl.formatMessage({ id: "model.reference" }),
    number: intl.formatMessage({ id: "model.number" }),
    brandText: intl.formatMessage({ id: "model.brandText" }),
    image: intl.formatMessage({ id: "model.image" }),
    classes: intl.formatMessage({ id: "generics.label.classes" }),
    publication: intl.formatMessage({ id: "similarities.label.publication" }),
    titular: intl.formatMessage({ id: "model.titular" }),
    analysis: intl.formatMessage({ id: "similarities.label.analysis" }),
    on: intl.formatMessage({ id: "generics.label.on" }),
    watchStartedBy: intl.formatMessage({
      id: "similarities.label.watchStartedBy",
    }),
    watchEndedBy: intl.formatMessage({ id: "similarities.label.watchEndedBy" }),
  };

  const {
    origin,
    setOrigin,
    similarities,
    setSelectedSimilaritiesId,
    setSimilarities,
    filter,
    setFilter,
    isFirst,
    selectedSimilaritiesId,
  } = useSimilarityActions();
  const {
    fetchSimilaritiesWithSource,
    onMonthChange,
    getRegistersSummary,
    closeWatch,
    generateReport,
    isLoading: similarityIsLoading,
  } = useSimilarityThunks();

  const { customers, setCustomers } = useCustomerActions();

  const { watchTaskList } = useWatchTaskActions();

  return {
    similaritiesProps: {
      closeWatch,
      generateReport,
      selectedSimilaritiesId,
      similarities,
      setSelectedSimilaritiesId,
      setSimilarities,
      isFirst,
      filter,
      setFilter,
      origin,
      setOrigin,
      fetchSimilaritiesWithSource,
      onMonthChange,
      getRegistersSummary,
      customers,
      setCustomers,
      watchTaskList,
      isLoading: similarityIsLoading,
      messages: similaritiesMessages,
    },
  };
};
