import { Container } from "react-bootstrap";
import MyAccordion from "../../widgets/MyAccordion";
import RenewalsByMonth from "./RenewalsByMonth";
import RenewalsByYear from "./RenewalsByYear";
import { Renewal } from "../../models/Renewal";

interface Props {
    fetchRenewalsByYear: (year: number, handleResult: (result: Renewal[]) => void) => void;
    setRenewalsByYear: (renewals: Renewal[]) => void;
    yearRenewal: number;
    setYear: (year: number) => void;
    renewalsByYear: Renewal[];

    fetchRenewalsByMonth: (month: number, handleResult: (result: Renewal[]) => void) => void;
    setRenewalsByMonth: (renewals: Renewal[]) => void;
    monthRenewal: number;
    setMonth: (month: number) => void;
    renewalsByMonth: Renewal[];
    messages: {
        brandText: string,
        endDate: string,
        customer: string,
        reference: string,
        number: string,
        source: string,
        monthsInAdvance: string,
        search: string,
        byMonths: string,
        byYear: string,
        year: string,
    }
}

const Renewals = ({ fetchRenewalsByYear, setRenewalsByYear, yearRenewal, setYear, renewalsByYear, fetchRenewalsByMonth, monthRenewal, renewalsByMonth, setMonth, setRenewalsByMonth, messages }: Props) => {

    const items = () => {
        return [
            { header: messages.byMonths, body: <RenewalsByMonth messages={messages} fetchRenewalsByMonth={fetchRenewalsByMonth} monthRenewal={monthRenewal} renewalsByMonth={renewalsByMonth} setMonth={setMonth} setRenewalsByMonth={setRenewalsByMonth} /> },
            { header: messages.byYear, body: <RenewalsByYear messages={messages} fetchRenewalsByYear={fetchRenewalsByYear} setRenewalsByYear={setRenewalsByYear} yearRenewal={yearRenewal} setYear={setYear} renewalsByYear={renewalsByYear} /> },
        ]
    }
    return <>
        <Container className={"mt-2"}>
            <MyAccordion items={items()} />
        </Container>

    </>
}


export default Renewals