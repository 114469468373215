import { Table } from "react-bootstrap";
import { RepresentativeCount } from "../../models/RepresentativeCount";
import { Page } from "../../models/Page";
import RenderRepresentativeCount from "./RenderRepresentativeCount";

type Props = {
    representativeCount: Page<RepresentativeCount[]>;
    messages: {
        name: string,
        quantity: string,
        origin: string
    };
}

const RepresentativeStatisticsTable = ({ messages,representativeCount }: Props) => {

    const representatives = representativeCount.content
    if (!representatives) return <></>
    return <>
        <Table>
            <thead>
                <tr>
                    <th>{messages.name}</th>
                    <th>{messages.quantity}</th>
                    <th>{messages.origin}</th>
                </tr>
            </thead>
            <tbody>
                {
                    <RenderRepresentativeCount representatives={representatives} />
                }
            </tbody>

        </Table>
    </>

}

export default RepresentativeStatisticsTable