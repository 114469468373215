import ManageIgnoredWords from "./ManageIgnoredWords";
import MyAccordion from "../../widgets/MyAccordion";
import { Container } from "react-bootstrap";
import { AccordionItems } from "../../models/AccordionItems";
import PasswordChangeForm from "./PasswordChangeForm";
import ManageRelatedClasses from "./ManageRelatedClasses";
import { IgnoredWord } from "../../models/IgnoredWord";
import { WatchingClass } from "../../models/WatchingClass";
import { Team } from "../../models/Team";

interface Props {
    resetPassword: (userId: string) => void;
    isLoading: boolean;
    userId: string;
    updateIgnoredWords: (ignoredWords: IgnoredWord[]) => void;
    currentTeam: Team
    setCurrentTeam: (team: Team) => void,
    updateRelatedClasses: (classes: WatchingClass[]) => void;
    messages: {
        managePassword: string,
        manageIgnoredWords: string,
        manageRelatedClass: string,
        sendPasswordResetEmail: string,
        wordsToBeIgnored: string,
        word: string,
        newWord: string,
        remove: string,
        saveChanges: string,
        class: string,
        relatedClasses: string,
        newClass: string,
    }
}

const User = ({ setCurrentTeam, resetPassword, isLoading, userId, updateIgnoredWords, currentTeam, updateRelatedClasses, messages }: Props) => {

    const items = (): AccordionItems[] => {
        return [
            { header: messages.managePassword, body: <PasswordChangeForm {...{ isLoading, resetPassword, userId, messages }} /> },
            { header: messages.manageIgnoredWords, body: <ManageIgnoredWords {...{ currentTeam, setCurrentTeam, isLoading, updateIgnoredWords, messages }} /> },
            { header: messages.manageRelatedClass, body: <ManageRelatedClasses {...{ isLoading, currentTeam, setCurrentTeam, updateRelatedClasses, messages }} /> },
        ]
    }

    return <>
        <Container className={"mt-2"}>
            <MyAccordion items={items()} />
        </Container>
    </>
}

export default User