import { toast } from "react-toastify";
import dayjs, { Dayjs } from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import MyButton from "../../widgets/MyButton";
import { Colors } from "../../widgets/Colors";
import StripedDataGrid from "../../widgets/StripedDataGrid";
import RegisterClassModal from "../../widgets/RegisterClassModal";
import AnalysisModal from "./modals/AnalysisModal";
import ClassesModal from "./modals/ClassesModal";
import { useEffect, useState } from "react";
import { Similarity } from "../../models/Similarity";
import { GridColumns, GridRowClassNameParams, GridRowHeightReturnValue, GridSelectionModel, GridValidRowModel } from "@mui/x-data-grid";
import { ImageList, ImageListItem } from "@mui/material";
import { RegisterClass } from "../../models/RegisterClass";


dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

type Props = {
  startDate: Dayjs,
  endDate: Dayjs,
  similarities: Similarity[],
  filter?: number,
  origin: string,
  setSelectedSimilaritiesId?: (ids: number[]) => void,
  selectedSimilaritiesId?: number[],
  generateReport?: (startDate: Dayjs, endDate: Dayjs, origin: string) => void,
  closeWatch?: (startDate: Dayjs, endDate: Dayjs, origin: string) => void,
  messages:{
    closeWatch: string,
    generateReport: string,
    noResults: string,
    similarity: string,
    reference: string,
    number: string,
    brandText: string,
    image: string,
    classes: string,
    publication: string,
    titular: string,
    institute: string,
    analysis: string,
  }
}

interface ModalRegister {
  classes: RegisterClass[],
  watched: string,
  candidate: string,
}

interface RowParams {
  row: Similarity
}

const defaultModalRegister = () => {
  return {} as ModalRegister
}

const SimilarityTable = ({ startDate, endDate, similarities, closeWatch, filter, generateReport, origin, selectedSimilaritiesId, setSelectedSimilaritiesId,messages }: Props) => {

  const [showClassModal, setShowClassModal] = useState(false)
  const [showAnalysisModal, setShowAnalysisModal] = useState(false)
  const [showClassesModal, setShowClassesModal] = useState(false)
  const [modalRegister, setModalRegister] = useState<ModalRegister>(defaultModalRegister())
  const [similarityDetails, setSimilarityDetails] = useState<Similarity>()
  const [pageSize, setPageSize] = useState(100);

  useEffect(() => {
    return () => {
      if (setSelectedSimilaritiesId) {
        setSelectedSimilaritiesId([])
      }
    }
  }, [])
  const filterData = () => {
    if (filter) {
      return similarities.filter(watchedBrand => watchedBrand.probability >= filter)
    }

    return []
  }
  const handleCloseWatch = () => {
    if (closeWatch) {
      closeWatch(startDate, endDate, origin)
    }

  }
  const handleGenerateReport = async () => {
    if (startDate <= endDate) {
      if (generateReport) {
        generateReport(startDate, endDate, origin,)

      }
    } else {
      toast("Data fim tem de ser posterior à data de início")

    }
  }

  const handleModalAnalysisDetails = (similarity: Similarity) => {
    setShowAnalysisModal(true)
    setModalRegister({
      candidate: similarity.candidate,
      watched: similarity.watched,
      classes: similarity.offendingClasses
    } as ModalRegister)
  }

  const watchedDecoration = (watchedInfo: string) => {
    return <p>{watchedInfo}</p>
  }

  const offendingDecoration = (offendingInfo: string) => {
    return <p><b>{offendingInfo}</b></p>
  }

  const rowInfo = (watchedInfo: string, offendingInfo: string) => {
    return <div>
      {watchedDecoration(watchedInfo)}
      <br />
      {offendingDecoration(offendingInfo)}
    </div>
  }

  const showClassDetails = (similarity: Similarity) => {
    setSimilarityDetails(similarity)
    setShowClassesModal(true)
  }


  const columns: GridColumns = [
    {
      field: "probability", headerName: messages.similarity, width: 100,
      renderCell: (params: RowParams) => {
        return `${(params.row.probability * 100).toFixed(2)} %`
      }
    },
    {
      field: "reference", headerName: messages.reference, width: 200, renderCell: (params: RowParams) => {
        return <>
          <p>
            {params.row.reference}
          </p>
        </>
      },
    },
    {
      field: "number", headerName: messages.number, width: 100,
      renderCell: (params: RowParams) => {
        const { watchedNumber, offendingNumber } = params.row

        return <>
          {rowInfo(watchedNumber, offendingNumber)}
        </>
      }
    },
    {
      field: "brandText", headerName: messages.brandText, width: 250,
      renderCell: (params: RowParams) => {
        const { brandText, originalOffendingBrand } = params.row
        return <>
          {rowInfo(brandText, originalOffendingBrand)}
        </>
      }
    },
    {
      field: "images", headerName: messages.image, width: 400,
      renderCell: (params: RowParams) => {
        const { watchedImage, offendingImage, brandText, originalOffendingBrand } = params.row
        const images = [watchedImage, offendingImage].filter(image => image)
          .map(image => <ImageListItem key={image}>
            <img src={`data:image/png;base64,${image}`} alt={""} />
          </ImageListItem>)

        return <>
          <div>
            {!watchedImage && watchedDecoration(brandText)}
            {images.length > 0 && <ImageList cols={1} >
              {images}
            </ImageList>}
            <br />
            {!offendingImage && offendingDecoration(originalOffendingBrand)}
          </div>
        </>
      }
    },
    {
      field: "classes", headerName: messages.classes, width: 150,
      renderCell: (params: RowParams) => {

        const { watchedClasses, offendingClasses } = params.row
        const watchedInfo = watchedClasses.map((clazz) => {
          return `${clazz.key}; `
        })
        const offendingInfo = offendingClasses.map((clazz) => {
          return `${clazz.key}; `
        })
        return <>
          <div>
            <p>{watchedInfo}</p>
            <br />
            <p><b>{offendingInfo}</b></p>
            <MyButton label={"Detalhes"} onClick={() => showClassDetails(params.row)} />
          </div>
        </>
      }
    },
    {
      field: "datePublication", headerName: messages.publication, width: 100, renderCell: (params: RowParams) => {
        const { datePublication } = params.row
        return <>
          <b>{datePublication}</b>
        </>
      }
    },
    {
      field: "titulars", headerName: messages.titular, width: 300,
      renderCell: (params: RowParams) => {
        const { watchedTitular, offendingTitular } = params.row
        return <>
          {rowInfo(watchedTitular, offendingTitular)}
        </>
      }
    },
    {
      field: "watchedSource", headerName: messages.institute, renderCell: (params: RowParams) => {
        const { watchedSource, offendingSource } = params.row
        return <>
          {rowInfo(watchedSource, offendingSource)}
        </>
      }
    },
    {
      field: "similarity", headerName: messages.analysis, renderCell: (params: RowParams) => {
        return <MyButton label={"info"} onClick={() => handleModalAnalysisDetails(params.row)} />
      }
    },
  ]

  const rows = filterData()?.map((similarity, index) => {
    return { ...similarity, id: index } as Similarity
  })

  const onSelectionChange = (selectionModel: GridSelectionModel) => {
    const selectedIds = selectionModel.map(v => (v) as number)
    if (setSelectedSimilaritiesId) {
      setSelectedSimilaritiesId(selectedIds)
    }
  }

  const getWatchedInfo = () => {
    return similarityDetails?.watchedClasses.map((rc, index) => {
      return <p key={index}>{rc.key} {rc.value}</p>
    }
    )
  }

  const getOffendingInfo = () => {
    return similarityDetails?.offendingClasses.map((rc, index) => {
      return <p key={index}>{rc.key} {rc.value}</p>
    }
    )
  }

  const getProps = () => {
    return {
      sx: {
        '& .MuiDataGrid-virtualScroller': {
          transform: 'rotateX(180deg)',
        },
        '& .MuiDataGrid-virtualScrollerContent': {
          transform: 'rotateX(180deg)',
        },
      },
      pageSize: pageSize,
      onPageSizeChange: (newPageSize: number) => setPageSize(newPageSize),
      rowsPerPageOptions: [5, 10, 20, 100],
      onSelectionModelChange: onSelectionChange,
      selectionModel: selectedSimilaritiesId,
      rows: rows,
      columns: columns,
      checkboxSelection: true,
      getRowClassName: (params: GridRowClassNameParams<GridValidRowModel>) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd',
      autoHeight: true,
      disableSelectionOnClick: true,
      getRowHeight: (): GridRowHeightReturnValue => 'auto',
    }
  }

  return <>

    <MyButton label={messages.closeWatch} onClick={handleCloseWatch} className={"mb-2"}
      color={Colors.GREEN} />

    {similarities.length > 0 &&
      <>
        <br />
        <MyButton label={messages.generateReport} onClick={handleGenerateReport} className={"mb-2"} />
        <StripedDataGrid {...getProps()} />

        <RegisterClassModal show={showClassModal} setShow={setShowClassModal} classes={modalRegister.classes} />
        <AnalysisModal show={showAnalysisModal} setShow={setShowAnalysisModal}
          brandTexts={[modalRegister.watched, modalRegister.candidate]} />
        <ClassesModal show={showClassesModal} setShow={setShowClassesModal} watchedInfo={getWatchedInfo()}
          offendingInfo={getOffendingInfo()} />
      </>

    }
    <div>{messages.noResults}</div>
  </>
}
export default SimilarityTable;