import { Container } from "react-bootstrap";
import MyAccordion from "../../widgets/MyAccordion";
import TitularStatistics from "./TitularStatistics";
import RepresentativeStatistics from "./RepresentativeStatistics";
import { AccordionItems } from "../../models/AccordionItems";
import { RepresentativeCount } from "../../models/RepresentativeCount";
import { Page } from "../../models/Page";
import { BrandRegister } from "../../models/BrandRegister";
import { TitularCount } from "../../models/TitularCount";

interface Props {
    currentPage: number;
    setCurrentPage: (page: number) => void;
    representativeCount: Page<RepresentativeCount[]>;
    setRepresentatives: (page: Page<RepresentativeCount[]>) => void;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    getRepresentativesBrandsCounts: (page: number) => Promise<Page<RepresentativeCount[]>>
    registerPage: Page<BrandRegister[]>;
    getTitularWithoutRepresentatives: (page: number) => Promise<Page<TitularCount[]>>;
    titularCount: Page<TitularCount[]>;
    setTitularCount: (page: Page<TitularCount[]>) => void;
    messages: {
        listTitularWithoutRepresentatives: string,
        listRepresentativeWithMostRegisters: string,
        search:string,
        name: string,
        quantity: string,
        origin: string,
        noResults: string,
    }
}

const Statistics = ({ setCurrentPage, currentPage, getRepresentativesBrandsCounts, representativeCount, setRepresentatives, isLoading, setIsLoading, getTitularWithoutRepresentatives, titularCount, setTitularCount, messages }: Props) => {

    const items = (): AccordionItems[] => {
        return [

            {
                header: messages.listTitularWithoutRepresentatives, body: <TitularStatistics {...{
                    getTitularWithoutRepresentatives, isLoading, setIsLoading, setTitularCount, titularCount,messages
                }} />
            },

            {
                header: messages.listRepresentativeWithMostRegisters, body: <RepresentativeStatistics {...{
                    representativeCount, setRepresentatives, currentPage, getRepresentativesBrandsCounts, isLoading, setCurrentPage, setIsLoading, messages
                }} />
            },
        ]
    }

    return <>

        <Container>
            <MyAccordion items={items()} />
        </Container>

    </>
}


export default Statistics