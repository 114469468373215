import { ChangeEvent, useState } from "react";
import { Form } from "react-bootstrap";
import MyModal from "../../widgets/MyModal";
import { Customer } from "../../models/Customer";
import { CustomerClass } from "../../models/CustomerClass";
import MySelect from "../../widgets/MySelect";
import MyButton from "../../widgets/MyButton";
import { Colors } from "../../widgets/Colors";

interface Props {
    customers: Customer[]
    showObservingClassModal: boolean
    selectedCustomer: Customer
    changeObservingClassModal: (show: boolean) => void
    setSelectedCustomer: (customer: Customer) => void
    updateCustomer: (customer: Customer) => void
    updateObservingClass: (customerId: number, classes: CustomerClass[], handleResult: (result: Customer | undefined) => void) => void
    defaultCustomer: () => Customer
}

const CustomerObservingClassFormModal = ({ customers, showObservingClassModal, changeObservingClassModal, selectedCustomer, setSelectedCustomer, updateCustomer, updateObservingClass, defaultCustomer }: Props) => {

    const [selectedClass, setSelectedClass] = useState(1)

    const handleClose = () => {
        changeObservingClassModal(false)
    };

    const handleClassSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedClass = parseInt(event.currentTarget.value)
        if (!isNaN(selectedClass)) {
            setSelectedClass(selectedClass)
        }
    }

    const updateCustomers = (customer: Customer, newCustomerClassArray: CustomerClass[]) => {
        const updatedCustomer = { ...customer, classes: newCustomerClassArray }
        const customerIndex = customers.findIndex(customer => customer.id === updatedCustomer.id)
        const updatedCustomerArray = [...customers]
        updatedCustomerArray[customerIndex] = updatedCustomer
        updateCustomer(updatedCustomer)
        setSelectedCustomer(updatedCustomer)
    }

    const handleAddClass = () => {
        const newClassObject = { number: selectedClass }
        const previousCustomerClassArray = selectedCustomer?.classes
        if (previousCustomerClassArray?.findIndex(clazz => clazz.number === selectedClass) === -1) {
            const newCustomerClassArray = [...previousCustomerClassArray, newClassObject]
            newCustomerClassArray.sort((a, b) => a.number - b.number)
            updateCustomers(selectedCustomer, newCustomerClassArray)
        }
    }

    const handleRemoveClass = () => {
        const customerClassesAfterRemotion = selectedCustomer?.classes?.filter(clazz => clazz.number !== selectedClass)
        if (customerClassesAfterRemotion) {
            updateCustomers(selectedCustomer, customerClassesAfterRemotion)
        }
    }

    const handleUpdateObservingClass = () => {

        const handleResult = (result: Customer | undefined) => {
            if (result) {
                changeObservingClassModal(false)
            }
        }
        if (selectedCustomer.id && selectedCustomer.classes) {
            updateObservingClass(selectedCustomer.id, selectedCustomer.classes, handleResult)
        }
    }

    const formBody = () => {
        return <><Form>
            <Form.Group>
                <MySelect onChange={handleClassSelect} labelId={""} label={"Classes"} value={selectedClass}
                    className={""} selectId={""} data={
                        Array.from({ length: 50 }, (_, index) => index + 1).map(index => {
                            return <option value={index} key={index}>{index}</option>
                        })
                    } />
                <MyButton label={"incluir"} onClick={handleAddClass} />
                <MyButton label={"remover"} onClick={handleRemoveClass} color={Colors.RED} />
            </Form.Group>
        </Form>
            {
                defaultCustomer()?.classes?.map(clazz => {
                    return <p>{clazz.number}</p>
                })
            }
        </>
    }

    const formFooter = () => {
        return <>
            <MyButton label={"fechar"} onClick={handleClose} color={Colors.GRAY} />
            <MyButton label={"guardar"} onClick={handleUpdateObservingClass} />
        </>
    }

    const myModalProps = {
        show: showObservingClassModal, title: "Atualiza Classes do Cliente",
        body: formBody(), footer: formFooter(), handleClose
    }

    return <>
        <MyModal {...myModalProps} size={"lg"} />
    </>
}

export default CustomerObservingClassFormModal;