import { Container, Nav, Navbar, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import MyButton from "./MyButton";
import { Colors } from "./Colors";

type Props = {
  isLoading: boolean,
  routes: JSX.Element,
  isLoggedIn: boolean,
  loggedLinks: JSX.Element,
  isAdmin: boolean,
  adminLinks: JSX.Element,
  handleLogin: () => Promise<void>,
  handleLogout: () => Promise<void>,
  homename: string,
  logoutButtonText: string,
  username: string,
  languageButtons: JSX.Element[]
}

const MyNav = ({ languageButtons, routes, loggedLinks, adminLinks, isAdmin, isLoggedIn, handleLogin, handleLogout, homename, logoutButtonText, username }: Props) => {

  return <>
    <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
      <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse role={"navigation"} id="responsive-navbar-nav">
          <Nav variant={"tabs"}>
            <Nav.Link className={"ms-sm-1"} as={Link} to={"/"}>
              <p>{homename}</p>
            </Nav.Link>
            {isLoggedIn && loggedLinks}
            {isAdmin && adminLinks}
            <Nav className={"mx-2"}>
              {
                !isLoggedIn && <MyButton label={"login"} onClick={handleLogin} color={Colors.BLUE} />
              }
              {
                isLoggedIn && <>
                  <Navbar.Text>Signed in as: {username}</Navbar.Text>
                  <MyButton className={"mx-2"} label={logoutButtonText} onClick={handleLogout}
                    color={Colors.BLUE} />
                  {languageButtons}
                </>
              }
            </Nav>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    {routes}
  </>
}



export default MyNav