import { useIntl } from "react-intl";
import { useSimilarityActions } from "../store/slices/SimilaritySlice";
import { useSimilarityThunks } from "../store/thunks/SimilarityThunks";

export const useFreeSearchProps = () => {
  const intl = useIntl();
  const freeSearchMessages = {
    brandText: intl.formatMessage({ id: "model.brandText" }),
    endDate: intl.formatMessage({ id: "model.concessionEndDate" }),
    class: intl.formatMessage({ id: "profile.label.class" }),
    startDate: intl.formatMessage({ id: "generics.label.startDate" }),
    search: intl.formatMessage({ id: "generics.label.search" }),
    closeWatch: intl.formatMessage({ id: "similarities.label.closeWatch" }),
    generateReport: intl.formatMessage({
      id: "similarities.label.generateReport",
    }),
    noResults: intl.formatMessage({ id: "generics.label.noResults" }),
    similarity: intl.formatMessage({ id: "similarities.label.similarity" }),
    reference: intl.formatMessage({ id: "model.reference" }),
    number: intl.formatMessage({ id: "model.number" }),
    image: intl.formatMessage({ id: "model.image" }),
    classes: intl.formatMessage({ id: "generics.label.classes" }),
    publication: intl.formatMessage({ id: "similarities.label.publication" }),
    titular: intl.formatMessage({ id: "model.titular" }),
    institute: intl.formatMessage({ id: "model.institute" }),
    analysis: intl.formatMessage({ id: "similarities.label.analysis" }),
  };

  const {
    origin,
    similarities,
    setSimilarities,
    filter,
    setFilter,
    isFirst,
    setIsFirst,
  } = useSimilarityActions();
  const {
    setIsLoading: setSimilarityIsLoading,
    isLoading: similarityIsLoading,
    fetchFreeSearch,
  } = useSimilarityThunks();

  return {
    freeSearchProps: {
      similarities,
      origin,
      setSimilarities,
      filter,
      setFilter,
      isFirst,
      setIsFirst,
      isLoading: similarityIsLoading,
      setIsLoading: setSimilarityIsLoading,
      fetchFreeSearch,
      messages: freeSearchMessages,
    },
  };
};
