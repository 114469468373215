import { RepresentativeCount } from "../../models/RepresentativeCount"

type Props = {
  representatives: RepresentativeCount[]
}

const RenderRepresentativeCount = ({ representatives }: Props) => {

  return <>
    {representatives.map(({ representativeName, count, origin }) => {
      return <tr key={representativeName}>
        <td>{representativeName}</td>
        <td>{count}</td>
        <td>{origin}</td>
      </tr>
    })}
  </>
}

export default RenderRepresentativeCount;