import { useAppDispatch } from "../hooks";
import KeycloakRequests from "../../api/customer/KeycloakRequests";
import { useKeycloakAction } from "../slices/KeycloakSlice";
import { KeycloakGroup } from "../../models/keycloak/KeycloakGroup";
import { toast } from "react-toastify";
import { KeycloakUser } from "../../models/keycloak/KeycloakUser";
import UserRequests, { KeycloakUserGroup } from "../../api/customer/UserRequests";
import { useIsLoadingAction } from "../slices/IsLoadingSlice";
import { useKeycloak } from "@react-keycloak/web";


const getUsers = () => {

    const { getUsers } = KeycloakRequests

    return async () => {
        return getUsers()
    }
}

const getGroups = () => {
    const { getGroups } = KeycloakRequests

    return async () => {
        return getGroups()
    }
}

const createGroup = (group: KeycloakGroup) => {
    const { createGroup } = KeycloakRequests

    return async () => {
        return createGroup(group)
    }
}


const deleteGroup = (groupId: string) => {
    const { deleteGroup } = KeycloakRequests

    return async () => {
        return deleteGroup(groupId)
    }
}

const updateGroupInstitute = (groupId: string, group: KeycloakGroup) => {
    const { updateGroupInstitute } = KeycloakRequests

    return async () => {
        return updateGroupInstitute(groupId, group)
    }
}

const createUser = (user: KeycloakUser) => {
    const { createUser } = KeycloakRequests

    return async () => {
        return createUser(user)
    }
}
const sendUserPostCreationMail = (userId: string) => {
    const { sendUserPostCreationMail } = KeycloakRequests

    return async () => {
        return sendUserPostCreationMail(userId)
    }
}
const deleteUser = (userId: string) => {
    const { deleteUser } = KeycloakRequests

    return async () => {
        return deleteUser(userId)
    }
}

const associateUserToTeam = (userGroup: KeycloakUserGroup) => {

    const { associateUserToTeam } = UserRequests

    return async () => {
        return associateUserToTeam(userGroup)
    }
}

export const useKeycloakThunks = () => {

    const dispatch = useAppDispatch()
    const { setUsers, setGroups, groups, users } = useKeycloakAction()
    const { isLoading, setIsLoading } = useIsLoadingAction()

    const removeGroupFromUsers = (group: KeycloakGroup) => {
        const changedUsers = [...users]
        changedUsers.forEach(u => {
            if (u?.groups?.find(g => g.name === group.name)) {
                u.groups.pop()
            }
        })
        setUsers(changedUsers)
    }

    const { keycloak } = useKeycloak();
    const institutes = keycloak.tokenParsed?.institutes;
    const userId = keycloak.tokenParsed?.sub ?? "";

    return {
        institutes,
        userId,
        isLoading: isLoading,
        setIsLoading,
        fetchKeycloakData: () => {

            if (users.length === 0) {
                setIsLoading(true)
                dispatch(getUsers())
                    .then(result => {
                        setUsers(result)
                    })
                    .finally(() => {
                        setIsLoading(false)
                    })
            }

            if (groups.length === 0) {
                setIsLoading(true)
                dispatch(getGroups())
                    .then(result => {
                        setGroups([...groups, ...result])
                    })
                    .finally(() => setIsLoading(false))
            }
        },
        createGroup: (group: KeycloakGroup) => {
            dispatch(createGroup(group)).then(groupId => {
                if (groupId) {
                    setGroups([...groups, { ...group, id: groupId }])
                    toast.info("Team created")
                }
            }).finally(() => {
                setIsLoading(false)
            })
        }, updateGroupInstitute: (group: KeycloakGroup) => {

            if (group.id) {
                setIsLoading(true)
                dispatch(updateGroupInstitute(group.id, group)).then(result => {
                    if (result) {
                        toast.info("Institute successfully updated")
                    } else {
                        toast.error("Could not update user institute")
                    }
                }).finally(() => {
                    setIsLoading(false)
                })
            }

        },
        deleteGroup: (group: KeycloakGroup) => {
            if (group.id) {
                setIsLoading(true)
                dispatch(deleteGroup(group.id)).then(result => {
                    if (result) {
                        setGroups(groups.filter(g => g.id !== group.id))
                        removeGroupFromUsers(group)
                        toast.info("Group deleted")
                    } else {
                        toast.error("Could not delete group")
                    }
                }).finally(() => {
                    setIsLoading(false)
                })
            }
        },


        createUser: (user: KeycloakUser, handleResult: (newUserId: string) => void) => {
            setIsLoading(true)
            dispatch(createUser(user)).then(handleResult).finally(() => {
                setIsLoading(false)
            })
        },
        sendUserPostCreationMail: (userId: string, handleResult: () => void) => {
            dispatch(sendUserPostCreationMail(userId)).then(handleResult)
        },
        deleteUser: (user: KeycloakUser) => {
            if (user.id) {
                setIsLoading(true)
                dispatch(deleteUser(user.id)).then(result => {
                    if (result) {
                        setUsers(users.filter(u => u.id !== user.id))
                        toast.info("User removed")
                    } else {
                        toast.error("Could not remove user")
                    }
                }).finally(() => {
                    setIsLoading(false)
                })
            }
        },
        associateUserToTeam: (user: KeycloakUser) => {

            const group = groups.find(g => user.groups?.find(ug => ug.name === g.name)) //user.groups?.includes(g.name))
            if (user.id && group && group.id) {
                setIsLoading(true)
                dispatch(associateUserToTeam({
                    username: user.username,
                    userId: user.id,
                    groupName: group.name,
                    groupId: group.id
                })).then(response => {
                    if (response) {
                        toast.info("Group successfully associated")
                    } else {
                        toast.error("Could not associate to group")
                    }
                }).finally(() => {
                    setIsLoading(false)
                })
            }


        }
    }

}