import { useIntl } from "react-intl";
import { useKeycloakAction } from "../store/slices/KeycloakSlice";
import { useKeycloakThunks } from "../store/thunks/KeycloakThunks";

export const useKeycloakProps = () => {
  const intl = useIntl();

  const keycloakMessages = {
    teamManagement: intl.formatMessage({ id: "keycloak.label.teamManagement" }),
    userManagement: intl.formatMessage({ id: "keycloak.label.userManagement" }),
    newTeam: intl.formatMessage({ id: "keycloak.label.newTeam" }),
    name: intl.formatMessage({ id: "model.name" }),
    createTeam: intl.formatMessage({ id: "keycloak.label.createTeam" }),
    institute: intl.formatMessage({ id: "model.institute" }),
    updateInstitutes: intl.formatMessage({
      id: "keycloak.label.updateInstitutes",
    }),
    remove: intl.formatMessage({ id: "generics.label.remove" }),
    newUser: intl.formatMessage({ id: "keycloak.label.newUser" }),
    username: intl.formatMessage({ id: "keycloak.label.username" }),
    firstName: intl.formatMessage({ id: "keycloak.label.firstName" }),
    lastName: intl.formatMessage({ id: "keycloak.label.lastName" }),
    email: intl.formatMessage({ id: "model.email" }),
    createUser: intl.formatMessage({ id: "keycloak.label.createUser" }),
    team: intl.formatMessage({ id: "keycloak.label.team" }),
    associateTeam: intl.formatMessage({ id: "keycloak.label.associateTeam" }),
  };

  const { users, setUsers, setGroups, groups } = useKeycloakAction();

  const {
    fetchKeycloakData,
    createGroup,
    updateGroupInstitute,
    deleteGroup,
    isLoading: keycloakIsLoading,
    setIsLoading: setKeycloakIsLoading,
    createUser,
    sendUserPostCreationMail,
    deleteUser,
    associateUserToTeam,
  } = useKeycloakThunks();

  return {
    keycloakProps: {
      setGroups,
      groups,
      createGroup,
      updateGroupInstitute,
      deleteGroup,
      fetchKeycloakData,
      isLoading: keycloakIsLoading,
      associateUserToTeam,
      createUser,
      deleteUser,
      sendUserPostCreationMail,
      setUsers,
      users,
      messages: keycloakMessages,
    },
  };
};
