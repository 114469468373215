import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { ChangeEvent, useState } from "react";
import { toast } from "react-toastify";
import SimilarityTable from "./SimilarityTable";
import MyDatePicker from "../../widgets/MyDatePicker";
import dayjs from "dayjs";
import MyTextInput from "../../widgets/MyTextInput";
import MyButton from "../../widgets/MyButton";
import { Similarity } from "../../models/Similarity";
import { WatchTask } from "../../models/WatchTask";

interface Props {
    setSimilarities: (similarities: Similarity[]) => void;
    similarities: Similarity[]
    filter: number;
    setFilter: (filter: number) => void;
    isFirst: boolean;
    setIsFirst: (isFirst: boolean) => void;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    fetchFreeSearch: (brandText: string, observingClass: number, startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => void;
    origin: string,
    messages: {
        brandText: string,
        class: string,
        startDate: string,
        endDate: string,
        search: string,
        closeWatch: string,
        generateReport: string,
        noResults: string,
        similarity: string,
        reference: string,
        number: string,
        image: string,
        classes: string,
        publication: string,
        titular: string,
        institute: string,
        analysis: string,
    }
}

const FreeSearch = ({ origin, similarities, setSimilarities, filter, setFilter, isFirst, setIsFirst, isLoading, setIsLoading, fetchFreeSearch, messages }: Props) => {

    const [startDate, setStartDate] = useState(dayjs())
    const [endDate, setEndDate] = useState(dayjs())
    const [brandText, setBrandText] = useState("")
    const [observingClass, _] = useState(1)

    const handleSearch = async () => {
        setSimilarities([])
        setIsLoading(true)
        if (startDate <= endDate) {
            fetchFreeSearch(brandText, observingClass, startDate, endDate)
        } else {
            toast("Data fim tem de ser posterior à data de início")
        }
        setIsFirst(false)
    }

    const handleBrandTextChange = (event: ChangeEvent<HTMLInputElement>) => {
        setBrandText(event.currentTarget.value)
    }

    const handleRangeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const filter = parseInt(event.currentTarget.value)
        if (!isNaN(filter)) {
            setFilter(filter)
        }
    }
    const isDisable = () => {
        return brandText.length === 0 || observingClass === 0 || !startDate || !endDate
    }

    return <>

        <Container className={"mb-2 mt-5"}>
            <Row>
                <Col>
                    <MyTextInput name={"brandText"} label={messages.brandText} value={brandText} onChange={handleBrandTextChange} />
                </Col>
                <Col>
                    <MyTextInput type={"number"} name={"observingClass"} label={messages.class} value={observingClass}
                        onChange={handleBrandTextChange} />
                </Col>
                <Col>
                    <MyDatePicker origin={origin} label={messages.startDate} date={startDate}
                        onChangeDate={(date: dayjs.Dayjs) => setStartDate(date)}  watchTaskList={[] as WatchTask[]} />
                </Col>
                <Col>
                    <MyDatePicker origin={origin} label={messages.endDate} date={endDate}
                        onChangeDate={(date: dayjs.Dayjs) => setEndDate(date)} watchTaskList={[] as WatchTask[]} />
                </Col>
            </Row>
        </Container>

        <Container className={"mb-2"}>
            <MyButton label={messages.search} onClick={handleSearch} disabled={isDisable()} />
        </Container>

        {!isFirst && <Container className={"mb-2"}>
            <Form.Label>Semelhança Mínima: {(filter * 100).toFixed(2)}%</Form.Label>
            <Form.Range value={filter} onChange={handleRangeChange} min={0} max={1} step={0.01} />
        </Container>}

        <Container>
            {isLoading && <Spinner animation={"border"} size={"sm"} />}
            <SimilarityTable {...{ origin, similarities, startDate, endDate, filter, messages }} />
        </Container>

    </>
}

export default FreeSearch;