import { Container } from "react-bootstrap";
import MyAccordion from "../../widgets/MyAccordion";
import { AccordionItems } from "../../models/AccordionItems";
import { mapInstitute } from "../../models/Institute";
import MySelect from "../../widgets/MySelect";
import IncidentForm from "./IncidentForm";
import NextRenewals from "./NextRenewals";
import { IncidentWatchTask } from "../../models/IncidentWatchTask";
import { Incident } from "../../models/Incident";
import dayjs from "dayjs";
import { Renewal } from "../../models/Renewal";
import { useEffect } from "react";
import Keycloak from "keycloak-js"

interface Props {
    selectedInstitute: string;
    setSelectedInstitute: (selectedInstitute: string) => void;
    institutes: string[];
    isLoading: boolean,
    setIsLoading: (isLoading: boolean) => void,
    watchTaskList: IncidentWatchTask[],
    incidents: Incident[],
    updateWatchTaskList: (incidents: IncidentWatchTask[]) => void,
    startDate: string,
    setStartDate: (date: string) => void,
    isFirst: boolean,
    setIsFirst: (isFirst: boolean) => void,
    setIncidents: (incidents: Incident[]) => void,
    getIncidents: (startDate: dayjs.Dayjs, selectedInstitute: string, handleResult: (result: Incident[]) => void) => void,
    getWatchTaskList: (startDate: dayjs.Dayjs, selectedInstitute: string, handleResult: (result: IncidentWatchTask[]) => void) => void,
    getMonthSummary: (startDate: string, origin: string) => void,
    fetchRenewalsByMonth: (month: number, handleResult: (renewals: Renewal[]) => void) => void,
    keycloak:Keycloak,
    messages: {
        next6MonthRenewals: string,
        institute: string,
        searchIncidents: string,
        noResults: string,
        bulletinOpenedBy: string,
        on: string,
        brandText: string,
        endDate: string,
        customer: string,
        reference: string,
        number: string,
        source: string,
        monthsInAdvance: string,
        search: string,
    }
}

const Incidents = ({ selectedInstitute, setSelectedInstitute, institutes, messages, getIncidents, getMonthSummary, getWatchTaskList, incidents, isFirst, isLoading, setIncidents, setIsFirst, setIsLoading, setStartDate, startDate, updateWatchTaskList, watchTaskList, fetchRenewalsByMonth }: Props) => {

    useEffect(() => {
        getMonthSummary(startDate, selectedInstitute)
    }, [startDate, selectedInstitute,getMonthSummary]);


    const items = (): AccordionItems[] => {
        return [
            { header: messages.next6MonthRenewals, body: <NextRenewals fetchRenewalsByMonth={fetchRenewalsByMonth} messages={messages} /> },
        ]
    }

    const getDataInstitutes = () => {
        return institutes.map((inst: string) => {
            const institute = mapInstitute(inst)
            if (institute) {
                return <option key={institute.origin}
                    value={institute.origin}>{institute.name}</option>
            }
        })
    }

    const incidentFormProps = {
        messages,
        isLoading,
        setIsLoading,
        selectedInstitute,
        watchTaskList,
        incidents,
        updateWatchTaskList,
        startDate,
        setStartDate,
        isFirst,
        setIsFirst,
        setIncidents,
        getIncidents,
        getWatchTaskList,
        getMonthSummary,
    }

    return <>
        <Container className={"mt-2"}>

            <MyAccordion items={items()} className={"mb-2"} />
            <MySelect {...{
                onChange: (evt) => setSelectedInstitute(evt.target.value), labelId: "insitutes-dropdown-label",
                label: messages.institute, value: selectedInstitute, className: "mb-2",
                selectId: "institutes-label",
                data: getDataInstitutes()
            }} />
        </Container>

        {selectedInstitute && <IncidentForm {...incidentFormProps} />}
    </>
}

export default Incidents
