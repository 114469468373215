import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { IntlProvider } from "react-intl";
import { messages } from "./i18n/messages";
import { useEffect, useState } from "react";
import { languages } from "./i18n/languages";
import Main from './Main';
import { useKeycloak } from '@react-keycloak/web';
import { useAuthProps } from './props/useAuthProps';

const App = () => {

    const [language, setLanguage] = useState(languages[0])
    const { keycloak } = useKeycloak()

    const { authProps:{doPostLogin} } = useAuthProps()

    useEffect(() => {
        if (keycloak.authenticated) {
          doPostLogin(keycloak)
        }
      }, [keycloak.authenticated])


    return (
        <>
            <BrowserRouter  future={{v7_startTransition:true,v7_relativeSplatPath:true}} >
                <IntlProvider locale={language.code} defaultLocale={language.code} messages={messages[language.code]}>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <Main setLanguage={setLanguage} keycloak={keycloak} />
                </IntlProvider>

            </BrowserRouter>

        </>
    );
}

export default App;
