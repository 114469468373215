import { Form } from "react-bootstrap";

import MyTextInput from "../../widgets/MyTextInput";
import { Customer } from "../../models/Customer";
import MyButton from "../../widgets/MyButton";
import { Colors } from "../../widgets/Colors";
import { object, string } from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import MySelect from "../../widgets/MySelect";
import MyModal from "../../widgets/MyModal";

const CustomerSchema = object<Customer>()
    .shape({
        name: string().required(),
        email: string().required(),
        address: string().required(),
        displayName: string().required(),
        language: string().required(),
    }).required()

interface Props {
    showNewCustomerModal: boolean;
    setShowNewCustomerModal: (show: boolean) => void;
    customers: Customer[];
    setCustomers: (customers: Customer[]) => void;
    createCustomer: (customer: Customer, handlerResult: (customer: Customer | undefined) => void) => void,
    messages: { newCustomer: string, name: string, email: string, address: string, displayName: string, language: string, close: string, saveChanges: string }
}

type RegisterInputId = "name" | "displayName" | "address" | "email" | "language"

const CustomerFormModal = ({ showNewCustomerModal, createCustomer, customers, setCustomers, setShowNewCustomerModal, messages }: Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(CustomerSchema)
    });


    const handleClose = () => {
        setShowNewCustomerModal(false)
    };

    const handleSaveNewCustomer = async (data: Customer) => {
        const handlerResult = (customer: Customer | undefined) => {
            if (customer) {
                setCustomers([...customers, customer])
                handleClose()
                reset()
            }
        }
        createCustomer(data, handlerResult)
    }

    const getCol = (label: string, registerInputId: RegisterInputId, errorText: string | undefined,) => {
        return <MyTextInput label={label} {...register(registerInputId)} errorText={errorText} />
    }

    const getOption = (key: string, value: string, text: string) => {
        return <option key={key} value={value} >{text}</option>
    }

    const formBody = () => {
        return <Form>
            {getCol(messages.name, "name", errors.name?.message)}
            {getCol(messages.email, "email", errors.email?.message)}
            {getCol(messages.address, "address", errors.address?.message)}
            {getCol(messages.displayName, "displayName", errors.displayName?.message)}
            <MySelect {...register("language")} labelId={""} errorText={errors.language?.message}
                label={messages.language} className={""} selectId={""} data={
                    ["PT", "EN"].map((lang) => getOption(lang, lang, lang))
                } />
        </Form>
    }

    const formFooter = () => {
        return <>
            <MyButton label={messages.close} onClick={handleClose} color={Colors.GRAY} />
            <MyButton label={messages.saveChanges} onClick={handleSubmit(handleSaveNewCustomer)} />
        </>
    }

    const myModalProps = {
        show: showNewCustomerModal, title: messages.newCustomer,
        body: formBody(), footer: formFooter(), handleClose
    }

    return <MyModal {...myModalProps} />

}


export default CustomerFormModal;