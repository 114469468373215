import {Page} from "../../models/Page";
import {BrandRegister} from "../../models/BrandRegister";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {useAppDispatch, useAppSelector} from "../hooks";
import {Representative} from "../../models/Representative";
import {RepresentativeCount} from "../../models/RepresentativeCount";
import {TitularCount} from "../../models/TitularCount";
import dayjs, {Dayjs} from "dayjs";
import {friendlyDate} from "../../utils/DateUtils";
import { useState } from "react";


export interface BulletinsState {
    registerPage: Page<BrandRegister[]>,
    currentPage:number,
    selectedRepresentative:Representative,
    representativeCount:Page<RepresentativeCount[]>,
    titularCount:Page<TitularCount[]>,
    publicationDate:string,
    selectedInstitute:string,
}


const bulletinsState: BulletinsState = {
    registerPage: {} as Page<BrandRegister[]>,
    currentPage:0,
    selectedRepresentative: {} as Representative,
    representativeCount:{} as Page<RepresentativeCount[]>,
    titularCount:{} as Page<TitularCount[]>,
    publicationDate:friendlyDate(dayjs()),
    selectedInstitute:'PT',
}


const bulletinsSlice = createSlice({
    name: "bulletins",
    initialState: bulletinsState,
    reducers: {
        setRegisterPage: (state, action: PayloadAction<Page<BrandRegister[]>>) => {
            state.registerPage = action.payload
        },setCurrentPage:(state, action:PayloadAction<number>)=>{
            state.currentPage=action.payload
        },
        setSelectedRepresentative:(state, action:PayloadAction<Representative>)=>{
            state.selectedRepresentative=action.payload
        },setRepresentatives:(state, action:PayloadAction<Page<RepresentativeCount[]>>)=>{
            state.representativeCount=action.payload
        },
        setTitularCount:(state, action:PayloadAction<Page<TitularCount[]>>)=>{
            state.titularCount=action.payload
        },setPublicationDate:(state, action:PayloadAction<string>)=>{
            state.publicationDate=action.payload
        },setSelectedInstitute:(state, action:PayloadAction<string>)=>{
            state.selectedInstitute=action.payload
        }
    }
})


export default bulletinsSlice.reducer


export const useBulletinsActions=()=>{

    const {registerPage,currentPage,
        selectedRepresentative,representativeCount,
        titularCount,publicationDate,selectedInstitute} = useAppSelector(state=>state.bulletins)

    const {setRegisterPage,setCurrentPage,
        setSelectedRepresentative,setRepresentatives,
        setTitularCount,setPublicationDate,setSelectedInstitute} = bulletinsSlice.actions

    const dispatch = useAppDispatch()
    const [isLoading,setIsLoading] = useState(false)



    return {
        isLoading,setIsLoading,
        setRegisterPage:(pages:Page<BrandRegister[]>)=>{
            dispatch(setRegisterPage(pages))
        },
        setCurrentPage:(currentPage:number)=>{
            dispatch(setCurrentPage(currentPage))
        },
        setSelectedRepresentative:(representative:Representative)=>{
            dispatch(setSelectedRepresentative(representative))
        },
        setRepresentatives:(representatives:Page<RepresentativeCount[]>)=>{
            dispatch(setRepresentatives(representatives))
        },

        setTitularCount:(titularCount:Page<TitularCount[]>)=>{
            dispatch(setTitularCount(titularCount))
        },
        setPublicationDate:(publicationDate:Dayjs)=>{
          dispatch(setPublicationDate(friendlyDate(publicationDate)))
        },
        setSelectedInstitute:(selectedInstitute:string)=>{
          dispatch(setSelectedInstitute(selectedInstitute))
        },


        registerPage,
        currentPage,
        selectedRepresentative,
        representativeCount,
        titularCount,
        publicationDate,
        selectedInstitute
    }
}
