import { useIntl } from "react-intl";
import { useBulletinsThunks } from "../store/thunks/BulletinsThunks";
import { useBulletinsActions } from "../store/slices/BulletinsSlice";
import { useKeycloakThunks } from "../store/thunks/KeycloakThunks";

export const useBulletinsProps = () => {
  const intl = useIntl();
  const bulletinsMessages = {
    institute: intl.formatMessage({ id: "model.institute" }),
    listPublications: intl.formatMessage({
      id: "bulletins.label.listPublications",
    }),
    noResults: intl.formatMessage({ id: "generics.label.noResults" }),
    number: intl.formatMessage({ id: "model.number" }),
    brandText: intl.formatMessage({ id: "model.brandText" }),
    image: intl.formatMessage({ id: "model.image" }),
    classes: intl.formatMessage({ id: "customers.label.classes" }),
    details: intl.formatMessage({ id: "customers.label.details" }),
    titular: intl.formatMessage({ id: "model.titular" }),
    address: intl.formatMessage({ id: "model.address" }),
    representative: intl.formatMessage({ id: "model.representative" }),
    representativeAddress: intl.formatMessage({ id: "model.address" }),
  };

  const { institutes } = useKeycloakThunks();

  const {
    registerPage,
    currentPage,
    setCurrentPage,
    setRegisterPage,
    setPublicationDate,
    publicationDate,
    selectedInstitute,
    setSelectedInstitute,
  } = useBulletinsActions();

  const {
    getAllRegistersByDateAndSource,
    isLoading: bulletinIsLoading,
    setIsLoading: setBulletinIsLoading,
  } = useBulletinsThunks();

  return {
    bulletinsProps: {
      origin,
      setCurrentPage,
      currentPage,
      setRegisterPage,
      registerPage,
      isLoading: bulletinIsLoading,
      setIsLoading: setBulletinIsLoading,
      setPublicationDate,
      publicationDate,
      setSelectedInstitute,
      selectedInstitute,
      getAllRegistersByDateAndSource,
      institutes,
      messages: bulletinsMessages,
    },
  };
};
