import MyModal from "../../widgets/MyModal";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import MySelect from "../../widgets/MySelect";
import MyButton from "../../widgets/MyButton";
import { Colors } from "../../widgets/Colors";
import MyTextInput from "../../widgets/MyTextInput";
import { Customer } from "../../models/Customer";
import { useForm } from "react-hook-form";

interface Props {
    showDetailsModal: boolean
    changeDetailsModal: (show: boolean) => void
    selectedCustomer: Customer
    updateCustomer: (customer: Customer) => void
    messages: {
        updateDetailsOf: string,
        email: string,
        address: string,
        displayName: string,
        language: string,
        close: string,
        saveChanges: string,
        isRequired: string,
    }
}

type RegisterInputId = "name" | "displayName" | "id" | "address" | "email" | "language" | "brandRegisters" | "ignoredWords" | "classes"

const CustomerDetailsModal = ({ changeDetailsModal, selectedCustomer, showDetailsModal, updateCustomer, messages }: Props) => {

    useEffect(() => {

        if (selectedCustomer.id) {
            setValue("id", selectedCustomer.id)
        }
        setValue("name", selectedCustomer.name)
        setValue("address", selectedCustomer.address)
        setValue("email", selectedCustomer.email)
        setValue("displayName", selectedCustomer.displayName)
        setValue("language", selectedCustomer.language ?? "PT")

        return () => { reset() }
    }, [selectedCustomer])

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue
    } = useForm<Customer>();

    const getErrorMessage = (error?: string) => {
        switch (error) {
            case "emailError": {
                return `${messages.email} ${messages.isRequired}`
            }
            case "addressError": {
                return `${messages.address} ${messages.isRequired}`
            }
            case "displayNameError": {
                return `${messages.displayName} ${messages.isRequired}`
            }
            case "languageError": {
                return `${messages.language} ${messages.isRequired}`
            }
        }
    }

    const handleClose = () => {
        changeDetailsModal(false)
    };

    const handleUpdateCustomer = async (customer: Customer) => {
        updateCustomer(customer)
    }

    const formFooter = () => {
        return <>
            <MyButton label={messages.close} onClick={handleClose} color={Colors.GRAY} />
            <MyButton label={messages.saveChanges} onClick={handleSubmit(handleUpdateCustomer)} />
        </>
    }

    const getCol = (label: string, registerInputId: RegisterInputId, errorText: string | undefined,) => {
        return <Col> <MyTextInput label={label} {...register(registerInputId)} errorText={errorText} />
        </Col>
    }

    const formBody = () => {
        return <>
            <Row>
                {getCol(messages.email, "email", getErrorMessage(errors.email?.message))}
                {getCol(messages.address, "address", getErrorMessage(errors.address?.message))}
            </Row>
            <Row>
                {getCol(messages.displayName, "displayName", getErrorMessage(errors.displayName?.message))}
                <Col>
                    <MySelect labelId={""} label={messages.language}
                        {...register("language")}
                        errorText={getErrorMessage(errors.language?.message)}
                        className={""} selectId={""} data={
                            [
                                <option key={"PT"} value={"PT"}>PT</option>,
                                <option key={"EN"} value={"EN"}>EN</option>,
                            ]
                        } />

                </Col>
            </Row>
            <Row>
                <p>Marcas</p>
                {selectedCustomer?.brandRegisters?.map(br => {
                    return <p>Número: {br.number} Sinal: {br.brandText} Instituto: {br.source}</p>
                }) ?? <></>}
            </Row>
        </>
    }

    const myModalProps = {
        handleClose, show: showDetailsModal,
        title: `${messages.updateDetailsOf} ${selectedCustomer.name}`,
        body: formBody(), footer: formFooter()
    }

    return <>
        <MyModal {...myModalProps} size="lg" />
    </>
}
export default CustomerDetailsModal