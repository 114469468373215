import Keycloak from "keycloak-js"

interface Props {
    children: JSX.Element,
    isAdmin?: boolean,
    isManager?: boolean,
    keycloak: Keycloak
}

const RequireAuth = ({ children, keycloak, isAdmin = false, isManager = false }: Props) => {
    if (isAdmin) {
        return keycloak.hasRealmRole("admin") ? children : null;
    }
    if (isManager) {
        return keycloak.hasRealmRole("manager") ? children : null;
    }
    return keycloak.authenticated ? children : null;


}

export default RequireAuth;