import { useIntl } from "react-intl";
import { useRenewalThunks } from "../store/thunks/RenewalThunks";
import { useRenewalsActions } from "../store/slices/RenewalSlice";

export const useRenewalsProps = () => {
  const intl = useIntl();
  const renewalsMessages = {
    brandText: intl.formatMessage({ id: "model.brandText" }),
    endDate: intl.formatMessage({ id: "model.concessionEndDate" }),
    customer: intl.formatMessage({ id: "model.customer" }),
    reference: intl.formatMessage({ id: "model.reference" }),
    number: intl.formatMessage({ id: "model.number" }),
    source: intl.formatMessage({ id: "model.institute" }),
    monthsInAdvance: intl.formatMessage({
      id: "renewals.label.monthsInAdvance",
    }),
    search: intl.formatMessage({ id: "generics.label.search" }),
    year: intl.formatMessage({ id: "model.year" }),
    byMonths: intl.formatMessage({ id: "renewals.label.byMonths" }),
    byYear: intl.formatMessage({ id: "renewals.label.byYear" }),
  };

  const { fetchRenewalsByYear, fetchRenewalsByMonth } = useRenewalThunks();

  const {
    setRenewalsByYear,
    yearRenewal,
    setYear,
    renewalsByYear,
    monthRenewal,
    setMonth,
    setRenewalsByMonth,
    renewalsByMonth,
  } = useRenewalsActions();

  return {
    renewalsProps: {
      fetchRenewalsByYear,
      setRenewalsByYear,
      yearRenewal,
      setYear,
      renewalsByYear,
      fetchRenewalsByMonth,
      monthRenewal,
      renewalsByMonth,
      setMonth,
      setRenewalsByMonth,
      messages: renewalsMessages,
    },
  };
};
