import { useIntl } from "react-intl";
import { Language, languages } from "./i18n/languages";
import { Nav, NavDropdown } from "react-bootstrap";
import { useNavigate, Routes, Route } from "react-router";
import { Link } from "react-router-dom";
import BrandWithoutRepresentatives from "./features/brandsWithoutRepresentative/BrandWithoutRepresentatives";
import Bulletins from "./features/bulletins/Bulletins";
import Customers from "./features/customers/Customers";
import ForceCheck from "./features/forceCheck/ForceCheck";
import Incidents from "./features/incidents/Incidents";
import SearchIncidents from "./features/incidents/SearchIncidents";
import KeycloakView from "./features/keycloak";
import Renewals from "./features/renewals/Renewals";
import Representatives from "./features/representatives/Representatives";
import FreeSearch from "./features/similarities/FreeSearch";
import Similarities from "./features/similarities/Similarities";
import Statistics from "./features/statistics/Statistics";
import User from "./features/user/User";
import RequireAuth from "./widgets/RequireAuth";
import en from "./uk_flag.png"
import pt from "./portuguese_flag.png"
import MyNav from "./widgets/MyNav";
import Keycloak from "keycloak-js"
import { useIsLoadingAction } from "./store/slices/IsLoadingSlice";
import { useProps } from "./props/useProps";
import Home from "./features/Home";
import AIR from "./features/air";


interface Props {
  setLanguage: (language: Language) => void,
  keycloak: Keycloak,
}

const Main = ({ setLanguage, keycloak }: Props) => {

  const intl = useIntl()
  const navigate = useNavigate()

  const { authProps, brandWithoutRepresentativesProps,
    bulletinsProps, customersProps, forceCheckProps, freeSearchProps, incidentsProps, keycloakProps, renewalsProps, representativesProps, searchIncidentsProps, similaritiesProps, statisticsProps, userProps, airProps
} = useProps()

  const { isLoggedIn, name, userIsAdmin, userIsManager, doLogout } = authProps
  const { isLoading } = useIsLoadingAction()

  const messages = {
    home: intl.formatMessage({ id: "nav.label.home" }),
    logout: intl.formatMessage({ id: "nav.label.logout" }),
    customers: intl.formatMessage({ id: "nav.label.customers" }),
    incidents: intl.formatMessage({ id: "nav.label.incidents" }),
    similarities: intl.formatMessage({ id: "nav.label.similarities" }),
    profile: intl.formatMessage({ id: "nav.label.profile" }),
    team: intl.formatMessage({ id: "nav.label.team" }),
    keycloak: intl.formatMessage({ id: "nav.label.keycloak" }),
    forceScheduler: intl.formatMessage({ id: "nav.label.forceScheduler" }),
    freeSearch: intl.formatMessage({ id: "nav.label.freeSearch" }),
    searchIncidents: intl.formatMessage({ id: "nav.label.searchIncidents" }),
    renewals: intl.formatMessage({ id: "nav.label.renewals" }),
    bulletins: intl.formatMessage({ id: "nav.label.bulletins" }),
    registersWithoutRepresentatives: intl.formatMessage({ id: "nav.label.registersWithoutRepresentatives" }),
    listRepresentatives: intl.formatMessage({ id: "nav.label.listRepresentatives" }),
    statistics: intl.formatMessage({ id: "nav.label.statistics" }),
    air:intl.formatMessage({id:"nav.label.air"})

  }

  const handleLogin = async () => {
    keycloak.login()
  }

  const handleLogout = async () => {
    keycloak.logout()
    doLogout()
    navigate("/")
  }

  const languageButtons = () => {
    const dimensions = 60
    return languages.map(l => {
      return <img alt={"flag"} key={l.name} onClick={() => setLanguage(l)} src={l.name === "Português" ? pt : en}
        width={dimensions} height={dimensions} />
    })
  }

  const loggedLinks = () => {
    return <>
      <Nav.Link as={Link} to={"/clientes"}>
        {messages.customers}
      </Nav.Link>
      <Nav.Link as={Link} to={"/incidents"}>
        {messages.incidents}
      </Nav.Link>
      <Nav.Link as={Link} to={"/similaridades"}>
        {messages.similarities}
      </Nav.Link>
      <Nav.Link as={Link} to={"/user"}>
        {messages.profile}
      </Nav.Link>
      <Nav.Link as={Link} to={"/air"}>
        {messages.air}
      </Nav.Link>
    </>
  }

  const adminLinks = () => {
    return <NavDropdown title={"Admin"} id={"basic-nav-dropdow"}>
      {
        userIsAdmin && <>
          <NavDropdown.Item as={Link} to={"/keycloak"}>
            {messages.keycloak}
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to={"/force-check"}>
            {messages.forceScheduler}
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to={"/pesquisalivre"}>
            {messages.freeSearch}
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to={"/search-incidents"}>
            {messages.searchIncidents}
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to={"/renewals"}>
            {messages.renewals}
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to={"/boletins"}>
            {messages.bulletins}
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to={"/air"}>
            {messages.air}
          </NavDropdown.Item>
        </>
      }
      <NavDropdown.Item as={Link} to={"/marcas-sem-representantes"}>
        {messages.registersWithoutRepresentatives}
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to={"/representantes"}>
        {messages.listRepresentatives}
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to={"/statistics"}>
        {messages.statistics}
      </NavDropdown.Item>
    </NavDropdown>
  }

  const renderRoutes = () => {
    if (!keycloak.authenticated) return <Routes>
      <Route path={"/"} element={<Home />} />
    </Routes>

    return <Routes>
      <Route path={"/"} element={<Home />} />
      <Route path={"/force-check"} element={
        <RequireAuth isAdmin={true} keycloak={keycloak}>
          <ForceCheck {...forceCheckProps} />
        </RequireAuth>
      } />
      <Route path={"/keycloak"} element={
        <RequireAuth isAdmin={true} keycloak={keycloak}>
          <KeycloakView {...keycloakProps} />
        </RequireAuth>
      } />
      {keycloak.authenticated && <Route path={"/incidents"} element={
        <RequireAuth keycloak={keycloak}>
          <Incidents {...incidentsProps} keycloak={keycloak} />
        </RequireAuth>
      } />}

      <Route path={"/renewals"} element={
        <RequireAuth keycloak={keycloak}>
          <Renewals {...renewalsProps} />
        </RequireAuth>
      } />
      <Route path={"/search-incidents"} element={
        <RequireAuth isAdmin={true} keycloak={keycloak}>
          <SearchIncidents {...searchIncidentsProps} />
        </RequireAuth>
      } />
      <Route path={"/clientes"} element={
        <RequireAuth keycloak={keycloak}>
          <Customers {...customersProps} />
        </RequireAuth>
      } />
      <Route path={"/similaridades"} element={
        <RequireAuth keycloak={keycloak}>
          <Similarities {...similaritiesProps} keycloak={keycloak} />
        </RequireAuth>
      } />
      <Route path={"/pesquisalivre"} element={
        <RequireAuth isAdmin={true} keycloak={keycloak}>
          <FreeSearch {...freeSearchProps} />
        </RequireAuth>
      } />
      <Route path={"/boletins"} element={
        <RequireAuth isAdmin={true} keycloak={keycloak}>
          <Bulletins {...bulletinsProps} />
        </RequireAuth>
      } />
      <Route path={"/marcas-sem-representantes"} element={
        <RequireAuth isManager={true} keycloak={keycloak}>
          <BrandWithoutRepresentatives {...brandWithoutRepresentativesProps} />
        </RequireAuth>
      } />
      <Route path={"/representantes"} element={
        <RequireAuth isManager={true} keycloak={keycloak}>
          <Representatives {...representativesProps} />
        </RequireAuth>
      } />
      <Route path={"/user"} element={
        <RequireAuth keycloak={keycloak}>
          <User {...userProps} />
        </RequireAuth>
      } />
      <Route path={"/statistics"} element={
        <RequireAuth isManager={true} keycloak={keycloak}>
          <Statistics {...statisticsProps} />
        </RequireAuth>
      } />
      <Route path={"/air"} element={
        <RequireAuth keycloak={keycloak}>
          <AIR {...airProps} />
        </RequireAuth>
      } />
      <Route path={"*"} element={<Home />} />
    </Routes>
  }

  return <MyNav languageButtons={languageButtons()} loggedLinks={loggedLinks()} adminLinks={adminLinks()} handleLogin={handleLogin} handleLogout={handleLogout} isAdmin={userIsAdmin || userIsManager} isLoggedIn={isLoggedIn} username={name} logoutButtonText={messages.logout} isLoading={isLoading} routes={renderRoutes()} homename={messages.home} />
}

export default Main;

