import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, PickersDay, PickersDayProps, StaticDatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { friendlyDate } from "../utils/DateUtils";
import dayjs, { Dayjs } from "dayjs";
import { IncidentWatchTask } from "../models/IncidentWatchTask";
import React from "react";


interface Props {
    date: dayjs.Dayjs,
    onChangeDate: (date: Dayjs) => void,
    onMonthChange?: (any: Dayjs) => void,
    incidentWatchList?: IncidentWatchTask[],
}

const isWeekend = (date: dayjs.Dayjs) => {
    return date.day() === 0 || date.day() === 6
}

const MyDatePickerIncidents = ({
    date, onChangeDate, incidentWatchList = [], onMonthChange
}: Props) => {

    const renderWeekPickerDay = (date: dayjs.Dayjs, selectedDates: Dayjs[], pickersDayProps: PickersDayProps<Dayjs>): JSX.Element => {

        const tasks = getWatchTaskFromListByDate(date)
        if (tasks.length > 0) {
            const style = { bgcolor: 'green' }

            return <PickersDay sx={style} {...pickersDayProps} key={date.toISOString()} />
        } else {
            if (isWeekend(date)) {
                return <React.Fragment key={date.toISOString()} />
            } else if (date.isBefore(dayjs())) {
                return <PickersDay
                    sx={{ bgcolor: 'red' }}
                    {...pickersDayProps}
                    key={date.toISOString()} />
            } else {
                return <PickersDay
                    {...pickersDayProps}
                    key={date.toISOString()} />
            }
        }

    };

    const getWatchTaskFromListByDate = (date: dayjs.Dayjs): IncidentWatchTask[] => {
        return incidentWatchList.filter(task => task.dateWatch === friendlyDate(date))
    }

    return <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker displayStaticWrapperAs="desktop"
                onMonthChange={onMonthChange}
                value={date} onChange={(date) => onChangeDate(date ?? dayjs())}
                renderDay={renderWeekPickerDay}
                renderInput={(params: any) => {
                    return <TextField
                        {...params} />
                }
                }
            />
        </LocalizationProvider>

    </>
}

export default MyDatePickerIncidents