import { useIntl } from "react-intl";
import { useTeamActions } from "../store/slices/TeamSlice";
import { useUserThunks } from "../store/thunks/UserThunks";
import { useKeycloakThunks } from "../store/thunks/KeycloakThunks";

export const useUserProps = () => {
  const intl = useIntl();

  const userMessages = {
    managePassword: intl.formatMessage({ id: "profile.label.managePassword" }),
    manageIgnoredWords: intl.formatMessage({
      id: "profile.label.manageIgnoredWords",
    }),
    manageRelatedClass: intl.formatMessage({
      id: "profile.label.manageRelatedClass",
    }),
    sendPasswordResetEmail: intl.formatMessage({
      id: "profile.label.sendPasswordResetEmail",
    }),
    wordsToBeIgnored: intl.formatMessage({
      id: "profile.label.wordsToBeIgnored",
    }),
    word: intl.formatMessage({ id: "profile.label.word" }),
    newWord: intl.formatMessage({ id: "profile.label.newWord" }),
    remove: intl.formatMessage({ id: "generics.label.remove" }),
    saveChanges: intl.formatMessage({ id: "generics.label.saveChanges" }),
    class: intl.formatMessage({ id: "profile.label.class" }),
    relatedClasses: intl.formatMessage({ id: "profile.label.relatedClasses" }),
    newClass:intl.formatMessage({id:"profile.label.newClass"}),
  };

  const { currentTeam,setCurrentTeam } = useTeamActions();
  const {
    updateRelatedClasses,
    getIgnoredWords,
    updateIgnoredWords,
    resetPassword,
    isLoading: userIsLoading,
  } = useUserThunks();

  const { userId } = useKeycloakThunks();

  return {
    userProps: {
      currentTeam,
      setCurrentTeam,
      updateRelatedClasses,
      isLoading: userIsLoading,
      resetPassword,
      userId,
      getIgnoredWords,
      updateIgnoredWords,
      messages: userMessages,
    },
  };
};
