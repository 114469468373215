import { useState } from "react";
import { Container } from "react-bootstrap";
import { TitularCount } from "../../models/TitularCount";
import MyButton from "../../widgets/MyButton";
import { Page } from "../../models/Page";
import WrapperPageableTable from "../../widgets/WrapperPageableTable";
import TitularStatisticsTable from "./TitularStatisticsTable";

interface Props {
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    getTitularWithoutRepresentatives: (page: number) => Promise<Page<TitularCount[]>>;
    titularCount: Page<TitularCount[]>;
    setTitularCount: (page: Page<TitularCount[]>) => void;
    messages: {
        search: string;
        name: string,
        quantity: string,
        origin: string,
        noResults: string,
    }
}

const TitularStatistics = ({ isLoading, setIsLoading, getTitularWithoutRepresentatives, titularCount, setTitularCount, messages }: Props) => {

    const [currentPage, setCurrentPage] = useState(0)
    const handleRequestData = async () => {
        setTitularCount({} as Page<TitularCount[]>)
        setCurrentPage(0)
        await requestPage(0)
    }

    const requestPage = async (page: number) => {
        const result = await getTitularWithoutRepresentatives(page)
        if (result) {
            setTitularCount(result)
        }
    }

    return <Container className={"mt-3"}>
            <WrapperPageableTable {...{
                isLoading, setIsLoading,
                searchButton: < MyButton {...{ disabled: isLoading, label: messages.search, onClick: handleRequestData }} />,
                table: <TitularStatisticsTable {...{ titularCount, messages }} />, page: titularCount,
                currentPage, setCurrentPage, requestPage, messages
            }} />
        </Container>

}

export default TitularStatistics