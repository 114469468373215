import { Container } from "react-bootstrap";
import { useEffect } from "react";
import WrapperPageableTable from "../../widgets/WrapperPageableTable";
import MyButton from "../../widgets/MyButton";
import RegisterTable from "../../widgets/RegisterTable";
import { Page } from "../../models/Page";
import { BrandRegister } from "../../models/BrandRegister";

interface Props {
    setRegisterPage: (page: Page<BrandRegister[]>) => void;
    registerPage: Page<BrandRegister[]>;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    requestRegistersWithoutRepresentative: (page: number) => void;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    messages: {
        listRegistersWithoutRepresentatives: string, noResults: string,
        number: string,
        brandText: string,
        image: string,
        classes: string,
        details: string,
        titular: string,
        address: string,
        representative: string,
        representativeAddress: string,
    }
}

const BrandWithoutRepresentatives = ({ setCurrentPage, currentPage, setRegisterPage, registerPage, isLoading, setIsLoading, requestRegistersWithoutRepresentative, messages }: Props) => {

    useEffect(() => {
        setRegisterPage({} as Page<BrandRegister[]>)
    }, [])

    const handleListRegisterWithoutRepresentatives = () => {
        handleRequestData()
    }

    const handleRequestData = () => {
        setRegisterPage({} as Page<BrandRegister[]>)
        setCurrentPage(0)
        requestRegistersWithoutRepresentative(0)
    }


    return <>
        <Container className={"mt-3"}>
            <WrapperPageableTable {...{
                isLoading, setIsLoading,
                table: < RegisterTable registerPage={registerPage} messages={messages} />,
                searchButton: <MyButton {...{
                    disabled: isLoading,
                    label: messages.listRegistersWithoutRepresentatives,
                    onClick: handleListRegisterWithoutRepresentatives,
                }} />,
                page: registerPage, currentPage,
                setCurrentPage, requestPage: requestRegistersWithoutRepresentative,
                messages,

            }} />
        </Container>
    </>
}


export default BrandWithoutRepresentatives